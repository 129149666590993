<div id="admin-container">
    <!--Title-->
    <div class="container-fluid">
        <div class="row ml-5 m-top-10">
            <div class="col-md-12 col-lg-12 col-sm-12">
                <div class="page-title-box d-flex align-items-center">
                    <div class="page-title-left">
                        <h2 class="card-title title_header">Settings</h2>
                    </div>
                    <span class="ml-5 m-top-5">
                        <!-- <button class="btn btn_popover_noborder me-2" placement="end"
                      ngbPopover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus." popoverTitle="Dashboard Info">
                      <i class="fa fa-info-circle _info_color_icon"></i>
                      </button> -->
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="clearfix">&nbsp;</div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                <div class="">
                    <label class="hoverable ml-30 mb-10" for="fileInput">
                        <span class="m-l-5 Settings_logo practice_name_icon" *ngIf="!imgUrl"
                            [ngStyle]="{ background: getRandomColor() }"><span class="_letter_name settings_letter">{{
                                practiceName
                                }}</span></span>
                        <img [src]="imgUrl" *ngIf="imgUrl" class="m-l-5 Settings_logo practice_name_icon" />
                        <div class="hover-text">
                            <i class="fa fa-camera" aria-hidden="true"></i>
                        </div>
                        <div class="background"></div>
                    </label>
                    <br />
                    <input id="fileInput" data-bs-toggle="modal" data-bs-target="#exampleModal" />

                    <div class="text_errors" *ngIf="!validImage">
                        Only JPG, JPEG, or PNG files are allowed.
                    </div>
                    <div class="text_errors" *ngIf="!validImageSize">
                        Image size should be less than 2 MB.
                    </div>
                    <div class="text_errors" *ngIf="serverErr">
                        {{ serverError }}
                    </div>
                </div>
                <h4 class="aambgreen_color text-capitalize">
                    Welcome, {{ currentUser?.name }}
                </h4>
            </div>
        </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title aambgreen_color" id="exampleModalLabel">
                        Practice Picture
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <p>
                                A picture helps people recognize you and lets
                                you know when you’re signed in to your account
                            </p>
                            <div class="text-center">
                                <span class="m-l-5 Settings_logo_ForPopup practice_name_icon" *ngIf="!imgUrl"
                                    [ngStyle]="{ background: getRandomColor() }"><span
                                        class="_letter_name settings_letter_ForPopup">{{ practiceName }}</span></span>
                                <img [src]="imgUrl" *ngIf="imgUrl"
                                    class="m-l-5 Settings_logo_ForPopup practice_name_icon" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer d-flex justify-content-center">
                    <label *ngIf="!imgUrl" for="file-upload" class="custom-file-upload">
                        <i class="fa fa-pencil" aria-hidden="true"></i>&nbsp;
                        Upload
                    </label>
                    <label *ngIf="imgUrl" for="file-upload" class="custom-file-upload">
                        <i class="fa fa-pencil" aria-hidden="true"></i>&nbsp;
                        Change
                    </label>
                    <input id="file-upload" (change)="onSelectFile($event)" type="file" />
                    <button type="button" *ngIf="imgUrl" class="btn btn-primary" (click)="delete()">
                        <i class="fa fa-trash" aria-hidden="true"></i> &nbsp;
                        Remove
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!--Basic Info-->
    <div class="container-fluid">
        <div class="row ml-5 m-top-10">
            <div class="col-md-12 col-lg-12 col-sm-12">
                <h5 class="h5 card-title text-black fw-600">Basic Info</h5>
                <!-- Table -->
                <div class="table-responsive">
                    <table id="basic-datatable" class="table datatables dt-responsive nowrap no-footer dtr-inline">
                        <thead>
                            <tr>
                                <th class="W-25">First Name</th>
                                <th class="W-25">Last Name</th>
                                <th class="W-25">Email</th>
                                <th class="W-25">Phone</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of userInfo">
                                <td class="text-capitalize">{{ item.firstname }}</td>
                                <td class="text-capitalize">{{ item.lastname }}</td>
                                <td>{{ item.email }}</td>
                                <td>{{ item.phone }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <!--My Profile-->
    <div class="container-fluid">
        <div class="row ml-5 m-top-10">
            <div class="col-md-12 col-lg-12 col-sm-12">
                <h5 class="h5 card-title text-black fw-600">My Profile</h5>
                <!-- Table -->
                <div class="table-responsive">
                    <table id="basic-datatable" class="table datatables dt-responsive nowrap no-footer dtr-inline">
                        <thead>
                            <tr>
                                <th class="W-25">Practice Name</th>
                                <th class="W-25">NPI Number</th>
                                <th class="W-25">NPI Type</th>
                                <th class="W-25">User Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ currentUser?.name }}</td>
                                <td>{{ currentUser?.npinumber }}</td>
                                <td>{{ currentUser?.npitype[0].name }}</td>
                                <td>{{ userRole }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
 
    <!-- change password  -->
    <div class="container-fluid">
        <div class="row ml-5 m-top-10">
            <div class="col-md-12 col-lg-12 col-sm-12">
                <h5 class="h5 card-title text-black fw-600">Change Password</h5>
                <!-- Table -->
                <div class="table-responsive">
                    <form [formGroup]="signupForm" (ngSubmit)="changePassword()" novalidate autocomplete="off">
                        <table id="change-password" class="table datatables dt-responsive nowrap no-footer dtr-inline">
                            <thead>
                                <tr>
                                    <th>Old Password</th>
                                    <th>New Password</th>
                                    <th>Confirm Password</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style="width: 25%">
                                        <div class="form-group inner-addon right-addon">
                                            <div class="input-group col-6 mx-auto">
                                                <input type="password" formControlName="oldpassword"
                                                    class="form-control" (keyup)="
                                                        removeWhiteSpacesoldpassword(
                                                            $event
                                                        )
                                                    " [(ngModel)]="oldpassword" [ngClass]="{
                                                        'is-valid':
                                                            (submitted ||
                                                                s.oldpassword
                                                                    .dirty) &&
                                                            !s.oldpassword
                                                                .errors,
                                                        'is-invalid':
                                                            (submitted ||
                                                                s.oldpassword
                                                                    .dirty) &&
                                                            s.oldpassword.errors
                                                    }" name="oldpassword" placeholder="Old Password" [type]="
                                                        showOldPasswordOnPress
                                                            ? 'text'
                                                            : 'password'
                                                    " />
                                                <div>
                                                    <i alt="show" class="far fa-eye-slash eye-show" (click)="
                                                            showOldPasswordOnPress =
                                                                !showOldPasswordOnPress
                                                        " [class.hide]="
                                                            showOldPasswordOnPress
                                                        "></i>
                                                    <i alt="hide" class="far fa-eye eye-hide" (click)="
                                                            showOldPasswordOnPress =
                                                                !showOldPasswordOnPress
                                                        " [class.hide]="
                                                            !showOldPasswordOnPress
                                                        "></i>
                                                </div>
                                                <div class="invalid-feedback" *ngIf="
                                                        (submitted ||
                                                            s.oldpassword
                                                                .dirty) &&
                                                        s.oldpassword.errors
                                                    ">
                                                    <div class="text_errors" *ngIf="
                                                            s.oldpassword.errors
                                                                .required
                                                        ">
                                                        Old Password Required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <input type="password" name="password" [value]="password" (ngModel)="password"
                          class="form-control" id="old-password" #password="ngModel" placeholder="******" />
                        <small *ngIf="!password.value && password.touched" class="text_errors">Password Required</small> -->
                                    </td>
                                    <td>
                                        <!-- password -->
                                        <div class="form-group inner-addon right-addon">
                                            <div class="input-group col-6 mx-auto">
                                                <input type="password" formControlName="password" class="form-control"
                                                    name="password" placeholder="New Password" (keyup)="
                                                        removeWhiteSpacesnewpassword(
                                                            $event
                                                        )
                                                    " [(ngModel)]="newpassword" [ngClass]="{
                                                        'is-valid':
                                                            (s.password.dirty ||
                                                                submitted) &&
                                                            passwordValid,
                                                        'is-invalid':
                                                            (s.password.dirty ||
                                                                submitted) &&
                                                            !passwordValid
                                                    }" [type]="
                                                        showPassword
                                                            ? 'text'
                                                            : 'password'
                                                    " class="form-control pwd-control" />
                                                <div>
                                                    <i alt="show" class="far fa-eye-slash eye-show" (click)="
                                                            showPassword =
                                                                !showPassword
                                                        " [class.hide]="
                                                            showPassword
                                                        "></i>
                                                    <i alt="hide" class="far fa-eye eye-hide" (click)="
                                                            showPassword =
                                                                !showPassword
                                                        " [class.hide]="
                                                            !showPassword
                                                        "></i>
                                                </div>
                                                <div class="invalid-feedback">
                                                    <!-- required -->
                                                    <span class="text_errors" *ngIf="
                                                            (s.password.dirty ||
                                                                submitted) &&
                                                            !requiredValid
                                                        ">Password is
                                                        Required</span>

                                                    <!-- password validation rules -->
                                                    <div id="validation-rules" class="mt-3" *ngIf="requiredValid">
                                                        <!-- minlength -->
                                                        <div class="f-1.2rem" [ngClass]="{
                                                                text_success:
                                                                    minLengthValid,
                                                                text_errors:
                                                                    !minLengthValid
                                                            }">
                                                            <i class="material-icons" [attr.class]="
                                                                    minLengthValid
                                                                        ? 'cancel'
                                                                        : 'check_circle'
                                                                "></i>
                                                            Must be at least 8
                                                            characters long
                                                        </div>

                                                        <!-- requires digit -->
                                                        <div [ngClass]="{
                                                                text_success:
                                                                    requiresDigitValid,
                                                                text_errors:
                                                                    !requiresDigitValid
                                                            }">
                                                            <i class="material-icons" [attr.class]="
                                                                    requiresDigitValid
                                                                        ? 'cancel'
                                                                        : 'check_circle'
                                                                "></i>
                                                            Must contain at
                                                            least 1 digit
                                                        </div>

                                                        <!-- requires uppercase -->
                                                        <div [ngClass]="{
                                                                text_success:
                                                                    requiresUppercaseValid,
                                                                text_errors:
                                                                    !requiresUppercaseValid
                                                            }">
                                                            <i class="material-icons" [attr.class]="
                                                                    requiresUppercaseValid
                                                                        ? 'cancel'
                                                                        : 'check_circle'
                                                                "></i>
                                                            Must contain at
                                                            least 1 uppercase
                                                            character
                                                        </div>

                                                        <!-- requires lowercase -->
                                                        <div [ngClass]="{
                                                                text_success:
                                                                    requiresLowercaseValid,
                                                                text_errors:
                                                                    !requiresLowercaseValid
                                                            }">
                                                            <i class="material-icons" [attr.class]="
                                                                    requiresLowercaseValid
                                                                        ? 'cancel'
                                                                        : 'check_circle'
                                                                "></i>
                                                            Must contain at
                                                            least 1 lowercase
                                                            character
                                                        </div>

                                                        <!-- requires special characters -->
                                                        <div [ngClass]="{
                                                                text_success:
                                                                    requiresSpecialCharsValid,
                                                                text_errors:
                                                                    !requiresSpecialCharsValid
                                                            }">
                                                            <i class="material-icons" [attr.class]="
                                                                    requiresSpecialCharsValid
                                                                        ? 'cancel'
                                                                        : 'check_circle'
                                                                "></i>
                                                            Must contain at
                                                            least 1 special
                                                            character
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="text_errors" *ngIf="isSamePassword">
                                                Your new password must be
                                                different from current password
                                            </div>
                                        </div>
                                        <!-- password -->
                                    </td>
                                    <td>
                                        <!-- confirm password -->
                                        <div class="form-group inner-addon right-addon">
                                            <div class="input-group col-6 mx-auto">
                                                <input type="password" formControlName="confirmPassword"
                                                    class="form-control" name="confirmPassword"
                                                    placeholder="Confirm your password" (keyup)="
                                                        removeWhiteSpacesconfirmpassword(
                                                            $event
                                                        )
                                                    " [(ngModel)]="
                                                        confirmpassword
                                                    " [ngClass]="{
                                                        'is-valid':
                                                            (submitted ||
                                                                s
                                                                    .confirmPassword
                                                                    .dirty) &&
                                                            !s.confirmPassword
                                                                .errors,
                                                        'is-invalid':
                                                            (submitted ||
                                                                s
                                                                    .confirmPassword
                                                                    .dirty) &&
                                                            s.confirmPassword
                                                                .errors
                                                    }" placeholder="Confirm Password" [type]="
                                                        showPasswordOnPress
                                                            ? 'text'
                                                            : 'password'
                                                    " />
                                                <div>
                                                    <i alt="show" class="far fa-eye-slash eye-show" (click)="
                                                            showPasswordOnPress =
                                                                !showPasswordOnPress
                                                        " [class.hide]="
                                                            showPasswordOnPress
                                                        "></i>
                                                    <i alt="hide" class="far fa-eye eye-hide" (click)="
                                                            showPasswordOnPress =
                                                                !showPasswordOnPress
                                                        " [class.hide]="
                                                            !showPasswordOnPress
                                                        "></i>
                                                </div>
                                                <div class="invalid-feedback" *ngIf="
                                                        (submitted ||
                                                            s.confirmPassword
                                                                .dirty) &&
                                                        s.confirmPassword.errors
                                                    ">
                                                    <div class="text_errors" *ngIf="
                                                            s.confirmPassword
                                                                .errors.required
                                                        ">
                                                        Confirm Your Password
                                                    </div>
                                                    <div class="text_errors" *ngIf="
                                                            s.confirmPassword
                                                                .errors
                                                                .minLength
                                                        ">
                                                        Password must be at
                                                        least 8 characters long
                                                    </div>
                                                    <div class="text_errors" *ngIf="
                                                            s.confirmPassword
                                                                .errors.mismatch
                                                        ">
                                                        Passwords do not match
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- confirm password -->
                                    </td>
                                    <td>
                                        <button type="submit" class="btn custom-btn" [disabled]="signupForm.invalid">
                                            Save
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
                <app-loader></app-loader>
            </div>
        </div>
    </div>
</div>