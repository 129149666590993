// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const origin = window.location.origin;
const envSetup = true;

const HTTP  = 'https://';
var DevHTTP  = '';
var SiteHTTP  = '';
var currentEnv = '';
var realm = 'aaamb';
var keycloakClientId = 'aaamb-web-client';
var subUrl = ''

if(origin.includes('dev')){
  DevHTTP  = HTTP + 'dev.';
  currentEnv = HTTP + 'dev.';
  SiteHTTP = HTTP + 'dev.app.';
  realm = 'dev';
  keycloakClientId = 'dev-aaamb-web-client';
} else if(origin.includes('uat')){
  DevHTTP  = HTTP + 'uat.';  
  currentEnv = HTTP + 'uat.';
  SiteHTTP = HTTP + 'uat.app.';
  realm = 'uat';
  keycloakClientId = 'uat-aaamb-web-client';
} else if(origin.includes('localhost')){
  DevHTTP  = HTTP + 'dev.';
  currentEnv =  HTTP + 'dev.';
  SiteHTTP = HTTP + 'dev.app.';
  realm = 'dev'
  keycloakClientId = 'dev-aaamb-web-client';
} else {
  DevHTTP  = HTTP + 'dev.';
  currentEnv =  HTTP + 'dev.';
  SiteHTTP = HTTP + 'dev.app.';
  realm = 'dev'
  keycloakClientId = 'dev-aaamb-web-client';
}


export const environmentNew = {
  production: false,
  apiUrl:'',
  orderURL:  DevHTTP + 'aaamb.com/api/shopping',
  mainURL : DevHTTP + 'admin.aaamb.com/api',
  baseURL : DevHTTP + 'aaamb.com/api/customer',
  prodURL : DevHTTP + 'aaamb.com/api/products',
  kcapiHost: DevHTTP + 'aaamb.com/api/kc-service',
  kcURL: HTTP + 'auth.aaamb.com',
  siteURL: SiteHTTP + 'aaamb.com' + subUrl,
  npiURL: 'https://npiregistry.cms.hhs.gov/api',
  uspsURl: "https://production.shippingapis.com/ShippingAPI.dll?API=Verify&XML=",
  currentEnv: currentEnv,
  realm: realm,
  keycloakClientId:keycloakClientId,
  stripeKey:"pk_test_51PHSyV06gg0uvLsInfeJyWoRbdwegqN87YeAhu2g988tel6pe8X6gixWO6Bti0rC0yLV4y64QnvmpWCNchZup9Qg00fBFrh5Wt"
};


export const environmentOld = {
  production: false,
  apiUrl:'',
  orderURL: 'http://3.6.5.222:8003',
  mainURL : 'http://3.6.5.222:8010',
  baseURL : 'http://3.6.5.222:8001',
  prodURL : 'http://3.6.5.222:8002',
  kcapiHost: 'http://3.6.5.222:3000',
  kcURL: 'http://3.6.5.222:8080',
  siteURL: 'http://3.6.5.222:8004',
  npiURL: 'https://npiregistry.cms.hhs.gov/api',
  uspsURl: "http://production.shippingapis.com/ShippingAPI.dll?API=Verify&XML=",
  currentEnv: currentEnv,
  realm: realm,
  keycloakClientId:keycloakClientId,
  stripeKey:"sk_test_51PHSyV06gg0uvLsIwYJAxiFhFUOZpuoU6yADWJCxlaMgWuCqLasV3JKO8FVfqrQue7trNq2yvMPmmYVpmb3n9JYU00AlwePRON"
};

export const environment = envSetup ? environmentNew : environmentOld;


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
