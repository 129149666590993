<div id="modal-2"  tabindex="-1" role="dialog">
  <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
          <div class="col-xl-12 sub-success p-5">
              <!-- <h1 class="aambgreen_color"><b>Thank you for showing your interest in AAA Medical Billing Services.</b></h1> -->
              <h5 class=" aambgreen_color font_18">Thank you for showing your interest in AAA Medical Billing
                  Services..
              </h5>
              <p>One of our representatives will get in touch with you shortly. <br> If you need immediate assistance
                  <br><span class="fontweight600">contact us at 1-844-44(AAAMB)</span>
              </p>

              <img src="assets/img/subscribe-success.png" width="300" />

              <!-- <h4 class="m-0">If you need immediate assistance</h4> 
              <p>contact us at 1-844-44(AAAMB)
              </p>  -->
              <br /><span class="btn btn-danger btn-mw btn-t-5" (click)="closeDialog()" >Continue</span>

          </div>
      </div>
  </div>
</div>
