import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
    FormGroup,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from "@angular/forms";
import { NgWizardService } from "ng-wizard";
import { MainUser } from "src/app/shared/models/mainuser";
import { User } from "src/app/shared/models/user";
import { CartService } from "src/app/shared/services/cart/cart.service";
import { CustomerService } from "src/app/shared/services/customers/customers.service";
import { DataService } from "src/app/shared/services/data.service";
import { DemoWizardService } from "src/app/shared/services/demo-wizard.service";
import { StepTwoComponent } from "../step-two/step-two.component";
import { Modal } from "bootstrap";
import { ModalService } from "src/app/_services";
import { CustomValidators } from "src/app/shared/directives/CustomValidators";

@Component({
    selector: "app-step-one",
    templateUrl: "./step-one.component.html",
    styleUrls: ["./step-one.component.scss"],
})
export class StepOneComponent implements OnInit {
    message: string;
    displayStyle = "none";
    auth2: any;
    @ViewChild("loginRef", { static: true }) loginElement!: ElementRef;
    user!: User;
    signupAaamb: FormGroup;
    userType = [];
    otp = "";
    submitted = false;
    showPassword = false;
    showCPassword = false;
    emailexists = false;
    alreadyRead = false;
    // new password validation
    public password: string = "";
    validationsubText: boolean;
    lengthValidation: boolean;
    uppercaseValidation: boolean;
    numberValidation: boolean;
    lowercaseValidation: boolean;
    specialcharValidation: boolean;
    ErrorDisplay: boolean = false;
    confirmpassword;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private cartService: CartService,
        private userService: CustomerService,
        private ngDemoService: DemoWizardService,
        private ngWizardService: NgWizardService,
        protected modalService: ModalService
    ) {
        this.signupAaamb = this.formBuilder.group({
            firstName: [
                this.userService?.user?.firstname ? this.userService?.user?.firstname: "",
                [
                    Validators.required,
                    Validators.pattern(/^[A-Za-z0-9]+(?:\s[A-Za-z0-9]+)*$/)
                ],
            ],
            lastName: [
                this.userService?.user?.lastname ? this.userService?.user?.lastname: "",
                [
                    Validators.required,
                    Validators.pattern(/^[A-Za-z0-9]+(?:\s[A-Za-z0-9]+)*$/)
                ],
            ],
            email: [
                this.userService?.user?.email ? this.userService?.user?.email: null,
                Validators.compose([
                    Validators.required,
                    Validators.pattern(
                        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
                    ),
                ]),
            ],
            password: [null, [
                Validators.required,
                // check whether the entered password has a number
            CustomValidators.patternValidator(/\d/, {
                hasNumber: true
              }),
              // check whether the entered password has upper case letter
              CustomValidators.patternValidator(/[A-Z]/, {
                hasCapitalCase: true
              }),
             // check whether the entered password has a lower case letter
              CustomValidators.patternValidator(/[a-z]/, {
                hasSmallCase: true
              }),
              // check whether the entered password has a special character
              CustomValidators.patternValidator(
                /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
                {
                  hasSpecialCharacters: true
                }
              ),
              Validators.minLength(8),]],
              confirmPassword: ["", [Validators.required]],
            phone: [
                this.userService?.user?.phone ? this.userService?.user?.phone: "",
                [
                    Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/),
                    Validators.required,
                ],
            ],
            terms: ["", Validators.requiredTrue],
        },
        {
            // check whether our password and confirm password match
            validator: CustomValidators.passwordMatchValidator
          }
        );
    }
    get f() {
        return this.signupAaamb.controls;
    }
    
    ngOnInit(): void {
        this.GetUserTypes();
        // this.googleAuthSDK();
    }

    removeWhiteSpacesconfirmpassword(event){
        this.confirmpassword = event.target.value.replace(/\s/g, "");
        const password = this.signupAaamb.controls.password.value;
       // console.log( this.confirmpassword +'------------' +password)
        // if(password!=this.confirmpassword){
        //     return this.signupAaamb.controls['confirmPassword'].setErrors({ NoPasswordMatch: true });
        // }
      }
      newpassword
    pressNewPassword(value: any) {
        //console.log("password------------------" + value);
        this.newpassword = value.replace(/\s/g, "");
        const upperCaseCharacters = /[A-Z]+/g;
        const lowerCaseCharacters = /[a-z]+/g;
        const numberCharacters = /[0-9]+/g;
        const specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        if (value !== undefined) {
            this.ErrorDisplay = true;
            this.validationsubText = true;
            if (upperCaseCharacters.test(value) === false) {
                this.uppercaseValidation = true;
            } else {
                this.uppercaseValidation = false;
            }
            if (lowerCaseCharacters.test(value) === false) {
                this.lowercaseValidation = true;
            } else {
                this.lowercaseValidation = false;
            }
            if (numberCharacters.test(value) === false) {
                this.numberValidation = true;
            } else {
                this.numberValidation = false;
            }
            if (specialCharacters.test(value) === false) {
                this.specialcharValidation = true;
            } else {
                this.specialcharValidation = false;
            }
            if (
                !this.uppercaseValidation &&
                !this.lowercaseValidation &&
                !this.specialcharValidation &&
                !this.numberValidation
            ) {
                if (value.length < 7) {
                    this.lengthValidation = true;
                    this.validationsubText = true;
                } else {
                    this.lengthValidation = false;
                    this.validationsubText = false;
                }
            }
        } else {
            this.ErrorDisplay = false;
            this.validationsubText = false;
        }
    }
    /**
     * Write code on Method
     *
     * @return response()
     */
    callLoginButton() {
        this.auth2.attachClickHandler(
            this.loginElement.nativeElement,
            {},
            (googleAuthUser: any) => {
                let profile = googleAuthUser.getBasicProfile();
                console.log(
                    "Token || " + googleAuthUser.getAuthResponse().id_token
                );
                console.log("ID: " + profile.getId());
                console.log("Name: " + profile.getName());
                console.log("Image URL: " + profile.getImageUrl());
                console.log("Email: " + profile.getEmail());
                /* Write Your Code Here */
            },
            (error: any) => {
                alert(JSON.stringify(error, undefined, 2));
            }
        );
    }

    GetUserTypes() {
        this.userService.GetUserType().then((res) => {
           // console.log(res);
            this.userType = res;
            const admin = this.userType.filter(
                (citem) => citem.name == "Admin"
            );
           // console.log("admin-----" + JSON.stringify(admin));
        });
    }
    onChangeEvent(event: any) {
       // console.log(event.target.value);
        this.emailexists = false;
    }
    onSubmit() {
        this.submitted = true;
        if (this.signupAaamb.invalid) {
            return;
        } else {
            this.EmailExists();
        }
    }

    EmailExists() {
        this.userService
            .EmailExists(this.signupAaamb.controls.email.value)
            .then((res) => {
                //console.log('email exists'+JSON.stringify(res));
                if (res) {
                    if (res.emailexists) {
                        this.emailexists = false;
                    } else {
                        // this.signupAaamb.controls.email.setErrors({ exists: 'Email already exists' });
                        this.emailexists = true;
                    }
                    if (this.emailexists) {
                        return;
                    } else {
                        this.user = {
                            _id: "",
                            firstname: "",
                            lastname: "",
                            email: "",
                            password: "",
                            phone: "",
                            otp: "",
                            usertype: "",
                            practice: "",
                            createdby: "",
                            createdon: new Date(),
                            modifiedby: "",
                            modifiedon: new Date(),
                        };
                        const admin = this.userType.filter(
                            (citem) => citem.name == "Admin"
                        );

                        this.user.firstname =
                            this.signupAaamb.controls.firstName.value;
                        this.user.lastname =
                            this.signupAaamb.controls.lastName.value;
                        this.user.email = this.signupAaamb.controls.email.value;
                        this.user.password =
                            this.signupAaamb.controls.password.value;
                        this.user.phone = this.signupAaamb.controls.phone.value;
                        this.user.usertype = admin[0]._id;
                        this.user.createdby = "1";
                        this.user.createdon = new Date();
                        this.user.modifiedby = "1";
                        this.user.modifiedon = new Date();
                        this.userService.user = this.user;
                        // console.log(
                        //     "submut----------------------" +
                        //         JSON.stringify(this.user)
                        // );
                        this.GetOtp();

                        this.ngWizardService.next();
                        window.scroll(0,0);
                    }
                } else {
                    this.emailexists = false;
                }
                // this.user.otp = this.otp;
            });
    }

    GetOtp() {
        this.userService.GetOtp(this.user.firstname + " " + this.user.lastname, this.user.email).then((res) => {
           // console.log(JSON.stringify(res));
            this.cartService.otp = res.data;
            // this.user.otp = this.otp;
        });
    }

    numberOnly(event): boolean {
        const charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    // show2(modalRef:HTMLDivElement)
    // {
    //     this.modelService.open()
    //     const modal=new Modal(modalRef);
    //     modal.show();
    // }
    onCheckboxChange(e, modalRef: string) {
        if (e.target.checked) {
            if (!this.alreadyRead) {
                this.displayStyle = "block";
                // this.modalService.open(modalRef);
                // this.alreadyRead = true;
            }
        } else {
            this.alreadyRead = false;
        }
    }
    closeModal() {
        this.displayStyle ='none'
        this.modalService.close();
    }
}
