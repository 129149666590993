import { Component, OnInit } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { Products } from "src/app/shared/models/products";
import { CustomerService } from "src/app/shared/services/customers/customers.service";
import { ProductsService } from "src/app/shared/services/products/products.service";
import { APP_CONSTANTS } from "src/app/shared/utils/appconstants";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-products",
    templateUrl: "./products.component.html",
    styleUrls: ["./products.component.scss", "../subscriptions/subscriptions.component.scss"],
})
export class ProductsComponent implements OnInit {
    user: any = [];
    activeSubscriptionList: any = [];
    order: any = [];
    productsList!: Products[];
    currentEnv: string = environment.currentEnv;
    npinumber = "";
    npitype = "";
    activeSub = [];
    constructor(
        private customerService: CustomerService,
        private productService: ProductsService,
        private cookieService: CookieService
    ) {}

    ngOnInit(): void {
        if (this.customerService.loggedInUser == null) {
            this.customerService.loggedInUser = [];
            const _token = JSON.parse(
                this.cookieService.get(APP_CONSTANTS.cookiekey) || "{}"
            );
            this.customerService.loggedInUser.push(_token.data);
            //console.log(JSON.stringify(this.customerService.loggedInUser));
        }
        this.user.push(this.customerService.loggedInUser[0]);
        this.getSubscriptions();
        if (this.customerService.loggedInUser[0].practiceData) {
            if (this.customerService.loggedInUser) {
                this.npinumber =
                    this.customerService.loggedInUser[0].practiceData.npinumber;
                this.npitype =
                    this.customerService.loggedInUser[0].practiceData.npitype[0].name;
            }
        }
        //  this.getProducts();
    }
    getProducts() {
        this.productService.isLoading(true);
        this.productService.getProductsByCategory().then((data) => {
            //console.log(JSON.stringify(data));
            this.productsList = data;
            this.productService.isLoading(false);

        });
    }
    GetCustomerOrders() {
      
        this.customerService
            .getOrderByCustomerId(this.user[0].id)
            .then((data) => {
                if (data) {
                    this.order.push(data);
                    console.log("orders", this.order);
                    data.forEach((element) => {
                        this.productService
                            .getProductsById(element.items[0].product)
                            .then((item) => {
                                item.autorenewal = element.items[0].autorenewal;
                                item.renewalDate = element.items[0].renewalDate;
                                item.subscriptionDate =
                                    element.items[0].subscriptionDate;
                                item.subscriptionType =
                                    element.items[0].subscriptionType;
                                item.orderId = element.items[0].orderId;

                                const activeList =   this.activeSub.findIndex(items=>{ 
                                    return items.product_id === item._id && element.stripe_base_subscription_item_id === items.stripe_base_subscription_item_id})
                                    if(activeList === -1) {
                                        item.active = false;
                                    } else {
                                        item.active = true;
                                        this.activeSubscriptionList.push(item);
                                    }
                                this.getProducts();
                                console.log(
                                    "activeList",
                                    this.activeSubscriptionList
                                );
                            });
                    });
                }
            });
    }


    getSubscriptions() {
        this.customerService
        .getSubscriptions(this.customerService.loggedInUser[0].id)
        .subscribe((res:[]) => {
            // this.subcriptionData = res;
            this.activeSub = [...res];
        this.GetCustomerOrders();

            // this.ngxService.stop();
            // this.getProducts();
        });
    }

    getProdUrl(name, url) {
        return name == 'Credential Today' ? this.currentEnv + url : url;
    }
}
