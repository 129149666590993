import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { KeycloakService } from "keycloak-angular";
import { CookieService } from "ngx-cookie-service";
import { Subject } from "rxjs";
import { CustomerService } from "src/app/shared/services/customers/customers.service";
import { DataService } from "src/app/shared/services/data.service";
import { UserService } from "src/app/shared/services/user.service";
import { APP_CONSTANTS } from "src/app/shared/utils/appconstants";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
    username = "";
    userfullname = "";
    role = "";
    userid = "";
    user = [];
    public iconOnlyToggled = false;
    public sidebarToggled = false;
    currentUser: any;
    practiceName: any;
    isLogo: boolean = true;
    LogoUrl: any;

    constructor(
        private readonly keycloak: KeycloakService,
        private userService: UserService,
        private customerService: CustomerService,
        private cookieService: CookieService,
        private dataService: DataService,
        private router: Router
    ) {}

    ngOnInit() {
        if (this.customerService.loggedInUser == null) {
            this.customerService.loggedInUser = [];
            const _token = JSON.parse(
                this.cookieService.get(APP_CONSTANTS.cookiekey) || "{}"
            );
            this.currentUser = _token.data.practiceData;
            this.practiceName = this.currentUser?.name
                .split(" ")
                .slice(0, 2)
                .map((n) => n[0].toUpperCase())
                .join("");
            console.log("User", this.currentUser);
            if (this.currentUser.logo) {
                this.isLogo = true;
                this.LogoUrl =
                    environment.mainURL + "/" + this.currentUser.logo;
            } else {
                this.isLogo = false;
            }
            this.customerService.loggedInUser.push(_token.data);
        }
        this.getUserDetails();
        if (this.customerService.loggedInUser) {
            // await this.GetCustomerOrders(this.customerService.loggedInUser[0].id);
            this.currentUser =
                this.customerService.loggedInUser[0].practiceData;
            this.practiceName = this.currentUser?.name
                .split(" ")
                .slice(0, 2)
                .map((n) => n[0].toUpperCase())
                .join("");
            console.log("User", this.currentUser);
            if (this.currentUser.logo) {
                this.isLogo = true;
                this.LogoUrl =
                    environment.mainURL + "/" + this.currentUser.logo;
            } else {
                this.isLogo = false;
            }
            //const usertype=this.customerService.loggedInUser[0].usertype
            this.role = this.customerService.loggedInUser[0].usertype.name;
        }
        this.dataService.getMessage.subscribe((res) => {
            if (res != "false") {
                this.currentUser.logo = res;
                this.isLogo = true;
                this.LogoUrl =
                    environment.mainURL + "/" + this.currentUser.logo;
            } else {
                this.isLogo = false;
                this.practiceName = this.currentUser?.name
                    .split(" ")
                    .slice(0, 2)
                    .map((n) => n[0].toUpperCase())
                    .join("");
            }
        });
    }

    getRandomColor() {
        if (this.practiceName.length > 6) {
            return "#004745";
        } else {
            return "#c12228";
        }
    }

    showDropdown() {
        const box = document.getElementById("dropdown");
        box.classList.add("show");
    }

    close(event) {
        // event.target.closest(".dropdown-menu").removeClass;
        const box = document.getElementById("dropdown");
        box.classList.remove("show");
    }

    settings() {
        this.router.navigate(["/settings"]);
        const box = document.getElementById("dropdown");
        box.classList.remove("show");
    }

    getUserDetails() {
        if (
            this.customerService.loggedInUser &&
            this.customerService.loggedInUser.length > 0
        ) {
            this.customerService
                .getUserById(this.customerService.loggedInUser[0].id)
                .then((res) => {
                    const word = res.firstname;
                    const capitalized =
                        word.charAt(0).toUpperCase() + word.slice(1);
                    this.userfullname = capitalized + " " + res.lastname;
                    this.username = (capitalized + " " + res.lastname)
                        .split(" ")
                        .slice(0, 2)
                        .map((n) => n[0].toUpperCase())
                        .join("");
                    this.userid = res._id;
                    this.user.push(res);
                });
        }
    }
    status: boolean = false;
    clickEvent() {
        this.status = !this.status;
    }
    logout() {
        this.cookieService.delete(APP_CONSTANTS.cookiekey, "/");
        this.keycloak.logout(APP_CONSTANTS.homeUrl);
    }
    // toggle sidebar in small devices
    toggleOffcanvas() {
        document.querySelector(".sidebar-offcanvas").classList.toggle("active");
    }

    // toggle sidebar
    toggleSidebar() {
        let body = document.querySelector("body");
        if (
            !body.classList.contains("sidebar-toggle-display") &&
            !body.classList.contains("sidebar-absolute")
        ) {
            this.iconOnlyToggled = !this.iconOnlyToggled;
            if (this.iconOnlyToggled) {
                body.classList.add("sidebar-icon-only");
            } else {
                body.classList.remove("sidebar-icon-only");
            }
        } else {
            this.sidebarToggled = !this.sidebarToggled;
            if (this.sidebarToggled) {
                body.classList.add("sidebar-hidden");
            } else {
                body.classList.remove("sidebar-hidden");
            }
        }
    }
}
