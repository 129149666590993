import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, lastValueFrom } from "rxjs";

import { login, User } from "../../models/user";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class CartService {
    public productList=new BehaviorSubject<any>([]);
    readonly baseURL = environment.baseURL;
    UserList!: User[];
    otp!:'';
    public cartItemList: any = [];
    constructor(private http: HttpClient) {}

    addToCart(products: any) {
        //
        this.cartItemList.push(products); //adding products to cart
        this.productList.next(this.cartItemList); //emiting
        this.getTotalPrice();
        console.log(this.cartItemList);
    }

    removeFromCart(item:any)
    {
        let newCart = [];
        newCart = this.cartItemList.filter((citem) => citem._id != item._id)
        this.cartItemList = [];
        for(let i=0; i< newCart.length; i++)
        {
            this.cartItemList.push(newCart[i]);
        }
    }
    getTotalPrice(): string {
        //to get total price
        let grandTotal = 0 ;
        this.cartItemList.map((x: any) => {
            grandTotal += parseFloat(x.price);
        });
        let sum = this.addZeroes(grandTotal);
    //console.log("grandTotal------------------"+sum);
        return (sum).toString();
    }
    addZeroes(num) {
        return num.toLocaleString("en", {useGrouping: false, minimumFractionDigits: 2})
     }
    toFixed(n, precision) {
        const coeff = 10**precision;
        return (Math.floor(n * coeff) / coeff).toFixed(precision);
    }
}
