import {
    Component,
    ElementRef,
    EventEmitter,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { ToastrService } from "ngx-toastr";
import { Products } from "src/app/shared/models/products";
import { CustomerService } from "src/app/shared/services/customers/customers.service";
import { ProductsService } from "src/app/shared/services/products/products.service";
import { APP_CONSTANTS } from "src/app/shared/utils/appconstants";
import { Modal } from "bootstrap";
import { CartService } from "src/app/shared/services/cart/cart.service";
import { ICreateOrderRequest, IPayPalConfig } from "ngx-paypal";
import { NgxSpinnerService } from "ngx-spinner";
import {
    ItemSchema,
    UpdateOrder,
    UpdateOrderRenewal,
} from "src/app/shared/models/order";
import { NavigationEnd, Router } from "@angular/router";
import { UserService } from "src/app/shared/services/user.service";
import { DomSanitizer } from "@angular/platform-browser";
import { ConfirmCancelEvent } from "angular-confirmation-popover/lib/confirmation-popover.directive";
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { StatesService } from "src/app/shared/services/states.service";
import {
    StripeCardElementOptions,
    StripeElementsOptions,
} from "@stripe/stripe-js";
import { StripeCardComponent, StripeService } from "ngx-stripe";
import { Address } from "ngx-google-places-autocomplete/objects/address";
import { debug } from "console";
@Component({
    selector: "app-subscriptions",
    templateUrl: "./subscriptions.component.html",
    styleUrls: ["./subscriptions.component.scss"],
})
export class SubscriptionsComponent implements OnInit {
    @ViewChild("cardNumber") cardNumber: StripeCardComponent;
    @ViewChild("cardExpiry") cardExpiry: StripeCardComponent;
    @ViewChild("cardCvc") cardCvc: StripeCardComponent;
    @ViewChild('cancelSubscriptionModalInfo') cancelSubscriptionModalInfo: any;
    updateorderrenewal!: UpdateOrderRenewal;
    public showSuccess: boolean = false;
    public showCancel: boolean = false;
    public showError: boolean = false;
    balanceactiveSubscriptionList: any = [];
    subscriptionId: String = "";
    transactionId: String = "";
    updateorder!: UpdateOrder;
    InactiveSubscriptionList!: Products[];
    orderItems: ItemSchema[];
    orderProductId: any = [];
    activeSubscriptionList: any = [];
    finalInactiveList: any = [];
    showTrans = false;
    item: ItemSchema;
    awaiting = false;
    productName = "";
    order: any = [];
    npinumber = "";
    user: any = [];
    grandTotal = 0;
    mySubscription;
    orderId = "";
    npitype = "";
    transId = "";
    cardDetails: any;
    items: {
        name: any;
        quantity: any;
        category: string;
        unit_amount: { currency_code: string; value: any };
    }[] = [];
    total = 0;

    @ViewChild("priceElem", { static: false }) priceElem?: ElementRef;
    @ViewChild("closebutton") closebutton;
    @ViewChild("saveCancelClick") saveCancelClick;
    // &nbsp;&nbsp;<i class="fa-solid fa-circle-xmark font-large cursorPointer breakdown_icon"
    providerTitle = "Amount Breakdown";
    confirmClicked = true;
    cancelClicked = true;
    hideConfirmButton: boolean = false;
    hideCancelButton: boolean = false;
    confirmText: string = "Close";
    subcriptionData: any;
    safeUrl: any;
    InvoiceData: any;
    totalAmount: any;
    providerAmount: any;
    IsShowTotal: boolean = false;
    BillingAdressInfo: any;
    states: any;
    stateName: any;
    username: any;
    userInfo = [];
    userid: any; 
    CardId: any;
    last4digits: any;
    branding: any;
    cardError:boolean = false;
    setasPrimary: boolean = false; 

    elementsOptions: StripeElementsOptions = {
        locale: "en",
    };

    cardOptions: StripeCardElementOptions = {
        style: {
            base: {
                iconColor: "#666EE8",
                color: "#31325F",
                fontWeight: "300",
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSize: "18px",
                "::placeholder": {
                    color: "#6e707e",
                    fontSize: "14px",
                },
            },
        },
    };
    cardErrorMethod: string;

    setAsPrimaryCard(event) {
        if (event.target.checked) {
            this.setasPrimary = true;
        } else {
            this.setasPrimary = false;
        }
    }

    

    constructor(
        private router: Router,
        private customerService: CustomerService,
        private productService: ProductsService,
        private cookieService: CookieService,
        private toastService: ToastrService,
        private cartService: CartService,
        private sanitizer: DomSanitizer,
        private spinner: NgxSpinnerService,
        private ngxService: NgxUiLoaderService,
        private stateservice : StatesService,
        private stripeService: StripeService,
    ) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.mySubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.router.navigated = false;
            }
        });
    }

   async ngOnInit() {
        this.getStates();
        this.productService.isLoading(true);
        this.awaiting = true;
        this.loadPageData();
        this.getSubscription();
        this.getInvoice();
        
       await this.getUserDetails();
         
        this.getBillingAdress();
        

        this.getCardDetails();
    }

    loadPageData() {
        if (this.customerService.loggedInUser == null) {
            this.customerService.loggedInUser = [];
            const _token = JSON.parse(
                this.cookieService.get(APP_CONSTANTS.cookiekey) || "{}"
            );
            this.customerService.loggedInUser.push(_token.data);
        }
        this.user.push(this.customerService.loggedInUser[0]);
        this.getSubscription();
        if (this.customerService.loggedInUser[0].practiceData) {
            if (this.customerService.loggedInUser) {
                this.npinumber =
                    this.customerService.loggedInUser[0].practiceData.npinumber;
                this.npitype =
                    this.customerService.loggedInUser[0].practiceData.npitype[0].name;
            }
        }
    }

    getPrices(product) {
        this.providerAmount = product.provider_cost * product.provider_qty;
        this.totalAmount = this.providerAmount + product.base_fare;
        console.log(this.providerAmount);
        console.log(this.totalAmount);
    }

    async getStates() {
        await this.stateservice.getAll().then((res) => {
            this.states = res;
            this.getBillingAdress()
        });
    }

    onBillingAddress(address: Address): void {
        // const address = item.item.split(",")
        // this.payToAddress.patchValue({city:address[1],state:address[2].trimStart(),zipCode:address[3]})
        // setTimeout(() => {
        //   this.payToAddress.get('address1').setValue(address[0])
        // }, 20);
        // Add your logic here
    
        const addObj: any = {};
    
        address.address_components.forEach((contry) => {
          addObj[contry.types[0]] = contry.short_name;
        });
        let address1 = `${addObj.street_number || ''} ${addObj.route || ''}`;
        let zipCode;
        if (addObj.postal_code_suffix) {
          zipCode = `${addObj.postal_code} - ${addObj.postal_code_suffix}`;
        } else {
          zipCode = addObj.postal_code;
        }

        // this.payToAddress.patchValue({
        //     city: addObj.locality,
        //     state: addObj.administrative_area_level_1,
        //     zipCode: zipCode,
        //   });
        //   setTimeout(() => {
        //     this.payToAddress.get("address1").setValue(address1);
        //   }, 20);

        this.BillingAdressInfo[0].address1 = address1 || '';
        this.BillingAdressInfo[0].city = addObj.locality || '';
        this.stateName= addObj.administrative_area_level_1 || '';
        this.BillingAdressInfo[0].postalCode = zipCode || '';
      }
    getBillingAdress() {
        console.log(this.customerService.loggedInUser);
        this.customerService
            .getBillingDetails(this.customerService.loggedInUser[0].id)
            .subscribe((res: any) => {
                console.log(res);
                this.BillingAdressInfo = res.map((data)=>{data.isEdit = false
                    return data;
                });
                this.stateName = res[0].state;
            //     
            //   let index =  this.states.findIndex((state)=>{
            //        return state._id === res[0].state
            //     })
            //     this.stateservice.getSingleState(this.states[index]._id).then((res) => {
            //         console.log(res)
            //         this.stateName = res.name;
            //      });
            });
    }

    getSubscription() {
        this.activeSubscriptionList = [];
        this.customerService
            .getSubscriptions(this.customerService.loggedInUser[0].id)
            .subscribe((res) => {
                this.subcriptionData = res;
                this.activeSubscriptionList = res;
                this.ngxService.stop();
                this.getProducts();
            });
    }

    getInvoice() {
        this.customerService
            .getInvoices(this.customerService.loggedInUser[0].id)
            .subscribe((res) => {
                console.log(res);
                this.InvoiceData = res;
                this.InvoiceData.forEach((item) => {
                    if (item.total > 0) {
                        this.IsShowTotal = true;
                    } 
                });

            });
    }

    getView(invoice_pdf) {
        this.safeUrl =
            this.sanitizer.bypassSecurityTrustResourceUrl(invoice_pdf);
    }

    order_Id: any;
    selectedproductName: any;
    updatedOrder(order) {
        console.log(order);
        this.order_Id = order.order_id;
        this.selectedproductName = order.product_name;
    }

    cancelSubscription(order) {
        this.customerService
            .cancelSubscriptions(
                this.customerService.loggedInUser[0].id,
                this.order_Id
            )
            .subscribe((res) => {
                console.log(res);
                
                this.toastService.success("Unsubscribed Successfully");
                this.getSubscription();
            });
    }

    getProducts() {
        this.productService.getProductsByCategory().then((data) => {
            this.InactiveSubscriptionList = data;
            console.log(this.InactiveSubscriptionList);
            console.log(this.activeSubscriptionList);
            this.finalInactiveList = [];
                this.InactiveSubscriptionList.forEach((item:any) => {
                 const activeList =   this.activeSubscriptionList.findIndex(items=>{ 
                    return items.product_id === item._id})
                    if(activeList === -1) {
                        this.finalInactiveList.push(item);
                    }else {
                        
                        this.activeSubscriptionList[activeList].logo_small = item.logo_small;
                        this.activeSubscriptionList[activeList].banner = item.banner;
                        this.activeSubscriptionList[activeList].name = item.name;
                        this.activeSubscriptionList[activeList].price = item.price;
                        this.activeSubscriptionList[activeList]._id = item._id;
                    }
                    // if (data.product_id == item._id) {
                    //     data.logo_small = item.logo_small;
                    //     data.banner = item.banner;
                    //     this.finalInactiveList = this.removeByAttr(
                    //         this.InactiveSubscriptionList,
                    //         "_id",
                    //         item._id
                    //     );
                    // }
                });
            setTimeout(() => {
            this.productService.isLoading(false);
            }, 1000);
            console.log(this.finalInactiveList);
        });
    }

    removeByAttr(arr, attr, value) {
        var i = arr.length;
        while (i--) {
            if (
                arr[i] &&
                arr[i].hasOwnProperty(attr) &&
                arguments.length > 2 &&
                arr[i][attr] === value
            ) {
                arr.splice(i, 1);
            }
        }
        return arr;
    }

    // GetCustomerOrders() {
    //     this.activeSubscriptionList = [];
    //     this.customerService
    //         .getOrderByCustomerId(this.user[0].id)
    //         .then((data) => {
    //             if (data.length == 0) {
    //                 this.toastService.error(
    //                     "Subscription data not found. Please contact Administrator",
    //                     "Subscription Error"
    //                 );
    //                 return;
    //             }
    //             if (data) {
    //                 this.order.push(data);
    //                 console.log("orders", this.order);
    //                 data.forEach((element) => {
    //                     this.productService
    //                         .getProductsById(element.items[0].product)
    //                         .then((item) => {
    //                             item.autorenewal = element.items[0].autorenewal;
    //                             item.renewalDate = element.items[0].renewalDate;
    //                             item.subscriptionDate =
    //                                 element.items[0].subscriptionDate;
    //                             item.subscriptionType =
    //                                 element.items[0].subscriptionType;
    //                             item.orderId = element.items[0].orderId;
    //                             this.activeSubscriptionList.push(item);
    //                             this.ngxService.stop();
    //                             this.getProducts();
    //                             console.log(
    //                                 "activeList",
    //                                 this.activeSubscriptionList
    //                             );
    //                         });
    //                 });
    //             }
    //         });
    // }

    show2(product: any) {
        if (this.cartService.cartItemList.length == 0) {
            this.total = 0;
            this.cartService.addToCart(product);
            this.productName = product.name;
            this.orderId = product._id;
            this.updateTotal();
        }
    }

    updateTotal() {
        this.showTrans = false;
        this.cartService.cartItemList.forEach((cartItem) => {
            this.items.push({
                name: cartItem.name,
                quantity: 1,
                category: "DIGITAL_GOODS",
                unit_amount: {
                    currency_code: "USD",
                    value: cartItem.price,
                },
            });
            this.total += parseFloat(cartItem.price) * 1;
        });
    }

    updateOrder(product) {
        this.ngxService.start();
        let cItems = [];
        let dt = new Date();
        dt.setMonth(dt.getMonth() + 1);
        this.customerService.subscription = {
            transactionId: "",
            subscriptionId: "",
        };
        this.item = {
            product: "",
            amount: "",
            status: "",
            txnId: "",
            subscriptionDate: new Date(),
            renewalDate: dt,
            subscriptionType: "Monthly",
            autorenewal: false,
            createdby: "1",
            createdon: new Date(),
            modifiedby: "1",
            modifiedon: new Date(),
        };
        this.updateorder = {
            customerId: "",
            items: [],
        };
        this.total = 0;
        this.cartService.removeFromCart(product);
        this.cartService.addToCart(product);
        this.productName = product.name;
        this.orderId = product._id;
        this.updateTotal();
        const orderIds = this.order[0];
        this.updateorder.customerId = this.customerService.loggedInUser[0].id;
        if (this.cartService.cartItemList.length > 0) {
            for (let i = 0; i < this.cartService.cartItemList.length; i++) {
                this.item.product = this.cartService.cartItemList[i]._id;
                this.item.amount = this.cartService.cartItemList[i].price;
                this.item.status = "Active";
                this.item.txnId = this.transId;
                this.item.subscriptionDate = new Date();
                this.item.renewalDate = dt;
                this.item.subscriptionType = "Monthly";
                this.item.autorenewal = false;
                this.item.createdby = "1";
                this.item.createdon = new Date();
                this.item.modifiedby = "1";
                this.item.modifiedon = new Date();
                cItems.push(this.item);
                this.item = new ItemSchema();
            }
        }
        this.updateorder.items = cItems;
        this.customerService.AddOrder(this.updateorder).then((data) => {
            this.transactionId = this.transId;
            this.showTrans = true;
            this.getSubscription();
        },err=>{
            this.ngxService.stop();
            this.toastService.error(err.error.message)

        });
    }

    reloadPage(modalRef: HTMLDivElement) {
        const modal = new Modal(modalRef);
        document.body.classList.remove("modal-open");
        this.router.navigate([this.router.url]);
    }

    UpdateAutoRenewal(event: any, item: any) {
        if (event.target.checked) {
            item.autorenewal = true;
        } else {
            item.autorenewal = false;
        }
        this.item = {
            product: item.product,
            amount: item.amount,
            status: item.status,
            txnId: item.txnId,
            subscriptionDate: item.subscriptionDate,
            renewalDate: item.renewalDate,
            subscriptionType: item.subscriptionType,
            autorenewal: item.autorenewal,
            createdby: item.createdby,
            createdon: item.createdon,
            modifiedby: this.user[0].id,
            modifiedon: new Date(),
        };
        this.updateorderrenewal = {
            customerId: "",
            _id: "",
            items: [],
        };
        this.updateorderrenewal.customerId = this.user[0].id;
        this.updateorderrenewal._id = item._id;
        this.updateorderrenewal.items.push(this.item);
        this.customerService
            .UpdateAutoRenewal(this.updateorderrenewal)
            .then((data) => {});
    }

    ////////////////////////finalized code

    getSubscriptionforCreatingOrders(product) {
        this.customerService
            .AddOrder(this.order)
            .then((data) => {
                console.log(data);
            })
            .catch((error) => {
                //console.log("Add Subscription" + error);
                this.toastService.error(
                    "An error occurred while the Subscription is created!!!, Please contact Administrator",
                    "Error"
                );
            });
    }

    getCardDetails(): void {
        this.customerService.getPaymentMethods(this.userid).subscribe(
            (res) => {
                this.cardDetails = res;
                this.cardDetails.unshift(
                    this.cardDetails.splice(
                        this.cardDetails.findIndex(
                            (item) => item.default_payment_method === true
                        ),
                        1
                    )[0]
                );

                this.ngxService.stop();
                console.log(this.cardDetails);
                console.log(res);
            },
            (err) => {
                this.productService.isLoading(false);
                this.toastService.error(err.error.message)
            }
        );
    }

    async getUserDetails() {
        if (this.customerService.loggedInUser.length > 0) {
            await this.customerService
                .getUserById(this.customerService.loggedInUser[0].id)
                .then((res) => {
                    this.customerService.user = res;
                    this.customerService.user._id = res._id;
                    this.customerService.user.firstname = res.firstname;
                    this.customerService.user.lastname = res.lastname;
                    this.customerService.user.email = res.email;
                    this.customerService.user.password = res.password;
                    this.customerService.user.phone = res.phone;
                    this.customerService.user.usertype = res.usertype[0]._id;
                    this.customerService.user.createdby = res._id;
                    this.customerService.user.createdon = res.createdon;
                    this.customerService.user.modifiedby = res._id;
                    this.customerService.user.modifiedon = new Date();
                    this.username = res.firstname;
                    this.userid = res._id;
                    this.getCardDetails();
                    this.user.push(res);
                    
                    this.userInfo.push(this.user[1]);
                    //console.log( "user details------------" + JSON.stringify(this.customerService.user)  );
                });
        }
    }

    deleteModal(cardInfo) {
        
        console.log(cardInfo);
        this.CardId = cardInfo.id;
        this.last4digits = cardInfo.last4;
        this.branding = cardInfo.brand;
    }

    async addPayment() {
        
        const cardElement = this.cardNumber.element;
        // Create a Payment Method using the card element
        const result = await this.stripeService.createPaymentMethod({
            type: "card",
            card: cardElement,
        });
        result.subscribe((result) => {
            console.log("hit", result);
            if(result?.error?.type == 'validation_error'){
                this.cardError = true;
                this.cardErrorMethod = result?.error?.message;
            }
            if(result?.paymentMethod){
            this.cardError = false;                
            this.ngxService.start();
            this.customerService
                .savePaymentMethods(
                    { payment_method_id: result?.paymentMethod?.id },
                    this.userid
                )
                .subscribe(
                    (res) => {
                        if (this.setasPrimary) {
                            this.setDefaultCard(result.paymentMethod.id);
                        } else {
                            this.getCardDetails();
                        }
                        this.getCardDetails();
                        this.saveCancelClick.nativeElement.click();
                        this.ngxService.stop();
                    },
                    (err) => {
                        this.toastService.error(err?.error?.message);
                        this.cardNumber.element.clear();
                        this.cardExpiry.element.clear();
                        this.cardCvc.element.clear();
                        this.setasPrimary = false;
                        this.cardError =false;
                        this.ngxService.stop();
                    }
                ); 
            }
        });
    }
       // call this method to set deafult
       setDefaultCard(paymentMethodId) {
        this.customerService
            .setDefaultCard({ payment_method_id: paymentMethodId }, this.userid)
            .subscribe(
                (res) => {
                    this.getCardDetails();
                    this.closebutton.nativeElement.click();
                },
                (err) => {}
            );
    }

      // call this method to set deafult
      setpopupDefaultCard(paymentMethodId) {
        // this.ngxService.start();
        
        this.ngxService.start();
        this.customerService
            .setDefaultCard({ payment_method_id: this.CardId }, this.userid)
            .subscribe(
                (res) => {
                    this.getCardDetails();
                },
                (err) => {}
            );
    }

     // call this method to set deafult
     deleteDefaultCard(paymentMethodId) {
        
        console.log(this.CardId);
        this.customerService
            .deleteDefaultCard(this.CardId, this.userid)
            .subscribe(
                (res) => {
                    console.log(res);
                    this.getCardDetails();
                    this.closebutton.nativeElement.click();
                },
                (err) => {}
            );
    }

    clearform() {
        this.cardNumber.element.clear();
        this.cardExpiry.element.clear();
        this.cardCvc.element.clear();
        this.setasPrimary = false;
        this.cardError =false;
    }

    formatPhoneNumber(event: any) {
        let a = event.target.value;
    
        if (a.length === 6) {
          let phnNum = event.target.value.replace(/[^0-9]/g, "");
          phnNum = phnNum.slice(0, 2);
          event.target.value = phnNum;
        }
    
        let phoneNumber = event.target.value.replace(/[^0-9]/g, "");
    
        if (phoneNumber.length >= 3 && phoneNumber.length < 7) {
          phoneNumber = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}`;
        } else if (phoneNumber.length >= 7) {
          phoneNumber = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
            3,
            6
          )}-${phoneNumber.slice(6, 10)}`;
        }
    
        event.target.value = phoneNumber;
      }


      updateBillingAddrs(id) {
        
        let payLoad = {
            "address1": this.BillingAdressInfo[0].address1,
            "address2": '',
            "postalCode": this.BillingAdressInfo[0].postalCode,
            "city": this.BillingAdressInfo[0].city,
             "state": this.BillingAdressInfo[0].state,
             "country": this.BillingAdressInfo[0].country
            }
            this.BillingAdressInfo[0].isEdit = !this.BillingAdressInfo[0].isEdit
        this.customerService.updateBillingAddress(payLoad,id).subscribe((res)=>{
            this.getBillingAdress();
          this.BillingAdressInfo[0].isEdit = !this.BillingAdressInfo[0].isEdit


        })
      }
}
