<form [formGroup]="reviewForm" (ngSubmit)="onSubmit()">
  <h3><b>Review Your Order</b></h3>
  <div class="col-xl-12  m-auto">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6">
          <p class="aambgreen_color font-large">Order Summary </p>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 text-end">
          <a role="button" class="btn btn_green_color btn-md mb-10" (click)="redirectTopricing()">Change Order</a>
        </div>
      </div>
      <div class="card">
        <table class="table table-order">
          <thead>
            <tr>
              <th>Product</th>
              <th>Price</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of cartItems[0];let i=index;">
              <td style="width:50%;">{{item.name}}</td>
              <td style="width:30%;">$ {{item.price}}/ Month</td>
              <td style="width:20%;">$ {{item.price}}</td>
            </tr>
            <tr>
              <td colspan="2"><span style="float:right; margin-right: 35px;">Amount to be paid per month :</span></td>
              <td><strong> $ {{ grandTotal}}</strong></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-xl-12 mt-2">
        <span class="red-color" style="font-size: 15px;">
          <i class="fa fa-info-circle"></i>
          Pricing is subject to changes based off of products and/or NPI's added
        </span>
        </div>
    </div>
  </div>

  <div class="col-xl-12" style="padding-top:40px; text-align: center;">
    <button class="btn btn-danger custom-large-btn">Confirm</button>
  </div>
</form>