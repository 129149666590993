export class SubscriptionOrder {
customerId!: String;
items: ItemSchema[];
}
export class  ItemSchema{
    product!: String;
    amount!: String;
    status!: String;
    txnId!: String;
    subscriptionDate!: Date;
    renewalDate!: Date;
    subscriptionType!: String;
    autorenewal!: Boolean;
    createdby!: String;
    createdon!: Date;
    modifiedby!: String;
    modifiedon!: Date;
}
export class UpdateOrder{
    customerId!: String;
    items: ItemSchema[];
}
export class UpdateOrderRenewal{
    customerId!: String;
    _id!: String;
    items: ItemSchema[];
}
