import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { lastValueFrom } from "rxjs";
import { Practice } from "../models/practice";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class PracticeService {
    readonly baseURL = environment.baseURL;
    readonly userURL = environment.mainURL;
    readonly orderURL = environment.orderURL;
    readonly npiUrl = environment.npiURL;
    readonly mainURL = environment.mainURL;

    constructor(private http: HttpClient) {}

    AddPractice(data: Practice): Promise<any> {
        try {
            console.log(JSON.stringify(data));
            return lastValueFrom(
                this.http.post(this.userURL + "/practice/add", data)
            );
        } catch (error: any) {
            throw new Error(error.message);
        }
    }
    ValidateNPINumber(npinumber: string)
    {
       // https://npiregistry.cms.hhs.gov/api/?version=2.1&number=1234567890
       try {
        console.log(JSON.stringify(npinumber));
        return lastValueFrom(
            this.http.get(this.mainURL + "/npiproviders?npiNumber="+npinumber+"")
        );
    } catch (error: any) {
        throw new Error(error.message);
    }
    }
}
