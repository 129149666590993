<div class="reg-con">
    <div class="container-fluid">
        <div class="header">
            <a class="logo" href="/home">AAAMB</a>
        </div>
        <section class="reg-login">
            <div class="col-xl-12">
                <div class="row" style="min-height: 76vh">
                    <div class="col-lg-7 col-md-12 col-sm-12 left-sec">
                        <h3>Enter your Email and instructions will be sent to your Email!</h3>
                    </div>
                    <div class="col-lg-5 col-md-12 col-sm-12 right-sec">
                        <h3>
                           Reset Password
                        </h3>
                        <!-- <div class="alert alert-success text-center mb-4" role="alert">
                            Enter your Email and instructions will be sent to you!
                        </div> -->
                        <form class="" [formGroup]="resetForm">
                            <div class="mb-3">
                                <label>Email Address<span>*</span></label>
                                <input type="email" formControlName="email" class="form-control" id="floatingInput"
                                    placeholder="name@example.com" required="true" [ngClass]="{
                                        'is-invalid': submitted && f.email.errors}" />
                            </div>

                            <div style="text-align:center;">
                            <button class="btn btn-primary" type="submit"  (click)="onSubmit()">
                                Submit</button>
                                </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div class="col-xl-12 footer">Copyright ©2024 AAAMB LLC. All rights reserved.</div>
</div>
