import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { KeycloakProfile } from "keycloak-js";
import { first } from "rxjs";
import { AuthService, LoginService } from "src/app/core";
import { login } from "src/app/shared/models/user";
import { AuthenticationService } from "src/app/shared/services/authentication.service";
import { CustomerService } from "src/app/shared/services/customers/customers.service";
import { KeycloakAngularModule, KeycloakService } from "keycloak-angular";
import { LocalService } from "src/app/shared/services/local.service";
import { environment } from "src/environments/environment";
import { CookieService } from "ngx-cookie-service";
import { APP_CONSTANTS } from "src/app/shared/utils/appconstants";
@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
    public siteURL = environment.siteURL;
    public isLoggedIn = false;
    public userProfile: KeycloakProfile | null = null;
    submitted = false;
    statusCode = false;
    noaccess = false;
    loginUser!: login;
    showPassword = false;
    loginForm = this.fb.group({
        email: [
            null,
            Validators.compose([
              Validators.required,
              Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/),
            ]),
          ],
        password: ["", [Validators.required]],
        rememberMe: [false],
    });
    constructor(
        private readonly keycloak: KeycloakService,
        private fb: UntypedFormBuilder,
        private auth: CustomerService,
        private authentication: AuthenticationService,
        private authService: AuthService,
        private loginService: LoginService,
        private cookieService: CookieService,
        private router: Router
    ) {
        this.loginUser = {
            email: "",
            password: "",
            applicationName: "",
        };
    }
    get f() {
        return this.loginForm.controls;
    }
    async ngOnInit() {
        localStorage.clear();
        this.isLoggedIn = await this.keycloak.isLoggedIn();
        if (this.isLoggedIn) {
            this.userProfile = await this.keycloak.loadUserProfile();
           // console.log("token-----------" + JSON.stringify(await this.keycloak.getToken()));
           // console.log("userProfile-----------" + JSON.stringify(this.userProfile));
            await this.getLoggedInUserData(this.userProfile.email);

           this.router.navigate(['/dashboard']);
        }
        else
        {
            this.keycloak.login();
        }
    }
   async checkPracticeData() {
    //console.log("practice data-------"+JSON.stringify(this.auth.loggedInUser[0]));
        const practiceData =  await this.auth.loggedInUser[0].practiceData;

        if (!practiceData || practiceData == "") {
            this.router.navigate(["/profilesetup"]);
            return;
        } else {
            //console.log('dashboard')
            this.router.navigate(["/dashboard"]);
        }
    }
    get email() {
        return this.loginForm.get("email");
    }

    get password() {
        return this.loginForm.get("password");
    }

    async login() {
        try {
            this.noaccess = false;
            this.statusCode = false;
            this.submitted = true;
            if (this.loginForm.invalid) {
               // console.log("invalid");
                return;
            }
            this.loginUser = {
                email: this.email?.value,
                password: this.password?.value,
                applicationName: "MAIN",
            };
            await this.authentication
                .login(this.email?.value, this.password?.value, "MAIN")
                .pipe(first())
                .subscribe({
                    next: async (res: any) => {
                        // get return url from query parameters or default to home page
                        //  const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
                        // this.router.navigateByUrl(returnUrl);
                        if (!res.data) {
                            this.statusCode = true;
                            return;
                        }
                        if (res) {
                            if (res.data.usertype.name !== "Admin") {
                                this.noaccess = true;
                                return;
                            }
                            // this.auth.loggedInUser = res.data;
                            this.auth.loggedInUser = [];
                            this.auth.loggedInUser.push(res.data);
                            //console.log("logged in user----------------" + JSON.stringify(res.data) );
                            //  localStorage.setItem("user",JSON.stringify(this.auth.loggedInUser[0]));
                            const practiceData = res.data.practiceData;

                            //alert(practiceData);
                            if (!practiceData || practiceData == "") {
                                this.router.navigate(["/profilesetup"]);
                                return;
                            } else {
                                this.router.navigate(["/dashboard"]);
                            }
                        }
                    },
                    error: (errorRes: HttpErrorResponse) => {
                        console.log(errorRes);
                        if(errorRes.error && errorRes.error.message.statusCode==401){
                            this.statusCode = true;
                            return;
                        }
                        if (errorRes.status === 401) {
                            const form = this.loginForm;
                            const errors = errorRes.error.errors;
                            Object.keys(errors).forEach((key) => {
                                form.get(
                                    key === "email" ? "username" : key
                                )?.setErrors({
                                    remote: errors[key][0],
                                });
                            });
                        }
                    },
                });
        } catch (error: any) {
            throw new Error(error);
        }
    }
    async getLoggedInUserData(email: string){
        //console.log("getLoggedInUserData---------")
        await this.authentication.signedInUserData(email.toLowerCase())
        .pipe(first())
        .subscribe({
            next: async (res: any) => {
               // console.log("getLoggedInUserData---------"+JSON.stringify(res));
                // get return url from query parameters or default to home page
                //  const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
                // this.router.navigateByUrl(returnUrl);
                if (!res.data) {
                    this.statusCode = true;
                    return;
                }
                if (res) {
                    if (res.data.usertype.name !== "Admin") {
                        this.noaccess = true;
                        return;
                    }
                    // this.auth.loggedInUser = res.data;
                    this.auth.loggedInUser = [];
                    this.auth.loggedInUser.push(res.data);
                   // console.log("practice data-------175"+JSON.stringify(this.auth.loggedInUser[0]));
                    //  localStorage.setItem("user",JSON.stringify(this.auth.loggedInUser[0]));
                    await this.checkPracticeData();
                }
            },
            error: (errorRes: HttpErrorResponse) => {
                if (errorRes.status === 422) {
                    const form = this.loginForm;
                    const errors = errorRes.error.errors;
                    Object.keys(errors).forEach((key) => {
                        form.get(
                            key === "email" ? "username" : key
                        )?.setErrors({
                            remote: errors[key][0],
                        });
                    });
                }
            },
        });
    }
    goBack(){
        this.cookieService.delete(APP_CONSTANTS.cookiekey, '/');
        this.keycloak.logout(APP_CONSTANTS.homeUrl);
        this.router.navigate(['/home']);
    }
    toggleShowPassword() {
        this.showPassword = !this.showPassword;
    }
}
