<form [formGroup]="paymentForm" (ngSubmit)="onSubmitPayment()">
    <h3><b>Payment Details</b></h3>
    <div class="col-xl-12">
        <div class="container">
            <div class="col-lg-10 p-3 mt-4 pt-4" style="
                    margin: 0 auto;
                    background-color: #fff;
                    border-radius: 20px;
                ">
                <div class="row">
                    <div class="stripe-card" ngxStripeCardGroup [elementsOptions]="elementsOptions">
                        <div class="row">
                            <div class="col-lg-6 col-sm-12 col-md-6">
                                <h4 class="aambgreen_color pb-0 mb-0">
                                    Pay with Card
                                </h4>
                            </div>
                            <div class="col-lg-6">
                                <img src="assets/img/card_logo.png" class="m-t-6" alt="payment Logos" />
                            </div>
                        </div>
                        <hr />
                        <h6 *ngIf="cardError" class="red_color text-center">{{cardErrText}}</h6>

                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="stripe-element form-group">
                                <label for="cardNummber" class="Label">Card Number</label>
                                <ngx-stripe-card-number class="borders form-control" id="cardNummber" #cardNumber
                                    [options]="cardOptions"></ngx-stripe-card-number>
                            </div>
                        </div>
                        <div class="row m-bottom-30">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="w-full stripe-element form-group">
                                    <label class="Label" for="cardExpiry">Expiration Date</label>
                                    <ngx-stripe-card-expiry class="form-control" id="cardExpiry" #cardExpiry
                                        [options]="cardOptions"></ngx-stripe-card-expiry>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="w-full stripe-element form-group">
                                    <label for="cardCVV">CVC</label>
                                    <ngx-stripe-card-cvc class="form-control" id="cardCVV" #cardCvc
                                        [options]="cardOptions"></ngx-stripe-card-cvc>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="w-full stripe-element">
                                <label for="cardholderName"
                                    >Cardholder Name</label
                                >
                                <ngx-stripe-card-name
                                    class="form-control"
                                    id="cardholderName"
                                    #cardholderName
                                    [options]="cardOptions"
                                ></ngx-stripe-card-name>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <!-- end #form-container -->
        </div>
        <br />
        <div class="row m-top-35">
            <div class="col-lg-12 text-center">
                <button type="button" class="btn btn-danger custom-large-btn" (click)="createToken()" mat-raised-button>
                    Subscribe Now
                </button>
            </div>
        </div>
    </div>
</form>