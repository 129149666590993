<footer class="footer-area">
    <div class="container-fluid footer-top">   
            <div class="container m-auto">
                <div class="row">
                    <div class="col-lg-6 col-sm-4">
                            <img src="assets/img/aaamb-logo-new.png" class="f-logo" alt="logo">
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-8 d-flex">
                        <div class="col-lg-5  col-md-5  col-sm-6">
                            <h5>Services</h5>
                            <ul class="footer-links">
                             <li><a href="#">Practice Management</a></li>
                             <li><a href="#">Revenue Cycle Management</a></li>
                             <li><a href="#">Medical Billing</a></li>
                             <li><a href="#">Information Technology</a></li>
                            </ul>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <h5>products</h5>
                            <ul class="footer-links">
                             <li><a href="#">Charge Capture</a></li>
                             <li><a href="#">Credential Today</a></li>
                             <li><a href="#">Paitent Scheduling</a></li>
                             <li><a href="#">Benefit Verification</a></li>

                            </ul>
                         </div>
                         <div class="col-lg-4 col-md-3  col-sm-2">
                            <h5>Quick Links</h5>
                            <ul class="footer-links">
                             <li><a href="/testimonials">Testimonials</a></li>
                             <li><a href="#">News</a></li>
                             <li><a href="#">Contact Us</a></li>
                            </ul>
                         </div>
                    </div>

                </div>
            </div>
        
    </div>

   <div class="container">
    <div class="row">
        <div class="container m-auto f-btm">
            <div class="row">
                <!-- <div class="col-xl-5 col-sm-12">
                    <ul>
                        <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="icofont-brand-youtube"></i></a></li>
                    </ul>
                </div> -->
                <div class="col-xl-6 col-sm-12 col-lg-6 f-txt">
                    Copyright ©2024 AAAMB LLC. All rights reserved.
                </div>
                <div class="col-xl-6 col-sm-12 col-lg-6 f-txt float_right text-end">
                    <a href='{{siteURL}}/terms-service' target="_blank">Terms of Service</a>&nbsp;&nbsp; <a href="/privacy-policy" target="_blank">Privacy Policy</a>
                </div>
            </div>
        </div>

    </div>
   </div>
</footer>

<!-- <div class="go-top"><i class="icofont-swoosh-up"></i></div> -->

<app-demo-sidebar></app-demo-sidebar>

