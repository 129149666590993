<section id="testimonial" class="testimonial-area ptb-100 bg-image">
    <div class="container">
        <div class="title pb-home">
            <h3><span>Words</span> From Clients</h3>
        </div>
        <div class="container testi-con mt-3">
            <div class="row">
                <div class="col-8">
            <p>At AAA Medical Billing Services, we offer quality and detail oriented end-to-end revenue cycle, practice management, contract analysis, medical billing and other services with emphasis on completeness and efficiency. Our courteous Medical Billing Service staff have the professional tools and experience necessary to help with all your needs. Call today to get in touch with our staff and to learn more about what we have to offer.</p>
        </div>   
        </div>
        </div>
        <div class="testimonial-slides owl-carousel owl-theme">
            <div class="testimonial-item">
                <div class="client-image">
                    <img src="assets/img/author1.jpg" alt="image">
                </div>
                <div class="testimonial-content">
                    <div class="desc">
                        <p>I highly recommend AAA Medical Billing Services. All of the staff members are extremely
                            professional, have excellent communication skills, and provide great service. I wouldn't choose
                            any other Medical Billing Service.</p>
                    </div>
                    <div class="client-info">
                        <h3>Jennifer CTO</h3>
                        <span>THNIK PRICING</span>
                    </div>
                </div>
            </div>
            <div class="testimonial-item">
                <div class="client-image">
                    <img src="assets/img/author2.jpg" alt="image">
                </div>
                <div class="testimonial-content">
                    <div class="desc">
                        <p>I highly recommend AAA Medical Billing Services. All of the staff members are extremely
                            professional, have excellent communication skills, and provide great service. I wouldn't choose
                            any other Medical Billing Service.</p>
                    </div>
                    <div class="client-info">
                        <h3>Steven Smith</h3>
                        <span>THNIK PRICING</span>
                    </div>
                </div>
            </div>
            <div class="testimonial-item">
                <div class="client-image">
                    <img src="assets/img/author3.jpg" alt="image">
                </div>
                <div class="testimonial-content">
                    <div class="desc">
                        <p>I highly recommend AAA Medical Billing Services. All of the staff members are extremely
                            professional, have excellent communication skills, and provide great service. I wouldn't choose
                            any other Medical Billing Service.</p>
                    </div>
                    <div class="client-info">
                        <h3>Alina Eva</h3>
                        <span>THNIK PRICING</span>
                    </div>
                </div>
            </div>


            <!-- <div class="testimonial-item">
                <div class="client-image">
                    <img src="assets/img/author4.jpg" alt="image">
                </div>
                <div class="testimonial-content">
                    <div class="desc">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed malesuada vulputate nisi in fermentum. Vivamus ac libero quis nisi auctor pulvinar. Aenean sit amet lectus posuere, mattis massa eget, ullamcorper diam. Nunc sit amet felis eget arcu congue dictum.</p>
                    </div>
                    <div class="client-info">
                        <h3>Steven Smith</h3>
                        <span>Product Manager</span>
                    </div>
                </div>
            </div> -->
        </div>
       
    </div>
</section>
<section class="viewpricing">
    <div class="container">
      <div class="row">
        <div class="col-xl-8 m-auto">
          <h3>Join millions of happy users</h3>
          <h2>Now is the time to subscribe <br> for hassle-free service.</h2>
          <a class="btn btn-white" (click)="redirectToPricing()">View Pricing</a>
        </div>
      </div>
    </div>
  </section>
