<section class="page-title-area-narrow ">
    <div class="container">
        <h2>Dashboard</h2>
    </div>
</section>
<div class="container-fluid" style="margin-top: 100px !important; max-width: 400px;;">
    <div class="row">
        <div class="mb-2">
            <div class="card">
                <div class="col-10 col-xl-10">
                <h4 class="card-title p-5">
                    Hello! Welcome
                </h4>
            </div>
            </div>
        </div>
    </div>
</div>
