import { Component, OnInit } from '@angular/core';

import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { otpEmail } from 'src/app/shared/models/user';
import { CustomerService } from 'src/app/shared/services/customers/customers.service';

@Component({
  selector: 'app-recoverpwd',
  templateUrl: './recoverpwd.component.html',
  styleUrls: ['./recoverpwd.component.scss']
})
export class RecoverpwdComponent implements OnInit {

  // set the currenr year
  year: number = new Date().getFullYear();

  resetForm: UntypedFormGroup;
  submitted = false;
  error = '';
  success = '';
  loading = false;
  resetemail!: otpEmail;
  constructor(private formBuilder: UntypedFormBuilder,
    private userService: CustomerService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService) {
      this.resetemail = {
        email : ''
      }
    }

  ngOnInit(): void {
    this.resetForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.resetForm.controls; }

  get email() {
    return this.resetForm.get('email');
  }
  /**
   * On submit form
   */
  onSubmit() {

    this.success = '';
    this.submitted = true;
   // this.toastr.success('Hello world!', 'Toastr fun!');
    // stop here if form is invalid
    if (this.resetForm.invalid) {
      return;
    }
    this.userService.EmailExists(this.resetForm.controls.email.value).then((res) => {
      //console.log('email exists'+JSON.stringify(res));
      if(res)
      {
          if(res.emailexists)
          {
            this.toastr.error('Email you entered doesn\'t exists', 'Error');
          }
          else{
            console.log('-------email---------' + this.email?.value );
            this.resetemail = {
              email :  this.email?.value,
              name: res.firstname+" "+res.lastname
            }
            this.userService.SendResetEmail(this.resetemail).then((res) => {
              console.log('-------------------------  this.resetemail' + res);
              if(res)
              {
                if(res.status == true)
                {
                    this.toastr.success("Reset email sent to your email. Please check your inbox.", "Reset Password");
                    this.resetForm.reset();
                    return;
                }
              }
            });
             // this.signupAaamb.controls.email.setErrors({ exists: 'Email already exists' });
              //this.emailexists = true;
          }
        }
      });
  }

}
