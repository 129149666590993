<div class="reg-con">
    <div class="header">
        <a class="logo" href="/home">AAAMB</a>
    </div>
    <div class="col-xl-12 sub-success">
        <h1 class="aambgreen_color"><b>Thank you!</b></h1>
        <h5 class=" aambgreen_color font_18">You have successfully subscribed to<span *ngFor="let items of this.selectedCartItems"
                ><b>{{items.name}}</b></span>
        </h5>
        <div *ngIf="showTrans">
            <!-- <p>Your Transaction Id is :<b>{{transactionid}}</b></p> -->
        </div>
        <img src="assets/img/subscribe-success.png" width="600" />
        <br /><a (click)="redirectToProvider()" class="btn btn-danger btn-mw btn-t-5">Get Started</a>
    </div>

    <div class="container mb-20">
        <div class="row">
          <div class="col-xl-6 col-sm-12 col-lg-6 f-txt aambgreen_color">
              Copyright ©2024 AAAMB LLC. All rights reserved.
          </div>
          <div class="col-xl-6 col-sm-12 col-lg-6 f-txt text-end">
              <a href='{{siteURL}}/terms-service' target="_blank" class="aambgreen_color">Terms of Service</a>&nbsp;&nbsp; <a href="/privacy-policy" class="aambgreen_color" target="_blank">Privacy Policy</a>
          </div>
        </div>
      </div>

</div>