<div id="admin-container">
    <!--Title-->
    <div class="container-fluid">
        <div class="row  ml-5 m-top-10">
            <div class="col-md-12 col-lg-12 col-sm-12">
                <div class="page-title-box d-flex align-items-center">
                    <div class="page-title-left">
                        <h2 class="card-title title_header">My Products</h2>
                    </div>
                    <span class="ml-5 m-top-5">
                        <!-- <button class="btn btn_popover_noborder me-2" placement="end"
                    ngbPopover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus." popoverTitle="Dashboard Info">
                    <i class="fa fa-info-circle _info_color_icon"></i>
                    </button> -->
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div clas="container-fluid">
        <div class="row admin-cont ml-10">

            <!-- <div class="pricing-table pricing_boxes" aria-colcount="">
                <div class="prdct-con">
                    <img src="{{ product.logo_small }}" width="80" />
                </div>
                <div class="product-label">
                    <label><b>{{ product.product_name }}</b></label>
                </div>
                <label class="productstatus white_color">Active</label>
                <div>
                    <hr style="margin: 15px 14px 5px;color: #004745!important;" />
                  
                    
                  
                    
                  
                </div>
            </div> -->
            <!--static Content-->
            <div class="col-lg-3 col-xl-2 col-md-6 col-xs-12 text-center" *ngFor="let product of this.activeSubscriptionList">
                <div class="pricing-table pricing_boxes text-center " style="border: 1px solid #004745 !important;">
                        <div class=" prdct-con text-center"><img src="{{product.logo_small}}" width="67"></div>
                        
                        <div class="product-label">
                            <label><b>{{ product.name }}</b></label>
                        </div>
                        <!-- <div class="prod-info">
                            <label><b>{{product.name}}</b></label>
                            <a href="{{product.productlink}}" target="_blank" class="btn btn-aaamb-1">Go-To Product <i
                                    class="fa fa-external-link" aria-hidden="true"></i></a>
                        </div> -->

                        <div>
                            <hr style="margin: 15px 14px 5px;color: #004745!important; border-top: 0.2px solid; opacity: 1;" />
                            <a [ngClass]="{'disabled-link': !product.active}" href="{{getProdUrl(product.name, product.productlink)}}" target="_blank" class="btn btn-aaamb-1 m-4 disabled-link">Go-To Product 
                                <i *ngIf="product.active"
                                class="fa fa-external-link " aria-hidden="true"></i>
                                <i *ngIf="!product.active" class="fa fa-plug-circle-xmark"></i>
                            </a>

                        </div>
                    <div>
                    </div>
                </div>
            </div>
            <div class="text-center justify-content-center h-25 position-relative msg-top" *ngIf="!activeSubscriptionList.length">
                <img src="assets/img/no-data-6.svg" alt="No_data_icon" class="img-size" />
            <br>
                <h3 class="d-block mt-3">No Active Products</h3>
            </div>
        </div>
    </div>
    <app-loader></app-loader>
</div>