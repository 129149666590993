import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import {
    HttpClient,
    HttpEvent,
    HttpHeaders,
    HttpRequest,
} from "@angular/common/http";
import { environment } from "src/environments/environment";
import { UpdatePracticeLogo } from "../models/products";

@Injectable({
    providedIn: "root",
})
export class ImageUploadService {
    readonly userURL = environment.mainURL;
    httpOptions = {
        headers: new HttpHeaders({
            "Content-Type": "application/x-www-form-urlencoded",
        }),
    };
    constructor(private http: HttpClient) {}

    upload(file: File) {
        const formData: FormData = new FormData();
        formData.append("file", file);
        return this.http.post(`${this.userURL}/practice/upload`, formData);
    }
    updatePracticeLogo(data: UpdatePracticeLogo){
        return this.http.post(`${this.userURL}/practice/updatelogo`, data);
    }
}
