<div class="col-lg-10 col-xl-10 m-auto">
    <form [formGroup]="signupAaamb" (ngSubmit)="onSubmit()">
        <h3><b>Tell Us About Yourself</b></h3>
        <div class="mb-3 mt-3 custom-align">
            <div class="col-xl-6">
                <div>
                    <label for="useremail">First Name<span>*</span></label>
                    <input type="email" formControlName="firstName" [ngClass]="{
                            'is-invalid': submitted && f.firstName.errors
                        }" class="form-control fl rounded" id="userfirstname" placeholder="Enter First Name"
                        required="true" style="width: 95%" />
                </div>
                <div class="text_errors ml-15 text-capitalize d-block" *ngIf="
                            (submitted && !signupAaamb.get('firstName').valid) ||
                            (!signupAaamb.get('firstName').valid &&
                                signupAaamb.get('firstName')?.touched) 
                        ">
                    First Name Required.
                </div>
            </div>
            <div class="col-xl-6">
                <div>
                    <label for="useremail"> Last Name<span>*</span></label>
                    <input type="text" formControlName="lastName"
                        [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" class="form-control rounded"
                        id="lastname" placeholder="Enter Last Name" />
                </div>
                <div class="text_errors text-capitalize ml-15" *ngIf="
                            (submitted && !signupAaamb.get('lastName').valid) ||
                            (!signupAaamb.get('lastName').valid &&
                                signupAaamb.get('lastName')?.touched) 
                        ">
                    Last Name Required.
                </div>
            </div>
        </div>

        <div class="col-xl-12 mb-3 m-auto">
            <label for="useremail">Email Id<span>*</span></label>
            <input type="email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                class="form-control rounded" id="useremail" placeholder="Enter Email Id"
                (keyup)="onChangeEvent($event)" />
        </div>
        <div class="col-xl-12 m-auto">
            <div class="text_errors ml-15 m-t-10" *ngIf="
                    (submitted && !signupAaamb.get('email')?.valid) ||
                    (signupAaamb.get('email')?.touched &&
                        !signupAaamb.get('email')?.valid)
                ">
                Email Required.
            </div>
            <div class="text_errors ml-15 m-t-10" *ngIf="emailexists">
                Email Already Exist.
            </div>
        </div>
        <div class="col-xl-12 mb-3 m-auto">
            <label for="password">Password<span>*</span></label>
            <div class="input-group auth-pass-inputgroup">
                <input [type]="showPassword ? 'text' : 'password'" formControlName="password" name="password" #password
                    [value]="password" (ngModel)="(password)" [(ngModel)]="newpassword"
                    (keyup)="pressNewPassword($event.target.value)"
                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }" class="form-control rounded" id="pwd"
                    placeholder="Enter Password" />
                <div (click)="showPassword = !showPassword" class="show-password-icon">
                    <i [class]="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'" aria-hidden="true"></i>
                </div>
            </div>
            <div class="col-xl-12 mt-2 m-auto">
                <div class="text_errors text-capitalize ml-15" *ngIf="
                        (submitted && !signupAaamb.get('password').valid) ||
                        (!signupAaamb.get('password').valid &&
                            signupAaamb.get('password')?.touched)
                    ">
                    Password required.
                </div>
                <div class="text_errors ml-0" *ngIf="
                        signupAaamb.get('password')?.touched &&
                        f.password.errors
                    ">
                    <label class="col validcol" [ngClass]="
                            signupAaamb.controls['password'].hasError(
                                'required'
                            ) ||
                            signupAaamb.controls['password'].hasError(
                                'minlength'
                            )
                                ? 'text-danger'
                                : 'text-success'
                        ">
                        <i class="material-icons">{{
                            signupAaamb.controls["password"].hasError(
                            "required"
                            ) ||
                            signupAaamb.controls["password"].hasError(
                            "minlength"
                            )
                            ? "cancel"
                            : "check_circle"
                            }}</i>
                        Must be at least 8 characters!
                    </label>
                    <label class="col validcol" [ngClass]="
                            signupAaamb.controls['password'].hasError(
                                'required'
                            ) ||
                            signupAaamb.controls['password'].hasError(
                                'hasNumber'
                            )
                                ? 'text-danger'
                                : 'text-success'
                        ">
                        <i class="material-icons">{{
                            signupAaamb.controls["password"].hasError(
                            "required"
                            ) ||
                            signupAaamb.controls["password"].hasError(
                            "hasNumber"
                            )
                            ? "cancel"
                            : "check_circle"
                            }}</i>
                        Must contain at least 1 number!
                    </label>
                    <label class="col validcol" [ngClass]="
                            signupAaamb.controls['password'].hasError(
                                'required'
                            ) ||
                            signupAaamb.controls['password'].hasError(
                                'hasCapitalCase'
                            )
                                ? 'text-danger'
                                : 'text-success'
                        ">
                        <i class="material-icons">{{
                            signupAaamb.controls["password"].hasError(
                            "required"
                            ) ||
                            signupAaamb.controls["password"].hasError(
                            "hasCapitalCase"
                            )
                            ? "cancel"
                            : "check_circle"
                            }}</i>
                        Must contain at least 1 in Capital Case!
                    </label>
                    <label class="col validcol" [ngClass]="
                            signupAaamb.controls['password'].hasError(
                                'required'
                            ) ||
                            signupAaamb.controls['password'].hasError(
                                'hasSmallCase'
                            )
                                ? 'text-danger'
                                : 'text-success'
                        ">
                        <i class="material-icons">{{
                            signupAaamb.controls["password"].hasError(
                            "required"
                            ) ||
                            signupAaamb.controls["password"].hasError(
                            "hasSmallCase"
                            )
                            ? "cancel"
                            : "check_circle"
                            }}</i>
                        Must contain at least 1 Letter in Small Case!
                    </label>
                    <label class="col validcol" [ngClass]="
                            signupAaamb.controls['password'].hasError(
                                'required'
                            ) ||
                            signupAaamb.controls['password'].hasError(
                                'hasSpecialCharacters'
                            )
                                ? 'text-danger'
                                : 'text-success'
                        ">
                        <i class="material-icons">{{
                            signupAaamb.controls["password"].hasError(
                            "required"
                            ) ||
                            signupAaamb.controls["password"].hasError(
                            "hasSpecialCharacters"
                            )
                            ? "cancel"
                            : "check_circle"
                            }}</i>
                        Must contain at least 1 Special Character!
                    </label>
                </div>
            </div>
        </div>
        <div class="col-xl-12 mb-3 m-auto">
            <label for="cpassword">Confirm Password<span>*</span></label>
            <div class="input-group auth-pass-inputgroup">
                <input [type]="showCPassword ? 'text' : 'password'" formControlName="confirmPassword"
                    (keyup)="removeWhiteSpacesconfirmpassword($event)" [(ngModel)]="confirmpassword" [ngClass]="{
                        'is-invalid': submitted && f.confirmPassword.errors
                    }" class="form-control rounded" id="cpwd" placeholder="Enter Confirm Password" />
                <div (click)="showCPassword = !showCPassword" class="show-password-icon">
                    <i [class]="showCPassword ? 'fa fa-eye' : 'fa fa-eye-slash'" aria-hidden="true"></i>
                </div>
                <div class="col-xl-12 mt-2 m-auto">
                    <div class="text_errors text-capitalize ml-15" *ngIf="
                            submitted &&
                            !signupAaamb.get('confirmPassword')?.touched
                        ">
                        Confirm Password required.
                    </div>
                </div>
                <div class="text_errors ml-15 m-t-10 move-danger" *ngIf="
                        submitted &&
                        signupAaamb.get('confirmPassword')?.touched &&
                        f.confirmPassword.errors
                    ">
                    <span class="text-danger" *ngIf="
                            submitted &&
                            signupAaamb.controls['confirmPassword'].hasError(
                                'NoPasswordMatch'
                            )
                        ">
                        Password and Confirm Password do not match.
                    </span>
                </div>
            </div>
        </div>
        <div class="col-xl-12 mb-3">
            <label for="phone">Cell Phone Number<span>*</span></label>
            <div style="display: flex">
                <div><input formControlName="pcode" class="form-control usflag rounded" disabled value="+1" /></div>
                <div><input formControlName="phone" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
                        (keypress)="numberOnly($event)" maxlength="14" placeholder="(xxx) xxx-xxxx"
                        class="form-control rounded" appPhoneMask id="phone-number" />
                    <div class="col-xl-12 m1-50">
                        <div class="text_errors ml-15" *ngIf="
                                (submitted && !signupAaamb.get('phone').valid) ||
                                (signupAaamb.get('phone').touched &&
                                    !signupAaamb.get('phone').valid)
                            ">
                            Phone Number Required.
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.email">
                Email must be a valid email address.
            </div>
        </div>
        <ul class="list-inline wizard w-100">
            <li class="next list-inline-item w-100">
                <div class="justify-content-center signup" style="padding: 5px 0">
                    <input type="checkbox" formControlName="terms" [ngClass]="{
                            'is-invalid': submitted && f.terms.errors
                        }" style="vertical-align: middle" (change)="onCheckboxChange($event, 'modal-2')" />
                    <small class="text-muted">I have read and agree to the Terms of Services and
                        Privacy<span>*</span>
                    </small>
                    <br />
                </div>
                <div class="text_errors m-t-10" *ngIf="submitted && !signupAaamb.get('terms').valid">
                    You must accept the Terms of Services and Privacy Policy to
                    register an account
                </div>
                <div class="col-xl-12" style="text-align: center">
                    <button class="btn btn-danger custom-large-btn">
                        Sign Up
                    </button>
                </div>
                <!-- <p class="text-center text-black" style="margin-bottom:1.5rem;">OR</p> -->
            </li>
            <!-- <li style="display: flex; justify-content: space-between;"><button class="btn cst-btn-wt" #loginRef>Continue With  <img src="assets/img/google-logo.png"/></button>
        &nbsp;&nbsp;&nbsp;<button class="btn cst-btn-wt">Continue With  <img src="assets/img/win-logo.png"/></button></li> -->
        </ul>
        <br /><br />
    </form>
</div>
<!-- Modal -->
<div id="modal-2" class="modal modal-backdrop" tabindex="-1" role="dialog" [ngStyle]="{ display: displayStyle }">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header modal-header--sticky">
                <h5 class="modal-title" style="color: #004745">
                    Terms of Service & Privacy Policy
                </h5>
                <!-- <button type="button" class="btn-close" (click)="modalService.close()"></button> -->
            </div>
            <div class="modal-body signup_step1_modal_body">
                <div class="ppptb-local">
                    <app-terms-of-service></app-terms-of-service>
                </div>
                <hr />
                <br /><br /><br />
                <div class="ppptb-local-150">
                    <app-privacy-policy></app-privacy-policy>
                </div>
            </div>
            <div class="modal-footer modal-footer--sticky" style="margin-top: 50px">
                <button style="margin-top: 25px" type="button" class="btn btn-primary" (click)="closeModal()">
                    I Agree
                </button>
            </div>
        </div>
    </div>
</div>