import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { lastValueFrom, Observable, switchMap } from "rxjs";

import { login, otpEmail, resetpassword, User } from "../../models/user";
import { Address } from "cluster";
import { CustomerAddress } from "../../models/address";
import {
    SubscriptionOrder,
    UpdateOrder,
    UpdateOrderRenewal,
} from "../../models/order";
import { MainUser } from "../../models/mainuser";
import { environment } from "src/environments/environment";
import { Subscription } from "../../models/subscription";
import { NpiType } from "../../models/npitype";
import * as xml2js from "xml2js";
@Injectable({
    providedIn: "root",
})
export class CustomerService {
    readonly baseURL = environment.baseURL;
    readonly userURL = environment.mainURL;
    readonly orderURL = environment.orderURL;
    readonly prodURL = environment.prodURL;
readonly kcServiceURL = environment.kcapiHost;
    //readonly kcServiceURL = 'http://3.6.5.222:3000';
    readonly kcUrl = environment.kcURL;
    readonly uspsURl = environment.uspsURl;
    httpOptions = {
        headers: new HttpHeaders({
            "Content-Type": "application/x-www-form-urlencoded",
        }),
    };
    loggedInUser: any[];
    user: User;
    customerAddress: CustomerAddress;
    mainUser: MainUser;
    UserList!: User[];
    optemail: otpEmail = {
        name: "",
        email: "",
    };
    npiInfo!: NpiType;
    practiceInfo: any[];
    productInfo: any[];
    subscription!: Subscription;
    constructor(private http: HttpClient) {}

    AddKCUser(data: User, token: string): Promise<any> {
        try {
            if (token) {
                this.httpOptions = {
                    headers: new HttpHeaders({
                        "Content-Type": "application/json;charset=UTF-8",
                        Authorization: `Bearer ${token}`,
                    }),
                };
                console.log(JSON.stringify(data));
                return lastValueFrom(
                    this.http.post(
                        this.kcServiceURL + "/user",
                        data,
                        this.httpOptions
                    )
                );
            }
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    GetAccessToken(): Promise<any> {
        try {
            return lastValueFrom(
                this.http.post(this.kcServiceURL + "/token", "")
            );
        } catch (error: any) {
            console.log(error.message);
            throw new Error(error.message);
        }
    }

    UpdateUserKCPassword(
        userId: string,
        data: any,
        token: string
    ): Promise<any> {
        try {
            if (token) {
                this.httpOptions = {
                    headers: new HttpHeaders({
                        "Content-Type": "application/json;charset=UTF-8",
                        Authorization: `Bearer ${token}`,
                    }),
                };
                console.log(JSON.stringify(data));
                return lastValueFrom(
                    this.http.put(
                        this.kcUrl +
                            `/admin/realms/${environment.realm}/users/` +
                            userId +
                            "/reset-password",
                        data,
                        this.httpOptions
                    )
                );
            } else {
                throw new Error("token required");
            }
        } catch (error: any) {
            console.log(JSON.stringify(error));
            throw new Error(error.message);
        }
    }

    AddUser(data: User): Promise<any> {
        try {
            console.log(JSON.stringify(data));
            return lastValueFrom(
                this.http.post(this.userURL + "/user/addcustomer", data)
            );
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    userOnboardingInfo(data: User): Promise<any> {
        try {
            console.log(JSON.stringify(data));
            return lastValueFrom(
                this.http.post(this.userURL + "/user/onboarding-info", data)
            );
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    UserDataByEmail(email: string): Promise<any> {
        try {
            //console.log(JSON.stringify(email));
            return lastValueFrom(
                this.http.post(this.userURL + "/user/signedindata", email)
            );
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    UserDataByEE(email: string): Promise<any> {
        try {
            const loginUser = {
                email: email,
            };
            // console.log(JSON.stringify(email));
            return lastValueFrom(
                this.http.post(
                    this.userURL + "/user/getUserDataByEE",
                    loginUser
                )
            );
        } catch (error: any) {
            throw new Error(error.message);
        }
    }
    UpdateUser(data: MainUser): Promise<any> {
        try {
            console.log(JSON.stringify(data));
            return lastValueFrom(
                this.http.put(this.userURL + "/user/addcustomer", data)
            );
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    updateBillingAddress(data,id) {
      //  http://localhost:8003/customer/address/644cd7992d059360d45f5682

        return this.http.put(
            this.baseURL + `/customer/address/${id}`,
            data
        );
       
    }

    AddAddress(data: CustomerAddress): Promise<any> {
        try {
            console.log(JSON.stringify(this.baseURL));
            return lastValueFrom(
                this.http.post(this.baseURL + "/customer/address", data)
            );
        } catch (error: any) {
            throw new Error(error.message);
        }
    }
    AddOrder(data: SubscriptionOrder): Promise<any> {
        try {
            console.log(JSON.stringify(data));
            return lastValueFrom(
                this.http.post(this.orderURL + "/shopping/order", data)
            );
        } catch (error: any) {
            throw new Error(error.message);
        }
    }
    getOrderByCustomerId(_id: string): Promise<any> {
        try {
            return lastValueFrom(
                this.http.get(this.orderURL + `/shopping/orders/${_id}`)
            );
        } catch (error: any) {
            throw new Error(error.message);
        }
    }
    GetUserType(): Promise<any> {
        try {
            return lastValueFrom(this.http.get(this.userURL + "/usertype/"));
        } catch (error: any) {
            throw new Error(error.message);
        }
    }
    GetOtp(name: string, email: string): Promise<any> {
        try {
            this.optemail.name = name;
            this.optemail.email = email;
            return lastValueFrom(
                this.http.post(this.userURL + "/user/sendotp", this.optemail)
            );
        } catch (error: any) {
            throw new Error(error.message);
        }
    }
    SendWelcomeEmail(
        email: string,
        name: string,
        company: string,
        products: string
    ): Promise<any> {
        try {
            var data = {
                email: email,
                name: name,
                company: company,
                products: products,
            };
            return lastValueFrom(
                this.http.post(this.userURL + "/user/sendwelcomeemail", data)
            );
        } catch (error: any) {
            throw new Error(error.message);
        }
    }
    EmailExists(email: string): Promise<any> {
        try {
            this.optemail.email = email;
            return lastValueFrom(
                this.http.post(
                    this.userURL + "/user/emailexists",
                    this.optemail
                )
            );
        } catch (error: any) {
            console.log(JSON.stringify(error));
            throw new Error(error.message);
        }
    }

    login(pft: login) {
        return this.http.post(this.userURL + "/user/login", pft);
    }
    postUser(pft: User) {
        return this.http.post(this.baseURL, pft);
    }
    getUserById(_id: string): Promise<any> {
        console.log("------------------------" + this.userURL + `/${_id}`);
        try {
            return lastValueFrom(this.http.get(this.userURL + `/user/${_id}`));
        } catch (error: any) {
            throw new Error(error.message);
        }
    }
    getUsersByPractice(_id: string): Promise<any> {
        console.log("------------------------" + this.userURL + `/${_id}`);
        try {
            return lastValueFrom(
                this.http.get(this.userURL + `/user/practice/${_id}`)
            );
        } catch (error: any) {
            throw new Error(error.message);
        }
    }
    putUser(pft: User): Promise<any> {
        try {
            console.log(JSON.stringify(pft));
            console.log(this.userURL + `/user/${pft._id}`);
            return lastValueFrom(
                this.http.put(this.userURL + `/user/${pft._id}`, pft)
            );
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    deleteUser(_id: string) {
        try {
            return lastValueFrom(this.http.delete(this.baseURL + `/${_id}`));
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    SendResetEmail(data: otpEmail): Promise<any> {
        try {
            console.log(JSON.stringify(data));
            return lastValueFrom(
                this.http.post(this.userURL + "/user/sendresetlink/email", data)
            );
        } catch (error: any) {
            throw new Error(error.message);
        }
    }
    ResetPassword(data: resetpassword): Promise<any> {
        try {
            console.log(JSON.stringify(data));
            return lastValueFrom(
                this.http.post(this.userURL + "/user/resetpassword", data)
            );
        } catch (error: any) {
            throw new Error(error.message);
        }
    }
    UpdateOrder(data: UpdateOrder): Promise<any> {
        try {
            console.log("update order data----" + JSON.stringify(data));
            return lastValueFrom(
                this.http.put(this.orderURL + "/shopping/updateorder", data)
            );
        } catch (error: any) {
            throw new Error(error.message);
        }
    }
    UpdateAutoRenewal(data: UpdateOrderRenewal): Promise<any> {
        try {
            console.log("update auto renewal data----" + JSON.stringify(data));
            return lastValueFrom(
                this.http.put(
                    this.orderURL + "/shopping/updateautorenewal",
                    data
                )
            );
        } catch (error: any) {
            throw new Error(error.message);
        }
    }
    getUSPSVeifyAddress(data: any) {
        //console.log("------------------------" + this.uspsURl + `/${data}`);
        try {
            return this.http
                .get(this.uspsURl + `/${data}`, { responseType: "text" })
                .pipe(switchMap(async (xml) => await this.parseXmlToJson(xml)));
        } catch (error: any) {
            throw new Error(error.message);
        }
    }
    async parseXmlToJson(xml) {
        // With parser
        /* const parser = new xml2js.Parser({ explicitArray: false });
        parser
          .parseStringPromise(xml)
          .then(function(result) {
            console.log(result);
            console.log("Done");
          })
          .catch(function(err) {
            // Failed
          }); */

        // Without parser
        return await xml2js
            .parseStringPromise(xml, { explicitArray: false })
            .then((response) => response.AddressValidateResponse.Address);
    }
    // http://3.6.5.222:8001/customer/address-details
    getBillingDetails(Id) {
        return this.http.get(`${this.baseURL}/customer/address-details/` + Id);
    }

    getProductDetails(id) {
        return this.http.get<User[]>(environment.prodURL + `/product/` + id);
    }

    // http://3.6.5.222:8003+`/shopping/orders/${_id}`)
    getProductIds(id) {
        return this.http.get<User[]>(
            environment.orderURL + `/shopping/orders/` + id
        );
    }

    getSubscriptions(id) {
        return this.http.get(
            environment.orderURL + `/shopping/allsubscriptions/` + id
        );
    }

    getInvoices(id) {
        return this.http.get(environment.orderURL + `/shopping/invoices/` + id);
    }

    cancelSubscriptions(id, oid) {
        return this.http.get(
            environment.orderURL +
                `/shopping/user/` +
                id +
                `/orders/` +
                oid +
                `/cancel`
        );
    }

    // change of the password
    changePassword(email, oldPassword, newPassword): Observable<any> {
        const credentialData = {
            email: email,
            oldPassword: oldPassword,
            newPassword: newPassword,
        };

        return this.http.post<any>(
            `${this.userURL}/user/changePassword`,
            credentialData
        );
    }

    getPaymentMethods(id) {
        return this.http.get<User[]>(
            this.userURL + `/user/${id}/payment-methods`
        );
    }

    savePaymentMethods(data, id) {
        return this.http.post(
            this.userURL + `/user/${id}/payment-methods`,
            data
        );
    }

    setDefaultCard(data, id) {
        return this.http.put(
            this.userURL + `/user/${id}/payment-methods`,
            data
        );
    }

    deleteDefaultCard(paymentMethodId, id) {
        return this.http.delete(
            this.userURL + `/user/${id}/payment-methods/${paymentMethodId}`
        );
    }
}
