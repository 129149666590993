import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { first } from "rxjs";
import { AuthenticationService } from "src/app/shared/services/authentication.service";
import { CartService } from "src/app/shared/services/cart/cart.service";
import { CustomerService } from "src/app/shared/services/customers/customers.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-order-status",
    templateUrl: "./order-status.component.html",
    styleUrls: ["./order-status.component.scss"],
})
export class OrderStatusComponent implements OnInit {
    public siteURL = environment.siteURL;
    transactionid: String = "";
    subscriptionid: String = "";
    showTrans = false;
    finalProductsList: any;
    itemsselected: boolean = false;
    selectedCartItems: any;
    noaccess: boolean;

    constructor(
        public userService: CustomerService,
        private cartService: CartService,
        private router: Router,
        private authentication:AuthenticationService
    ) {}

    ngOnInit(): void {
        console.log(JSON.stringify(localStorage.getItem("txnId")));
        if (!this.userService.subscription) {
            if (localStorage.getItem("txnId")) {
                this.transactionid = localStorage.getItem("txnId");
                this.subscriptionid = localStorage.getItem("subId");
                this.showTrans = true;
            }
    }
    else
    {
      this.transactionid = this.userService.subscription.transactionId;
      this.subscriptionid = this.userService.subscription.subscriptionId;
      localStorage.setItem("subId",this.userService.subscription.subscriptionId.toString());
      localStorage.setItem("txnId",this.userService.subscription.transactionId.toString());
      this.showTrans = true;
    }
    this.selectedCartItems = this.cartService.cartItemList
  }
    async redirectToProvider(): Promise<void> {  
        await this.authentication
            .login(this.userService.user.email, this.userService.user.password, "MAIN")
            .pipe(first())
            .subscribe({
                next: async (res: any) => {
                    if (res) {
                        if (res.data.usertype.name !== "Admin") {
                            this.noaccess = true;
                            return;
                        }
                        // this.auth.loggedInUser = res.data;
                        this.userService.loggedInUser = [];
                        this.userService.loggedInUser.push(res.data);
                        //console.log("logged in user----------------" + JSON.stringify(res.data) );
                        //  localStorage.setItem("user",JSON.stringify(this.auth.loggedInUser[0]));
                        const practiceData = res.data.practiceData;

                        //alert(practiceData);
                        if (!practiceData || practiceData == "") {
                            this.router.navigate(["/profilesetup"]);
                            return;
                        } else {
                            this.router.navigate(["/dashboard"]);
                        }
                    }
                }
            })
    }

}
