<section id="testimonial" class="testimonial-area ptb-100 bg-image">
    <div class="container">
        <div class="section-title mt-5 mb-5">
            <h2>Terms of Service</h2>
        </div>
        <div class="container testi-con" style="text-align: justify;">
            <div class="row">
            <p><b>Security of Data:</b> All personal, professional and financial data related to the credentialing project of the Client, will be kept in strictest confidence by AAAMB. It is AAAMB’s policy to protect the security of Client data to the maximum extent possible and in accordance with the current Government Guidelines and online Security protocols.</p>
            <p><b>Use of Confidential Information:</b> Except as directly necessary for performing its obligation AAAMB will not use, reproduce or disclose to others any such Confidential Information without the prior written consent of the Disclosing Party. The Client agrees that the information supplied to the AAAMB is accurate, complete and truthful. That the client will provide AAAMB with all copies of documents and information required by payers, and the copies of the correspondence from Third Party Payers. AAAMB will not change or alter any information supplied by the client. AAAMB will maintain a record of documents provided for a period of 3 years and will forward to the payers in a timely manner as long as provided by the Client in a timely manner.</p>
            <p><b>Limitation of Liability (Disclaimer):</b> AAAMB will use reasonable care in processing credentialing and contracting tasks for the Client. AAAMB will not be held responsible for errors caused by Client or Client’s representatives and staff, payer or payer representatives.  </p>
            <p><b>Termination:</b> This agreement shall be terminated, either with or without cause, by Client after giving thirty (30) days prior written notice to AAAMB. However, AAAMB may terminate the Service agreement, either with or without causes only giving after sixty (60) days prior written notice to Client or allow Client a suitable time frame to make alternative arrangement for the credentialing needs. Upon termination of this agreement by either party, Client agrees to promptly pay all outstanding charges owed as of the final termination date.</p>
            <p><b>Non Liability:</b> AAAMB shall not be liable for the failure to perform any of its obligations under these terms when such failure is caused by conditions beyond the reasonable control of said party or by accident, act of God, repairs, equipment failures, strike or labor disturbances.</p>
            <p><b>Compensation:</b> Client will pay AAAMB as compensation for the service provided in accordance with the following: Charges for credentialing service rendered by the AAAMB shall be auto-ran at the time of initiation of requested credentialing project and monthly, based on AAAMB service fees. </p>
            <p><b>Authorization:</b> By accepting these terms, I hereby authorize AAAMB representative to execute all documents pertaining to the credentialing management of my practice in accordance to the outline service request.</p>
            </div>
        </div>

    </div>
</section>
