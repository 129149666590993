import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { AdminLayoutComponent } from "../theme/admin-layout/admin-layout.component";
import { ProductsComponent } from "./products/products.component";
import { SubscriptionsComponent } from "./subscriptions/subscriptions.component";
import { UsersComponent } from "./users/users.component";
import { ReportsComponent } from "./reports/reports.component";
import { SettingsComponent } from "./settings/settings.component";
import { AuthGuard } from "../core/authentication/auth.guard";
import { PrivacyPolicyComponent } from "../components/layouts/privacy-policy/privacy-policy.component";
import { TermsOfServiceComponent } from "../components/layouts/terms-of-service/terms-of-service.component";

const routes: Routes = [
    {
        path: "",
        component: AdminLayoutComponent,
        children: [
            {
                path: "",
                component: DashboardComponent,
                // data: {
                //     roles: ["admin"],
                // },
                canActivate: [AuthGuard],
            },
            {
                path: "dashboard",
                 component: DashboardComponent,
                // data: {
                //     roles: ["admin"],
                // },
                canActivate: [AuthGuard],
            },
            {
                path: "myproducts",
                component: ProductsComponent,
                canActivate: [AuthGuard],
            },
            {
                path: "subscriptions",
                component: SubscriptionsComponent,
                 canActivate: [AuthGuard],
            },
            {
                path: "users",
                component: UsersComponent,
                 canActivate: [AuthGuard],
            },
            {
                path: "reports",
                component: ReportsComponent,
                 canActivate: [AuthGuard],
            },
            {
                path: "settings",
                component: SettingsComponent,
                 canActivate: [AuthGuard],
            },
        ],
    },
    {path: 'privacy-policy', component: PrivacyPolicyComponent},
    {path: 'terms-service', component: TermsOfServiceComponent},
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AdminRoutingModule {}
