
const origin = window.location.origin;
const envSetup = true;
var isProd = false;

const HTTP  = 'https://';
var DevHTTP  = '';
var SiteHTTP  = '';
var subUrl = '';

if(origin.includes('dev')){
  DevHTTP  = HTTP + 'dev.';
  SiteHTTP = HTTP + 'dev.app.';
} else if(origin.includes('uat')){
  DevHTTP  = HTTP + 'uat.';
  SiteHTTP = HTTP + 'uat.app.';
} else if(origin.includes('localhost')){
  DevHTTP  = HTTP + 'localhost.';
  SiteHTTP = HTTP + 'localhost.';
} else {
  DevHTTP  = HTTP + '';
  SiteHTTP = HTTP + 'app.';
  isProd = true;
}


export const APP_NEW_CONSTANTS = {
  cookiekey:  'aaamb-client',
  cookieexpiry: '7',
  imgurl: DevHTTP + 'admin.aaamb.com/api',
  USPS_ID: "05WAAAMB72833",
  USPS_REV: 1,
  homeUrl: SiteHTTP + 'aaamb.com'+subUrl+'/login'
};

export const APP_OLD_CONSTANTS = {
  cookiekey:  'aaamb-client',
  cookieexpiry: '7',
  imgurl: 'http://3.6.5.222:8010/',
  USPS_ID: "05WAAAMB72833",
  USPS_REV: 1,
  homeUrl: "http://3.6.5.222:8004/login"
};

export const APP_CONSTANTS = envSetup ? APP_NEW_CONSTANTS : APP_OLD_CONSTANTS;
