import { Component, OnInit } from '@angular/core';
import { ProductsService } from '../shared/services/products/products.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
 isLoading = true;
  constructor( private loadSpinner: ProductsService) { }

  ngOnInit(): void {
    this.loadSpinner.isloading.subscribe((isload) => {
      this.isLoading = isload;
    });
  }

}
