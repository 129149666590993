export interface User {
    _id?: string;
    firstname?:  string;
    lastname?: string;
    email?: string;
    password?:  string;
    otp?: string;
    phone?: string;
    kcid?: string;
    usertype?: string;
    practice?: string;
    createdby?: string;
    createdon?: Date;
    modifiedby?: string;
    modifiedon?: Date;
    payment_method_id?: String;
  }
export class login{
    email?: string;
    password?: string;
    applicationName?: string

}
export class otpEmail{
    name?: string;
    email?: string;
}
export class resetpassword{
    email?: string;
    password?: string;
    confirmPassword?: string

}

export interface KCUser {
    id?: string;
    enabled?: boolean;
    firstName: string;
    lastName: string;
    email: string;
    username: string;
    credentials?: [KCUserAttributes];
    groups?: [string];
}
export interface KCUserAttributes{
    type?: string;
    value?: string;
    temporary?: boolean
}

