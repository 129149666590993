import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgWizardService } from 'ng-wizard';
import { CartService } from 'src/app/shared/services/cart/cart.service';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-step-three',
  templateUrl: './step-three.component.html',
  styleUrls: ['./step-three.component.scss']
})
export class StepThreeComponent implements OnInit {
    cartItems = [];
    grandTotal: string ;
    reviewForm: UntypedFormGroup;
  constructor( private cartService:CartService,
    private dataService: DataService,
    private ngWizardService: NgWizardService,
    private formBuilder:UntypedFormBuilder,
    private router: Router
    ) {
    this.reviewForm = this.formBuilder.group({
      });
  }

  ngOnInit() {
    this.dataService.getClickEvent().subscribe((value:any)=>{
        if(value == 'cart'){
            //console.log('----wizrd'+JSON.stringify(this.cartService.cartItemList));
            this.cartItems.push(this.cartService.cartItemList);

            //console.log('----cartitems----------'+JSON.stringify(this.cartItems));
            this.grandTotal = this.cartService.getTotalPrice();
        }
    });
  }
  get f() { return this.reviewForm.controls; }
  onSubmit(){
    this.ngWizardService.next();
  }

  redirectTopricing() {
    this.router.navigate(['/products'], { queryParams: {id:'pricing'} });
  }

}
