import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {

  constructor(private route: ActivatedRoute, private elementRef: ElementRef,private router: Router) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      if (id) {
        setTimeout(() => {
        this.scrollToSection(id);
        }, 200);
      }
    });
  }
  
  scrollToSection(sectionId: string): void {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  redirectToPricing() {
    this.router.navigate(['/products'], { queryParams: {id:'pricing'} });
  }
}
