<div id="admin-header">
  <nav class="navbar navbar-expand-lg navbar-dark">
    <div class="container-fluid">
      <!--New mod code-->
      <div class="d-flex align-items-center">
        <div class="flex-shrink-0 m-top-20 mr-10">
          <span class="m-l-5 practice_letter_logo practice_name_icon" *ngIf="!isLogo"
            [ngStyle]="{'background': getRandomColor()}"><span
              class="_letter_name user_letters">{{practiceName}}</span></span>
          <img class="hospital_logo_Practice practice_logo" *ngIf="isLogo" [src]="LogoUrl" alt="Practice Logo" />
        </div>
        <div class="flex-grow-1">
          <h4 class="HeaderTitle m-b-5 m-top-30 text-capitalize">{{ this.currentUser?.name }}</h4>
          <span class="topbar_subTitle">Practice</span>
        </div>
      </div>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">&nbsp;</ul>
        <form class="d-flex">
          <!--Notifications-->
          <ul class="navbar-nav logout_section admin-nav me-auto m-top-20">
            <!-- <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle " href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <i class="fa fa-bell-o notify" aria-hidden="true"></i>
                    </a>
                    <ul class="dropdown-menu notifications-ddown">
                        <li>Notification 1</li>
                        <li>Notification 2</li>
                        <li><a href="#">Notification 3</a></li>
                        <li><a href="#">Notification with a long content 4</a></li>
                      </ul>
                </li>             -->
            <!--Notifications-->
            <!-- <li class="nav-item"><i class="fa fa-user-o profile-pic"></i>
                        <a class="nav-link"   aria-expanded="false">
                         <h6>{{username}}<span>{{role}}</span></h6>
                        </a>
                    </li>
                    <li class="nav-item">
                      <a role="button" (click)="logout()">
                        <i class="fa fa-sign-out" aria-hidden="true"></i>
                      </a>
                  </li> -->
            <div class="col">
              <div class="dropdown">
                <span class="logout_icon" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"
                  (click)="showDropdown()">
                  <section class="user_name_logo" [ngStyle]="{'background': getRandomColor()}"><span
                      class="_letter_name user_letters">{{username}}</span></section>&nbsp;<span
                    class="aambgreen_color font-14 font-weight-500">{{role}}</span> &nbsp;<i
                    class="fa-solid fa-caret-down"></i>
                </span>
                <div class="dropdown-menu" id="dropdown" aria-labelledby="dropdownMenuButton1">
                  <div class="user_info_card" style="width: 30rem;">
                    <div class="card-body user_info_card_body">
                      <div class="row">
                        <div class="col-md-12 col-lg-12 col-sm-12 text-end">
                          <button type="button" class="btn-close text-end aambgreen_color" aria-label="Close"
                            (click)="close($event)"></button>
                        </div>
                       
                      </div>
                      <div class="clearfix">&nbsp;</div>
                      <div class="clearfix">&nbsp;</div>

                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                          <span class="user_name_logo" [ngStyle]="{'background': getRandomColor()}"><span
                              class="_letter_name user_letters">{{username}}</span></span>
                        </div>
                        <div class="clearfix">&nbsp;</div>
                        <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                          <h5 class="aambgreen_color m-0" style="font-weight: 400 !important;">Hi, {{userfullname | titlecase}} </h5>
                          <div class="col-md-12 col-lg-12 col-sm-12 text-center">
                            <h5 class="card-title aambgreen_color mb-2">{{user[0]?.email}}</h5>
                          </div>
                          <p class="font-14 font-weight-500" style="color: #c12228 !important;">{{role}}</p>
                        </div>
                      </div>
                      <div class="clearfix">&nbsp;</div>
                      <div class="row justify-content-md-center">
                        <div class="col-md-5 col-lg-5 col-sm-5 user_add_button bg_standard_color text-center">
                          <button type="button" (click)="settings()" class="btn user_info_button">Settings &nbsp;<i
                              class="fa-solid fa-gear" style="vertical-align:middle;font-weight: 600;"></i> </button>
                        </div>
                        <div class="col-md-5 col-lg-5 col-sm-5 user_signout_button bg_standard_color text-center">
                          <button type="button" class="btn white_color font_1rem user_info_button"
                            id="vertical-menu-btn" (click)="logout()" title="Sign out">
                            Logout &nbsp;&nbsp;<i class="fa fa-sign-out-alt fa-1x"></i>
                          </button>
                        </div>
                      </div>
                      <div class="clearfix">&nbsp;</div>
                      <!-- <div class="row  justify-content-md-center m-bottom-30">
                        <div class="col-md-5 col-lg-5 col-sm-5 text-end">
                          <small><a href="/terms" target="_blank" class="footer_links font-12 aambgreen_color">Terms of
                              Services</a></small>
                        </div>
                        <div class="col-md-5 col-lg-5 col-sm-5">
                          <small><a href="/privacy-policy" target="_blank" class="font-12 aambgreen_color">Privacy
                              Policy</a></small>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </ul>


        </form>
      </div>
    </div>
  </nav>
</div>