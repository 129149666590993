import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

import { Products } from '../../models/products';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  isloading = new EventEmitter<any>();

  readonly baseURL = environment.prodURL;
  productsList!: Products[];
  constructor(private http: HttpClient) { }
  getProductsByCategory(): Promise<any> {
    try {
        console.log('hi');
      return lastValueFrom(this.http.get(this.baseURL+'/category/health'));
		} catch (error: any) {
			throw new Error(error.message);
		}
  }

  isLoading(data) {
    this.isloading.emit(data);
  }
  getProductsMaxId(): Promise<any> {
    try {
      return lastValueFrom(this.http.get(this.baseURL+'/getMaxId'));
		} catch (error: any) {
			throw new Error(error.message);
		}
  }
  AddProducts(data: Products): Promise<any> {
    try {
        return lastValueFrom(this.http.post(this.baseURL, data));
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
  postProducts(pft: Products) {
    return this.http.post(this.baseURL, pft);
  }
  getProductsById(_id: string): Promise<any>  {
    try {
        return lastValueFrom(this.http.get(this.baseURL+ `/product/${_id}`));
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
  putProducts(pft: Products): Promise<any> {
    try {
      return lastValueFrom(this.http.put(this.baseURL + `/${pft._id}`, pft));
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  deleteProducts(_id: string) {
    try {
      return lastValueFrom(this.http.delete(this.baseURL + `/${_id}`));
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
}
