<section id="services" class="services-area ptb-100">
    <div class="container pb-home">
        <div class="row ">
          <div class="col-8">
            <h2><span>What</span> we do</h2>
            <p>AAA Medical Billing Services provides a professional, expert medical billing, collection and administrative
              services for medical practitioners. Whether you require a complete turnkey billing service from start to
              finish or just practice analysis, our experienced medical billing and collection team is sure to impress you.
            </p>
    
            <p> Not all billing services are apt for all customers. We would like to assist you in any way possible, so our
              first step is always to determine what type of services you need and what is important to you. Then we can
              decide what solutions are best for you.</p>
          </div>
          <div class="col-1">&nbsp;</div>
          <div class="col-3">
            <img src="../../../../assets/img/hexagon-bg.jpeg" />
          </div>
    
        </div>
      </div>
    
      <div class="container pb-home ">
        <div class="row align-items">
          <div class="col-1"></div>
          <div class="col-4">
            <img src="../../../../assets/img/medical-practice.png" />
          </div>
          <div class="col-6">
            <h3 class="services-area-header">Practice Management</h3>
            <p>A business check-up is imminent for any business. Whether your business is new or well established, knowing if it is in <q>top condition</q> is imperative.
          </p>
            <button class="btn btn-danger">Learn More</button>
          </div>
    
    
    
        </div>
      </div>
    
      <!---->
      <div class="container pb-home-2">
        <div class="row align-items">
          <div class="col-1"></div>
          <div class="col-6">
            <h3 class="services-area-header">Revenue Cycle Management</h3>
            <p>
              Revenue cycle management (RCM) is a lifeline for any practice. It is a concept that covers all facets of medical billing. Every practice should know about the importance of RCM and its solutions.
    
            </p>
            <button class="btn btn-danger">Learn More</button>
          </div>
          <div class="col-4">
            <img src="../../../../assets/img/medical-practice.png" />
          </div>
          <div class="col-2">&nbsp;</div>
        </div>
      </div>
      <!---->
      <div class="container pb-home-1">
        <div class="row align-items">
          <div class="col-1"></div>
          <div class="col-4">
            <img src="../../../../assets/img/medical-practice.png" />
          </div>
          <div class="col-6">
            <h3 class="services-area-header">Medical Billing</h3>
            <p>
              With increasing demands on providers and decreasing reimbursements, healthcare offices need highly skilled medical billers.
    
            </p>
            <button class="btn btn-danger">Learn More</button>
          </div>
          <div class="col-2">&nbsp;</div>
        </div>
      </div>
      <!---->
      <div class="container pb-home-2">
        <div class="row align-items">
          <div class="col-1"></div>
          <div class="col-6">
            <h3 class="services-area-header">Information Technology</h3>
            <p>
              IT solutions should complement the business needs and not become hindrances to the process.
            </p>
            <button class="btn btn-danger">Learn More</button>
          </div>
          <div class="col-4">
            <img src="../../../../assets/img/medical-practice.png" />
          </div>
          <div class="col-2">&nbsp;</div>
        </div>
      </div>
</section>
