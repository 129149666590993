import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { environment } from '../../environments/environment';


import { AdminRoutingModule } from "./admin-routing.module";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { AdminLayoutComponent } from "../theme/admin-layout/admin-layout.component";
import { SidebarComponent } from "../theme/web-layout/sidebar/sidebar.component";
import { BreadcrumbsComponent } from "../theme/web-layout/breadcrumbs/breadcrumbs.component";
import { HeaderComponent } from "../theme/web-layout/header/header.component";
import { ProductsComponent } from "./products/products.component";
import { SubscriptionsComponent } from "./subscriptions/subscriptions.component";
import { UsersComponent } from "./users/users.component";
import { RolesComponent } from "./roles/roles.component";
import { ReportsComponent } from "./reports/reports.component";
import { NgxPayPalModule } from "ngx-paypal";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SettingsComponent } from "./settings/settings.component";
import { NgxStripeModule } from "ngx-stripe";
import { LoaderComponent } from "../loader/loader.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { RouterModule } from "@angular/router";
import { ConfirmationPopoverModule } from "angular-confirmation-popover";
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";

@NgModule({
    declarations: [
        DashboardComponent,
        AdminLayoutComponent,
        SidebarComponent,
        BreadcrumbsComponent,
        HeaderComponent,
        ProductsComponent,
        SubscriptionsComponent,
        UsersComponent,
        RolesComponent,
        ReportsComponent,
        SettingsComponent,
        LoaderComponent,
    ],
    imports: [
        ReactiveFormsModule,
        CommonModule,
        RouterModule,
        AdminRoutingModule,
        NgxPayPalModule,
        NgxStripeModule.forRoot(
            environment.stripeKey
        ),
        NgxSpinnerModule.forRoot({ type: "ball-scale-multiple" }),
        ConfirmationPopoverModule.forRoot({
            confirmButtonType: 'danger', // set defaults here
          }),  
        NgxUiLoaderModule,
        GooglePlaceModule,
        FormsModule

    ],
})
export class AdminModule {}
