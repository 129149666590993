import { Component, OnInit, AfterViewInit } from "@angular/core";
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { CustomValidators } from "src/app/shared/directives/CustomValidators";
import { resetpassword } from "src/app/shared/models/user";
import { CustomerService } from "src/app/shared/services/customers/customers.service";

@Component({
    selector: "app-passwordreset",
    templateUrl: "./passwordreset.component.html",
    styleUrls: ["./passwordreset.component.scss"],
})

/**
 * Reset-password component
 */
export class PasswordresetComponent implements OnInit, AfterViewInit {
    showPassword = false;
    showConfirmPassword = false;
    isnpandcpsame = false;
    submitted = false;
    error = "";
    success = "";
    loading = false;
    resetpasswordModel: resetpassword;
    resetemail = "";
    // new password validation
    public password: string = "";
    validationsubText: boolean;
    lengthValidation: boolean;
    uppercaseValidation: boolean;
    numberValidation: boolean;
    lowercaseValidation: boolean;
    specialcharValidation: boolean;
    ErrorDisplay: boolean = false;
    // set the currenr year
    year: number = new Date().getFullYear();
    resetForm: UntypedFormGroup;
    // tslint:disable-next-line: max-line-length
    constructor(
        private formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private userService: CustomerService,
        private toastr: ToastrService,
        private router: Router
    ) {
        this.resetForm = this.formBuilder.group({
            newPassword: [
                null,
                [
                    Validators.required,
                    // check whether the entered password has a number
                    CustomValidators.patternValidator(/\d/, {
                        hasNumber: true,
                    }),
                    // check whether the entered password has upper case letter
                    CustomValidators.patternValidator(/[A-Z]/, {
                        hasCapitalCase: true,
                    }),
                    // check whether the entered password has a lower case letter
                    CustomValidators.patternValidator(/[a-z]/, {
                        hasSmallCase: true,
                    }),
                    // check whether the entered password has a special character
                    CustomValidators.patternValidator(
                        /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
                        {
                            hasSpecialCharacters: true,
                        }
                    ),
                    Validators.minLength(8),
                ],
            ],
            confirmPassword: [
                null,
                [
                    Validators.required,
                    Validators.minLength(8),
                ],
            ],
        },
        {
            // check whether our password and confirm password match
            validator: CustomValidators.resetPasswordMatchValidator
          }
        );
    }

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            //console.log(params);
            this.resetemail = decodeURIComponent(params.e);
            //  this.resetemail= this.resetemail.replace(/p1L2u3S/g, '+' ).replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=');
            console.log(this.resetemail);
        });
    }

    ngAfterViewInit() {}

    // convenience getter for easy access to form fields
    get f() {
        return this.resetForm.controls;
    }
    get newpassword() {
        return this.resetForm.get("newPassword");
    }
    get confirmPassword() {
        return this.resetForm.get("confirmPassword");
    }
    toggleShowPassword() {
        this.showPassword = !this.showPassword;
    }
    toggleConfirmShowCPassword() {
        this.showConfirmPassword = !this.showConfirmPassword;
    }
    pressNewPassword(value: any) {
        const upperCaseCharacters = /[A-Z]+/g;
        const lowerCaseCharacters = /[a-z]+/g;
        const numberCharacters = /[0-9]+/g;
        const specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        if (value !== undefined) {
            this.ErrorDisplay = true;
            this.validationsubText = true;
            if (upperCaseCharacters.test(value) === false) {
                this.uppercaseValidation = true;
            } else {
                this.uppercaseValidation = false;
            }
            if (lowerCaseCharacters.test(value) === false) {
                this.lowercaseValidation = true;
            } else {
                this.lowercaseValidation = false;
            }
            if (numberCharacters.test(value) === false) {
                this.numberValidation = true;
            } else {
                this.numberValidation = false;
            }
            if (specialCharacters.test(value) === false) {
                this.specialcharValidation = true;
            } else {
                this.specialcharValidation = false;
            }
            if (
                !this.uppercaseValidation &&
                !this.lowercaseValidation &&
                !this.specialcharValidation &&
                !this.numberValidation
            ) {
                if (value.length < 7) {
                    this.lengthValidation = true;
                    this.validationsubText = true;
                } else {
                    this.lengthValidation = false;
                    this.validationsubText = false;
                }
            }
        } else {
            this.ErrorDisplay = false;
            this.validationsubText = false;
        }
    }
    onSubmit() {
        this.isnpandcpsame = false;
        this.success = "";
        this.submitted = true;
        //console.log(this.newpassword.value);
        //console.log(this.confirmPassword.value);
        // stop here if form is invalid
        if (this.resetForm.invalid) {
            return;
        }
        if (this.newpassword.value !== this.confirmPassword.value) {
            this.isnpandcpsame = true;
            return;
        }
        this.resetpasswordModel = {
            email: "",
            password: "",
            confirmPassword: "",
        };
        this.resetpasswordModel.email = this.resetemail;
        this.resetpasswordModel.password = this.newpassword.value;
        this.resetpasswordModel.confirmPassword = this.confirmPassword.value;

        //console.log("this.resetemail-------------"+JSON.stringify(this.resetemail));
        this.userService.UserDataByEE(this.resetemail).then((user) => {
           // console.log("user-------------"+JSON.stringify(user));
            if(user && user.statusCode !=='User Not found'){
                this.userService.GetAccessToken().then((token:any) =>{
                    let kctoken = token.access_token
                    var pwdData = { "type": "password", "temporary": false, "value": this.newpassword.value }
                    this.userService.UpdateUserKCPassword(user.data.kcid, pwdData, kctoken).then((isdone:any) =>{
                       //console.log("------------------------- " + JSON.stringify(isdone));
                        if(isdone == null){
                            this.userService.ResetPassword(this.resetpasswordModel).then((res) => {
                                //console.log("-------------------------  this.resetemail" + res);
                                if (res) {
                                    if (res.status == true) {
                                        this.toastr.success(
                                            "Password changed successfully. Please login with new password.You will be redirected after 5 seconds.",
                                            "Reset Password",{progressBar: true}
                                        );
                                        this.resetForm.reset();
                                        setTimeout(() => {
                                            this.router.navigateByUrl('/login');
                                          }, 5000);

                                    }
                                }
                            });
                        }
                        else
                        {
                            this.toastr.error(
                                "There is some problem with updating password. Please contact administrator. You will be redirected after 5 seconds.",
                                "Reset Password",{progressBar: true}
                            );
                            setTimeout(() => {
                                this.router.navigateByUrl('/home');
                            }, 5000);
                        }
                    });
                });
            }
            else{
                this.toastr.error(
                    "User details not found. Please contact administrator",
                    "Reset Password"
                );
            }
        });
    }
}
