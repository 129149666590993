import { HttpEventType, HttpResponse } from "@angular/common/http";
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
} from "@angular/core";
import {
    FormControl,
    FormGroup,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { Observable, Subject, first } from "rxjs";
import { Practice } from "src/app/shared/models/practice";
import { Products, UpdatePracticeLogo } from "src/app/shared/models/products";
import { AuthenticationService } from "src/app/shared/services/authentication.service";
import { CartService } from "src/app/shared/services/cart/cart.service";
import { CustomerService } from "src/app/shared/services/customers/customers.service";
import { DataService } from "src/app/shared/services/data.service";
import { ImageUploadService } from "src/app/shared/services/imageupload.service";
import { NpiTypeService } from "src/app/shared/services/main/npitype.service";
import { PracticeService } from "src/app/shared/services/practice.service";
import { ProductsService } from "src/app/shared/services/products/products.service";
import { APP_CONSTANTS } from "src/app/shared/utils/appconstants";
import { HeaderComponent } from "src/app/theme/web-layout/header/header.component";
import { Modal } from "bootstrap";
import { ToastrService } from "ngx-toastr";
import { CustomValidators } from "src/app/shared/directives/CustomValidators";

import { KeycloakService } from "keycloak-angular";
import {
    StripeCardElementOptions,
    StripeElementsOptions,
} from "@stripe/stripe-js";
import { StripeCardComponent, StripeService } from "ngx-stripe";
import { NgxSpinnerService } from "ngx-spinner";
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { StatesService } from "src/app/shared/services/states.service";

@Component({
    selector: "app-settings",
    templateUrl: "./settings.component.html",
    styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements OnInit {
    @ViewChild("cardNumber") cardNumber: StripeCardComponent;
    @ViewChild("cardExpiry") cardExpiry: StripeCardComponent;
    @ViewChild("cardCvc") cardCvc: StripeCardComponent;
    practiceName: any;
    url!: any;
    user: any = [];
    userInfo: any = [];
    npinumber = "";
    npitype = "";
    productList: any = [];
    productsList!: Products[];
    order: any = [];
    orderProductId: any = [];
    balanceProductList: any = [];
    practice!: Practice;
    submitted = false;
    isValid = false;
    error = "";
    practiceForm: UntypedFormGroup;
    userType = [];
    npiType = [];
    selectedNpiType = "";
    selectedUserType = "";
    username = "";
    userid = "";
    npiname: String = "";
    imageObj: File;
    imageUrl: string;
    progress = 0;
    message = "";
    imgUrl: string;
    fileInfos?: Observable<any>;
    updateLogo!: UpdatePracticeLogo;
    logo = false;
    validImage = true;
    validImageSize = true;
    serverErr = false;
    serverError = "";
    currentUser: any;
    public password: string = "";
    public newPassword: string = "";
    public confirmPassword: string = "";
    public showPassword: boolean;
    public showPasswordOnPress: boolean;
    public showOldPasswordOnPress: boolean;
    public oldpassword;
    public newpassword;
    public confirmpassword;
    isSamePassword = false;
    private token: string = "";

    cardOptions: StripeCardElementOptions = {
        style: {
            base: {
                iconColor: "#666EE8",
                color: "#31325F",
                fontWeight: "300",
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSize: "18px",
                "::placeholder": {
                    color: "#6e707e",
                    fontSize: "14px",
                },
            },
        },
    };

    elementsOptions: StripeElementsOptions = {
        locale: "en",
    };

    stripeTest: UntypedFormGroup;
    cardDetails: any;
    states: any;
    constructor(
        private formBuilder: UntypedFormBuilder,
        private customerService: CustomerService,
        private productService: ProductsService,
        private cookieService: CookieService,
        private cdr: ChangeDetectorRef,
        private cartService: CartService,
        private npiTypeService: NpiTypeService,
        private practiceService: PracticeService,
        private router: Router,
        private imageUploadService: ImageUploadService,
        private authentication: AuthenticationService,
        private dataService: DataService,
        private toastService: ToastrService,
        private tosterService: ToastrService,
        private keyCloakService: KeycloakService,
        private stripeService: StripeService,
        private spinner: NgxSpinnerService,
        private ngxService: NgxUiLoaderService,
        private stateservice : StatesService,
        
    ) {
        this.practiceForm = this.formBuilder.group({
            npiType: ["", Validators.required],
            npiNumber: ["", Validators.required],
            practiceName: ["", Validators.required],
            facilityName: [""],
            userType: ["", Validators.required],
        });
        this.signupForm = this.formBuilder.group(
            {
                oldpassword: new FormControl(null, [Validators.required]),
                password: new FormControl(
                    null,
                    Validators.compose([
                        Validators.required,
                        Validators.minLength(8),
                        CustomValidators.patternValidator(
                            new RegExp("(?=.*[0-9])"),
                            {
                                requiresDigit: true,
                            }
                        ),
                        CustomValidators.patternValidator(
                            new RegExp("(?=.*[A-Z])"),
                            {
                                requiresUppercase: true,
                            }
                        ),
                        CustomValidators.patternValidator(
                            new RegExp("(?=.*[a-z])"),
                            {
                                requiresLowercase: true,
                            }
                        ),
                        CustomValidators.patternValidator(
                            new RegExp("(?=.*[$@^!%*?&])"),
                            {
                                requiresSpecialChars: true,
                            }
                        ),
                    ])
                ),
                confirmPassword: new FormControl(null, [
                    Validators.required,
                    Validators.minLength(8),
                ]),
            },
            {
                validator: CustomValidators.passwordMatchValidator,
            }
        );
    }
    signupForm = this.formBuilder.group(
        {
            oldpassword: new FormControl(null, [Validators.required]),
            password: new FormControl(
                null,
                Validators.compose([
                    Validators.required,
                    Validators.minLength(8),
                    CustomValidators.patternValidator(
                        new RegExp("(?=.*[0-9])"),
                        {
                            requiresDigit: true,
                        }
                    ),
                    CustomValidators.patternValidator(
                        new RegExp("(?=.*[A-Z])"),
                        {
                            requiresUppercase: true,
                        }
                    ),
                    CustomValidators.patternValidator(
                        new RegExp("(?=.*[a-z])"),
                        {
                            requiresLowercase: true,
                        }
                    ),
                    CustomValidators.patternValidator(
                        new RegExp("(?=.*[$@^!%*?&])"),
                        {
                            requiresSpecialChars: true,
                        }
                    ),
                ])
            ),
            confirmPassword: new FormControl(null, [
                Validators.required,
                Validators.minLength(8),
            ]),
        },
        {
            validator: CustomValidators.passwordMatchValidator,
        }
    );
    get s() {
        return this.signupForm.controls;
    }
    get passwordValid() {
        return this.signupForm.controls["password"].errors === null;
    }

    get requiredValid() {
        return !this.signupForm.controls["password"].hasError("required");
    }

    get minLengthValid() {
        return !this.signupForm.controls["password"].hasError("minlength");
    }

    get requiresDigitValid() {
        return !this.signupForm.controls["password"].hasError("requiresDigit");
    }

    get requiresUppercaseValid() {
        return !this.signupForm.controls["password"].hasError(
            "requiresUppercase"
        );
    }

    get requiresLowercaseValid() {
        return !this.signupForm.controls["password"].hasError(
            "requiresLowercase"
        );
    }

    get requiresSpecialCharsValid() {
        return !this.signupForm.controls["password"].hasError(
            "requiresSpecialChars"
        );
    }
    removeWhiteSpacesoldpassword(event) {
        this.oldpassword = event.target.value.replace(/\s/g, "");
    }
    removeWhiteSpacesnewpassword(event) {
        this.newpassword = event.target.value.replace(/\s/g, "");
    }
    removeWhiteSpacesconfirmpassword(event) {
        this.confirmpassword = event.target.value.replace(/\s/g, "");
    }
    get f() {
        return this.practiceForm.controls;
    }

    async ngOnInit() {
        this.productService.isLoading(true);
        const _token = JSON.parse(
            this.cookieService.get(APP_CONSTANTS.cookiekey) || "{}"
        );
        this.currentUser = _token.data.practiceData;
        this.practiceName = this.currentUser?.name
            .split(" ")
            .slice(0, 2)
            .map((n) => n[0].toUpperCase())
            .join("");
        console.log(this.currentUser);
        await this.getNPITypes();
        await this.GetUserTypes();
        this.getSubscription();
        await this.loadPageData();
        if (this.customerService.loggedInUser == null) {
            this.customerService.loggedInUser = [];
            const _token = JSON.parse(
                this.cookieService.get(APP_CONSTANTS.cookiekey) || "{}"
            );
            //console.log("cookie--------------" + JSON.stringify(_token));
            this.customerService.loggedInUser.push(_token.data);
            //console.log(JSON.stringify(this.customerService.loggedInUser));
            await this.getUserDetails();
        } else {
            if (this.customerService.loggedInUser[0].practiceData) {
                if (this.customerService.loggedInUser) {
                    this.npinumber =
                        this.customerService.loggedInUser[0].practiceData.npinumber;
                    this.npitype =
                        this.customerService.loggedInUser[0].practiceData.npitype[0].name;
                    this.selectedNpiType =
                        this.customerService.loggedInUser[0].practiceData.npitype[0]._id;
                    this.practiceForm.controls.npiNumber.setValue(
                        this.npinumber
                    );
                    this.practiceForm.controls.practiceName.setValue(
                        this.customerService.loggedInUser[0].practiceData.name
                    );
                }
            }
            await this.getUserDetails();

            if (
                this.customerService.loggedInUser[0].practiceData.logo ==
                undefined
            ) {
                this.logo = false;
                this.url = null;
            }
            if (
                this.customerService.loggedInUser[0].practiceData.logo == "" ||
                this.customerService.loggedInUser[0].practiceData.logo ==
                    undefined
            ) {
                // this.imgUrl = "../../../assets/img/practice-img.jpg";
                this.url = null;
            } else {
                this.imgUrl =
                    APP_CONSTANTS.imgurl +
                    this.customerService.loggedInUser[0].practiceData.logo;
                this.logo = true;
                this.url =
                    APP_CONSTANTS.imgurl +
                    this.customerService.loggedInUser[0].practiceData.logo;
            }
        }
         this.getStates();
        this.getCardDetails();
        this.getBillingAdress();

    }

    async getStates() {
        await this.stateservice.getAll().then((res) => {
           // console.log(JSON.stringify(res));
            this.states = res;
            
            // this.user.otp = this.otp;
        });
    }

    getCardDetails(): void {
        
        this.customerService.getPaymentMethods(this.userid).subscribe(
            (res) => {
                this.cardDetails = res;
                this.cardDetails.unshift(
                    this.cardDetails.splice(
                        this.cardDetails.findIndex(
                            (item) => item.default_payment_method === true
                        ),
                        1
                    )[0]
                );
                this.ngxService.stop();
                console.log(this.cardDetails);
                console.log(res);
            },
            (err) => {}
        );
    }
    async getUserDetails() {
        if (this.customerService.loggedInUser.length > 0) {
            await this.customerService
                .getUserById(this.customerService.loggedInUser[0].id)
                .then((res) => {
                    this.customerService.user = res;
                    this.customerService.user._id = res._id;
                    this.customerService.user.firstname = res.firstname;
                    this.customerService.user.lastname = res.lastname;
                    this.customerService.user.email = res.email;
                    this.customerService.user.password = res.password;
                    this.customerService.user.phone = res.phone;
                    this.customerService.user.usertype = res.usertype[0]._id;
                    this.customerService.user.createdby = res._id;
                    this.customerService.user.createdon = res.createdon;
                    this.customerService.user.modifiedby = res._id;
                    this.customerService.user.modifiedon = new Date();
                    this.username = res.firstname;
                    this.userid = res._id;
                    this.user.push(res);
                    this.userInfo.push(this.user[1]);
                    //console.log( "user details------------" + JSON.stringify(this.customerService.user)  );
                });
        }
    }
    async getNPITypes() {
        await this.npiTypeService.getNPIType().then((res) => {
            // console.log(res);
            this.npiType = res;
            this.selectedNpiType = undefined;
            // const admin = this.userType.filter((citem) => citem.name == 'Admin');
            //console.log('admin-----'+JSON.stringify(admin));
        });
    }
    userRole: any;
    async GetUserTypes() {
        await this.customerService.GetUserType().then((res) => {
            // console.log(res);
            this.userType = res;

            const admin = this.userType.filter(
                (citem) => citem.name == "Admin"
            );
            //console.log("admin-----" + JSON.stringify(admin));
            this.userRole = admin[0].name;
            console.log(this.userRole);
            this.selectedUserType = admin[0]._id;
            this.cdr.detectChanges();
        });
    }
    getUserType(event: any) {
        // console.log(JSON.stringify(event.target.value));
        this.selectedUserType = event.target.value;
        //console.log(this.selectedUserType);
    }
    getNpiType($event: any) {
        //  console.log(JSON.stringify($event.target.value));
        this.selectedNpiType = $event.target.value;
        this.npiname =
            $event.target.options[$event.target.options.selectedIndex].text;
        // console.log(this.selectedNpiType);
    }
    get getnpiType() {
        return this.practiceForm.get("npiType");
    }
    async onSubmit() {
        this.submitted = true;
        if (this.practiceForm.invalid) {
            return;
        } else {
            this.practice = {
                npitype: "",
                npinumber: "",
                name: "",
                // usertype: "",

                createdby: "",
                createdon: new Date(),
                modifiedby: "",
                modifiedon: new Date(),
            };
        }
        await this.practiceService
            .ValidateNPINumber(this.practiceForm.controls.npiNumber.value)
            .then((data: any) => {
                //console.log(JSON.stringify(data));
                if (data.result_count === 0) {
                    return;
                } else {
                }
            })
            .catch((error) => {
                console.log("NPI Validation" + JSON.stringify(error));
            });
        this.practice.npitype = this.selectedNpiType;
        this.practice.npinumber = this.practiceForm.controls.npiNumber.value;
        this.practice.name = this.practiceForm.controls.practiceName.value;
        // this.practice.usertype = this.selectedUserType;
        //  this.practice.facility = this.practiceForm.controls.facility.value;
        this.practice.createdby = this.userid;
        this.practice.createdon = new Date();
        this.practice.modifiedby = this.userid;
        this.practice.modifiedon = new Date();
        //console.log("valid");
        //  console.log(this.practice);
        this.customerService.npiInfo = {
            npinumber: "",
            npitype: "",
            practicename: "",
        };
        this.customerService.npiInfo.npinumber = this.practice.npinumber;
        let npi = this.getnpiType;
        this.customerService.npiInfo.npitype = this.npiname;
        this.customerService.npiInfo.practicename = this.practice.name;
        this.practiceService.AddPractice(this.practice).then((data) => {
            //  console.log('practice tell----------------'+JSON.stringify(data));
            this.customerService.user.practice = data._id;

            this.customerService
                .putUser(this.customerService.user)
                .then((data) => {
                    this.customerService.loggedInUser[0].practiceData.push(
                        data
                    );
                    localStorage.setItem(
                        "user",
                        JSON.stringify(this.customerService.loggedInUser[0])
                    );
                    localStorage.setItem("userData", JSON.stringify(this.user));
                    this.router.navigate(["/dashboard"]);
                });
        });
    }
    async onSelectFile(event) {
        this.serverErr = false;
        this.serverError = "";
        this.validImageSize = true;
        this.validImage = true;
        if (event.target.files && event.target.files[0]) {
            if (
                event.target.files[0].type === "image/jpeg" ||
                event.target.files[0].type === "image/png" ||
                event.target.files[0].type === "image/jpg"
            ) {
                if (event.target.files[0].size < 200 * 200) {
                    /* Checking height * width*/
                }
                if (event.target.files[0].size > 2097152) {
                    /* checking size here - 2MB */
                    this.validImageSize = false;
                    return;
                }
            } else {
                this.validImage = false;
                return;
            }
            var reader = new FileReader();

            reader.readAsDataURL(event.target.files[0]); // read file as data url

            reader.onload = (event) => {
                // called once readAsDataURL is completed
                this.url = event.target.result;
            };
            const FILE = (event.target as HTMLInputElement).files[0];
            this.imageObj = FILE;
            const imageForm = new FormData();
            imageForm.append("image", this.imageObj);
            // this.imageUploadService.imageUpload(imageForm).subscribe(res => {
            // this.imageUrl = res['image'];
            // });
            await this.imageUploadService.upload(this.imageObj).subscribe(
                (res: any) => {
                    //console.log(res);
                    this.updateLogo = {
                        id: "",
                        path: "",
                    };
                    this.updateLogo.id =
                        this.customerService.loggedInUser[0].practiceData._id;
                    this.updateLogo.path = res.file;

                    this.imageUploadService
                        .updatePracticeLogo(this.updateLogo)
                        .subscribe(async (data: any) => {
                            await this.getLoggedInUserData(
                                this.customerService.user.email
                            );
                            // console.log("---------logo----------"+JSON.stringify(data));
                            this.imgUrl =
                                APP_CONSTANTS.imgurl + this.updateLogo.path;
                            this.dataService.updateLogo(this.updateLogo.path);
                        });
                },
                (error: any) => {
                    this.serverErr = true;
                    this.serverError = error?.error?.message;
                    return;
                }
            );
        }
    }

    async getLoggedInUserData(email: string) {
        //console.log("getLoggedInUserData---------")
        await this.authentication
            .signedInUserData(email)
            .pipe(first())
            .subscribe({
                next: async (res: any) => {
                    // get return url from query parameters or default to home page
                    //  const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
                    // this.router.navigateByUrl(returnUrl);
                    if (!res.data) {
                        // this.statusCode = true;
                        return;
                    }
                    if (res) {
                        if (res.data.usertype.name !== "Admin") {
                            // this.noaccess = true;
                            return;
                        }
                        // this.auth.loggedInUser = res.data;
                        this.customerService.loggedInUser = [];
                        this.customerService.loggedInUser.push(res.data);
                        //console.log("practice data-------175"+JSON.stringify(this.customerService.loggedInUser[0]));
                        this.dataService.setRefresh(true);
                        //  localStorage.setItem("user",JSON.stringify(this.auth.loggedInUser[0]));
                        //  await this.checkPracticeData();
                    }
                },
            });
    }
    delete() {
        this.url = null;
        this.imgUrl = "";
        this.updateLogo = {
            id: "",
            path: "",
        };
        this.updateLogo.id =
            this.customerService.loggedInUser[0].practiceData._id;
        this.updateLogo.path = "";

        this.imageUploadService
            .updatePracticeLogo(this.updateLogo)
            .subscribe(async (data: any) => {
                await this.getLoggedInUserData(this.customerService.user.email);
                // console.log("---------logo----------"+JSON.stringify(data));
                this.dataService.updateLogo("false");
            });
    }

    BillingAdressInfo = [];
    stateName: any;
    getBillingAdress() {
        console.log(this.customerService.loggedInUser);
        this.customerService
            .getBillingDetails(this.customerService.loggedInUser[0].id)
            .subscribe((res: any) => {
                setTimeout(() => {
                this.productService.isLoading(false);
                }, 800);
                console.log(res);
                this.BillingAdressInfo = res;
              let index =  this.states.findIndex((state)=>{
                   return state._id === res[0].state
                })
                this.stateservice.getSingleState(this.states[index]._id).then((res) => {
                    console.log(res)
                    this.stateName = res.name;
                 });
            });
    }

    getRandomColor() {
        return "#C12228";
    }

    subscriptionDetails: any;
    productDetails = [];
    getSubscription() {
        this.customerService
            .getProductIds(this.customerService.loggedInUser[0].id)
            .subscribe((res: any) => {
                this.subscriptionDetails = res[0]?.items;
                //To get product details
                this.subscriptionDetails?.forEach((res) => {
                    this.customerService
                        .getProductDetails(res.product)
                        .subscribe((res: any) => {
                            this.productDetails = res;
                            if (res.name == "Credential Today") {
                                this.subscriptionDetails =
                                    this.subscriptionDetails.filter(
                                        (val) => val.product == res._id
                                    );
                            }
                        });
                });
            });
    }

    loadPageData() {
        if (this.customerService.loggedInUser == null) {
            // console.log("null-----76");
            this.customerService.loggedInUser = [];
            const _token = JSON.parse(
                this.cookieService.get(APP_CONSTANTS.cookiekey) || "{}"
            );
            this.customerService.loggedInUser.push(_token.data);
        }
        //console.log("----------82"+JSON.stringify(this.customerService.loggedInUser));
        this.user.push(this.customerService.loggedInUser[0]);
        //console.log(JSON.stringify(this.user[0].id));
        this.GetCustomerOrders();
        if (this.customerService.loggedInUser[0].practiceData) {
            if (this.customerService.loggedInUser) {
                this.npinumber =
                    this.customerService.loggedInUser[0].practiceData.npinumber;
                this.npitype =
                    this.customerService.loggedInUser[0].practiceData.npitype[0].name;
            }
        }
    }
    getProducts() {
        this.productService.getProductsByCategory().then((data) => {
            //console.log('--------------------available'+JSON.stringify(this.orderProductId));
            this.productsList = data;
            for (let i = 0; i < this.orderProductId.length; i++) {
                this.balanceProductList = this.productsList.filter(
                    (t) => t._id !== this.orderProductId[i].product
                );
                this.productsList = this.balanceProductList;
                this.balanceProductList = [];
            }
        });
    }
    GetCustomerOrders() {
        this.customerService
            .getOrderByCustomerId(this.user[0].id)
            .then((data) => {
                if (data.length == 0) {
                    this.toastService.error(
                        "Subscription data not found. Please contact Administrator",
                        "Subscription Error"
                    );
                    return;
                }
                if (data) {
                    this.order.push(data[0]);
                    console.log("--------------order" + data);
                }
                if (data[0].items.length > 0) {
                    for (let i = 0; i < data[0].items.length; i++) {
                        this.orderProductId.push(data[0].items[i]);
                        this.productService
                            .getProductsById(data[0].items[i].product)
                            .then((data) => {
                                this.productList.push(data);
                                this.getProducts();
                            });
                    }
                }
                console.log(this.productList);
            });
    }
    // Change Password
    changePassword() {
        this.submitted = true;
        if (this.signupForm.invalid) {
            return;
        } else {
            //console.log(this.signupForm.value.oldpassword +'-----'+this.signupForm.value.confirmPassword );
            if (
                this.signupForm.value.oldpassword ===
                this.signupForm.value.confirmPassword
            ) {
                this.isSamePassword = true;
            } else {
                this.isSamePassword = false;
                // this.loaderService.isLoading.next(true);
                this.customerService
                    .changePassword(
                        this.customerService.user.email,
                        this.signupForm.value.oldpassword,
                        this.signupForm.value.password
                    )
                    .subscribe({
                        next: (res) => {
                            this.updateKcid(
                                this.customerService.user.email,
                                this.signupForm.value.password
                            );
                        },
                        error: (error) => {
                            // this.loaderService.isLoading.next(false);
                            this.tosterService.error(
                                error.error.message.isOperational
                            );
                        },
                    });
            }
        }
    }
    @ViewChild("closebutton") closebutton;
    setasPrimary: boolean = false;
    cardError:boolean = false;
    setAsPrimaryCard(event) {
        if (event.target.checked) {
            this.setasPrimary = true;
        } else {
            this.setasPrimary = false;
        }
    }

    clearform() {
        this.cardNumber.element.clear();
        this.cardExpiry.element.clear();
        this.cardCvc.element.clear();
        this.setasPrimary = false;
        this.cardError =false;
    }

    isEnabled() {
        return false;
    }

    async addPayment() {
        const cardElement = this.cardNumber.element;
        // Create a Payment Method using the card element
        const result = await this.stripeService.createPaymentMethod({
            type: "card",
            card: cardElement,
        });
        result.subscribe((result) => {
            console.log("hit", result);
            if(result?.error?.type == 'validation_error'){
                this.cardError = true;                
            }
            if(result?.paymentMethod){
            this.cardError = false;                
            this.ngxService.start();
            this.customerService
                .savePaymentMethods(
                    { payment_method_id: result?.paymentMethod?.id },
                    this.userid
                )
                .subscribe(
                    (res) => {
                        if (this.setasPrimary) {
                            this.setDefaultCard(result.paymentMethod.id);
                        } else {
                            this.getCardDetails();
                        }
                        this.getCardDetails();
                        this.closebutton.nativeElement.click();
                        this.ngxService.stop();
                    },
                    (err) => {}
                ); 
            }
        });
    }

    // call this method to set deafult
    setDefaultCard(paymentMethodId) {
        this.customerService
            .setDefaultCard({ payment_method_id: paymentMethodId }, this.userid)
            .subscribe(
                (res) => {
                    
                    this.getCardDetails();
                    this.closebutton.nativeElement.click();
                },
                (err) => {}
            );
    }

    // call this method to set deafult
    setpopupDefaultCard(paymentMethodId) {
        // this.ngxService.start();
        
        this.ngxService.start();
        this.customerService
            .setDefaultCard({ payment_method_id: this.CardId }, this.userid)
            .subscribe(
                (res) => {
                    this.getCardDetails();
                },
                (err) => {}
            );
    }

    CardId: any;
    last4digits: any;
    branding:any;
    deleteModal(cardInfo) {
        console.log(cardInfo);
        this.CardId = cardInfo.id;
        this.last4digits = cardInfo.last4;
        this.branding = cardInfo.brand;
    }

    // call this method to set deafult
    deleteDefaultCard(paymentMethodId) {
        console.log(this.CardId);
        this.customerService
            .deleteDefaultCard(this.CardId, this.userid)
            .subscribe(
                (res) => {
                    console.log(res);
                    this.getCardDetails();
                    this.closebutton.nativeElement.click();
                },
                (err) => {}
            );
    }

    // update password in keyCLoak
    private async updateKcid(email: string, password: string) {
        this.authentication.signedInUserData(email).subscribe({
            next: (user) => {
                console.log(
                    "-----------------------------------" + JSON.stringify(user)
                );
                let kcid = user?.data?.kcid;
                this.customerService.GetAccessToken().then((token: any) => {
                    this.token = token.access_token;
                    var pwdData = {
                        type: "password",
                        temporary: false,
                        value: password,
                    };
                    this.customerService
                        .UpdateUserKCPassword(kcid, pwdData, this.token)
                        .then((res) => {
                            this.tosterService.success(
                                "Password Reset Successful"
                            );
                        })
                        .then(() => {
                            this.keyCloakService.logout();
                            // this.loaderService.isLoading.next(false);
                        })
                        .catch((error) => {
                            //this.loaderService.isLoading.next(false);
                            this.tosterService.error(JSON.stringify(error));
                        });
                });
            },
            error: (error) => {
                this.tosterService.error(JSON.stringify(error));
            },
        });
    }
}
