import { User } from "src/app/shared/models/user";


export const admin: User = {
  _id: '1',
  firstname: 'Sateesh',
  email: 'nzb329@163.com',
  //avatar: './assets/images/avatar.jpg',
};
export const author: User = {
  _id: '2',
  firstname: 'Jimmy-San',
  email: 'nzb329@163.com',
 // avatar: './assets/images/avatar.jpg',
};
export const tester: User = {
 _id: '3',
  firstname: 'Tester',
  email: 'nzb329@163.com',
 // avatar: './assets/images/avatar.jpg',
};
export const guest: User = {
    _id:'00',
  firstname: 'unknown',
  email: 'unknown',
 // avatar: './assets/images/avatar-default.jpg',
};
