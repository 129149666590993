import { Component, OnInit } from "@angular/core";
import {
    FormBuilder,
    FormGroup,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from "@angular/forms";
import { NgWizardService } from "ng-wizard";
import { Address } from "ngx-google-places-autocomplete/objects/address";
import { CustomerAddress } from "src/app/shared/models/address";
import { MainUser } from "src/app/shared/models/mainuser";
import { SubscriptionOrder } from "src/app/shared/models/order";
import { User } from "src/app/shared/models/user";
import { CartService } from "src/app/shared/services/cart/cart.service";
import { CustomerService } from "src/app/shared/services/customers/customers.service";
import { DataService } from "src/app/shared/services/data.service";
import { StatesService } from "src/app/shared/services/states.service";
import { APP_CONSTANTS } from "src/app/shared/utils/appconstants";

@Component({
    selector: "app-step-four",
    templateUrl: "./step-four.component.html",
    styleUrls: ["./step-four.component.scss"],
})
export class StepFourComponent implements OnInit {
    user!: User;
    address!: CustomerAddress;
    subscriptionOrder!: SubscriptionOrder;
    states = [];
    addressForm: FormGroup;
    submitted = false;
    grandTotal = 0;
    selectState = "";
    status: boolean = false;
    uspsAddress: any;
    timeout: any = null;
    constructor(
        private formBuilder: FormBuilder,
        private dataService: DataService,
        private cartService: CartService,
        private statesService: StatesService,
        private userService: CustomerService,
        private ngWizardService: NgWizardService
    ) {
        this.addressForm = this.formBuilder.group({
            companyName: ["", [Validators.required,
                Validators.minLength(3)]],
            companyPhone: [
                "",
                [
                    Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/),
                    Validators.required,
                    Validators.maxLength(14)
                ],
            ],
            address1: ["", [Validators.required]],
            companyState: ["", Validators.required],
            companyCity: ["", Validators.required],
            zipCode: [
                "",
                [
                    Validators.pattern(/^[0-9]{5}(?:-[0-9]{4})?$/),
                    Validators.required,
                ],
            ],
        });
    }
    get f() {
        return this.addressForm.controls;
    }
    async ngOnInit() {
        this.dataService.getClickEvent().subscribe((value:any) => {
            if(value == 'email'){
                this.addressForm.controls.companyPhone.setValue(
                    this.userService.user.phone.toString()
                );
                this.addressForm.controls.companyName.setValue(
                    this.userService.user.firstname.toString() +' '+ this.userService.user.lastname.toString()

                );
            }

        });
        await this.getStates();
    }
    async getStates() {
        await this.statesService.getAll().then((res) => {
           // console.log(JSON.stringify(res));
            this.states = res;
            this.selectState = undefined;
            // this.user.otp = this.otp;
        });
    }
    onStateChange(event: any) {
        this.selectState = event.target.value;
        this.CheckUSPSAddress();
       // console.log(this.selectState);
    }
    getUSPSAddress(zip:any){
        if (zip.length == 5) {
            let stateCode = this.states.filter(item => item._id==this.addressForm.controls.companyState.value);
           // console.log("code-----------"+JSON.stringify(stateCode));
            const xmlBody =
                '<AddressValidateRequest USERID="' +
                APP_CONSTANTS.USPS_ID +
                '"><Revision>' +
                APP_CONSTANTS.USPS_REV +
                "</Revision><Address><Address1>" +
                this.addressForm.controls.address1.value +
                "</Address1><Address2>" +
                this.addressForm.controls.address2.value +
                "</Address2><City>" +
                this.addressForm.controls.companyCity.value +
                "</City><State>" +
                stateCode[0]?.code +
                "</State><Zip5>" +
                this.addressForm.controls.zipCode.value +
                "</Zip5><Zip4></Zip4></Address></AddressValidateRequest>";

                this.userService.getUSPSVeifyAddress(xmlBody).subscribe((res) => {
                    //console.log("usps add---"+JSON.stringify(res));
                    this.uspsAddress = res;
                    // const parser = new xml2js.Parser({ strict: false, trim: true });
                    // parser.parseString(res?.AddressValidateResponse?.Address, (err, result) => {
                    //     console.log(result)
                    // });
                });
        }
        else
        {
            this.uspsAddress = null;
            this.status =false;
        }
    }
    onKeySearch(event: any) {
        clearTimeout(this.timeout);
        var $this = this;
        this.timeout = setTimeout(function () {
          if (event.keyCode != 13) {
            $this.executeListing(event.target.value);
          }
        }, 1000);
      }

      private executeListing(value: string) {
        this.CheckUSPSAddress();
      }

    CheckUSPSAddress(){
        this.status =false;
        let stateCode = this.states.filter(item => item._id==this.addressForm.controls.companyState?.value);
           // console.log("code-----------"+JSON.stringify(stateCode));
            const xmlBody =
                '<AddressValidateRequest USERID="' +
                APP_CONSTANTS.USPS_ID +
                '"><Revision>' +
                APP_CONSTANTS.USPS_REV +
                "</Revision><Address><Address1>" +
                this.addressForm.controls.address1?.value +
                "</Address1><Address2>" +
                this.addressForm.controls.address2?.value +
                "</Address2><City>" +
                this.addressForm.controls.companyCity?.value +
                "</City><State>" +
                stateCode[0]?.code +
                "</State><Zip5>" +
                this.addressForm.controls.zipCode?.value +
                "</Zip5><Zip4></Zip4></Address></AddressValidateRequest>";

                this.userService.getUSPSVeifyAddress(xmlBody).subscribe((res) => {
                    //console.log("usps add---"+JSON.stringify(res));
                    this.uspsAddress = res;
                    // const parser = new xml2js.Parser({ strict: false, trim: true });
                    // parser.parseString(res?.AddressValidateResponse?.Address, (err, result) => {
                    //     console.log(result)
                    // });
                });

    }

    selectAddress(){
        this.status = !this.status;
        if(this.status){
          //  console.log("do it here---")
            this.addressForm.controls.address1.setValue(this.uspsAddress?.Address1);
            this.addressForm.controls.address2.setValue(this.uspsAddress?.Address2);
            this.addressForm.controls.companyCity.setValue(this.uspsAddress?.City);
            let state = this.states.filter(item => item.code==this.uspsAddress.State);

            this.addressForm.controls.companyState.setValue(state[0]?._id);
            this.addressForm.controls.zipCode.setValue(this.uspsAddress?.Zip5);
        }
        else
        {
            this.addressForm.controls.address1.setValue('');
            this.addressForm.controls.address2.setValue('');
            this.addressForm.controls.companyCity.setValue('');
            this.addressForm.controls.companyState.setValue(undefined);
            this.addressForm.controls.zipCode.setValue('');
        }
    }
    onSubmitBilling() {
        //console.log('address form' +JSON.stringify(this.cartService.cartItemList));
        this.submitted = true;
        // if(this.uspsAddress?.Error){
        //     return;
        // }
        if (this.addressForm.invalid) {
            return;
        } else {
            this.address = {
                customerId: "",
                companyname: "",
                address1: "",
                phone: "",
                postalCode: "",
                city: "",
                state: "",
                country: "",
            };
            this.address.companyname =
                this.addressForm.controls.companyName.value;
            this.address.phone = this.addressForm.controls.companyPhone.value;
            this.address.address1 =
                this.addressForm.controls.address1.value;
           
            this.address.city = this.addressForm.controls.companyCity.value;
            this.address.state = this.selectState;
            this.address.country = "1";
            this.address.postalCode = this.addressForm.controls.zipCode.value;
            this.userService.customerAddress = this.address;
            // console.log('submut----------------------'+JSON.stringify(this.user));
            //console.log(this.address);
            this.ngWizardService.next();
            window.scroll(0,0);
        }
    }

    handleAddressChange(address:Address) {



        // this.addressForm = this.formBuilder.group({
        //     companyName: ["", [Validators.required,
        //         Validators.minLength(3)]],
        //     companyPhone: [
        //         "",
        //         [
        //             Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/),
        //             Validators.required,
        //         ],
        //     ],
        //     address1: ["", [Validators.required]],
        //     companyState: ["", Validators.required],
        //     companyCity: ["", Validators.required],
        //     zipCode: [
        //         "",
        //         [
        //             Validators.pattern(/^[0-9]{5}(?:-[0-9]{4})?$/),
        //             Validators.required,
        //         ],
        //     ],
        // });

        const addObj:any = {};

    address.address_components.forEach((contry)=>{
      addObj[contry.types[0]] = contry.short_name; 
    })
    let address1 = `${addObj.street_number || ''} ${addObj.route || ''}`
    let zipCode;
    if(addObj.postal_code_suffix) {
      zipCode = `${addObj.postal_code}-${addObj.postal_code_suffix}`
    }else {
      zipCode = addObj.postal_code;
    }
    this.addressForm.patchValue({companyCity:addObj.locality || '',companyState: addObj.administrative_area_level_1 || '',zipCode:zipCode || ''})
    setTimeout(() => {
      this.addressForm.get('address1').setValue(address1)
    }, 20);
    }
}
