import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { WebRoutingModule } from "./web-routing.module";
import { MatDialogModule } from "@angular/material/dialog";

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        WebRoutingModule,
        ReactiveFormsModule,
        MatDialogModule,
    ],
})
export class WebModule {}
