<form [formGroup]="addressForm" (ngSubmit)="onSubmitBilling()">
    <h3><b>Billing Address</b></h3>
    <div class="col-xl-12 mb-2">
        <label for="company" >Full name (First and Last name)<span>*</span></label>
        <input type="txt" formControlName="companyName"
        [ngClass]="{ 'is-invalid': submitted && f.companyName.errors }" class="form-control rounded"
          id="companyname" placeholder="Enter Full Name">
    </div>
    <div class="col-xl-12 m-auto">
        <div class="text_errors ml-15 m-t-5" *ngIf="(submitted && !addressForm.get('companyName').valid) || (addressForm.get('companyName').touched && !addressForm.get('companyName').valid)">
            At least 3 characters required and no special character allowed.
        </div>       
    </div>
    <div class="col-xl-12 mb-2 d-flex">
        <div>
            <label for="cphone" >Cell Phone Number<span>*</span></label>
        </div>
    </div>
    <div class="col-xl-12 mb-2 d-flex" style="gap:5px">
        <div class="col-xl-2 col-sm-2">
            <input formControlName="ccode" class="form-control usflag rounded" disabled value="+1">
        </div>
        <div class="col-xl-10 col-sm-10">
        <input formControlName="companyPhone"
        [ngClass]="{ 'is-invalid': submitted && f.companyPhone.errors }"  class="form-control rounded"
          id="companyphone" appPhoneMask maxlength="14"  placeholder="(xxx) xxx-xxxx">
        <div class="m-auto d-block">
            <div class="text_errors ml-15 m-top-5" *ngIf="(submitted && !addressForm.get('companyPhone').valid) || (addressForm.get('companyPhone').touched && !addressForm.get('companyPhone').valid)">
                Enter valid Cell Phone Number.
            </div>  
        </div>
    </div> 
    </div>
    <div class="col-xl-12 col-sm-12 mb-2">
        <label for="cphone">Country<span>*</span>  </label>
        <input class="form-control rounded"  formControlName="country" value="United States" aria-readonly="true" disabled="true">
    </div>
    <div class="col-xl-12 mb-2">
        <div class="col-xl-12">
            
                <label for="address1" >Address<span>*</span> </label>
                <input formControlName="address1" ngx-google-places-autocomplete #placesRef="ngx-places" [options]="{
                    componentRestrictions: { country: 'US' }
                    }" (onAddressChange)="handleAddressChange($event)"
                [ngClass]="{ 'is-invalid': submitted && f.address1.errors }"  class="form-control rounded"
                  id="address1" placeholder="Enter Address" (keyup)="onKeySearch($event)"> 
            </div>
            <div class="col-xl-12 m-auto">
                <div class="text_errors ml-15 m-top-5" *ngIf="(submitted && !addressForm.get('address1').valid) || (addressForm.get('address1').touched && !addressForm.get('address1').valid)">
                    Address required.
                </div>       
        </div>       
       
    </div>
    <div class="col-xl-12 mb-4">
        <div class="row">
            <div class="col-xl-4 col-sm-4">
                <label for="companyCity" >City<span>*</span> </label>
                <input disabled formControlName="companyCity" style="margin-right:10px;"
                [ngClass]="{ 'is-invalid': submitted && f.companyCity.errors }" class="form-control rounded"
                id="companycity" placeholder="Enter city" (keyup)="onKeySearch($event)">
                <div class="col-xl-12 m-auto">
                    <div class="text_errors ml-15 m-top-5" *ngIf="(submitted && !addressForm.get('companyCity').valid) || (addressForm.get('companyCity').touched && !addressForm.get('companyCity').valid)">
                        City required.
                    </div>       
                </div>
            </div>
            <div class="col-xl-4 col-sm-4">
                <label for="cphone">State<span>*</span></label>
                <select disabled class="form-control rounded minimal" id="companystate"  formControlName="companyState"
                [ngClass]="{ 'is-invalid': submitted && f.companyState.errors }"
                [(ngModel)]="selectState" (change)="onStateChange($event)">
                <option [value]="undefined" disabled selected>State</option>
                <option *ngFor="let c of states" [value]="c.code">{{ c.name }}</option>
                </select>
                <div class="col-xl-12 m-auto">
                    <div class="text_errors ml-15 m-top-5" *ngIf="(submitted && !addressForm.get('companyState').valid) || (addressForm.get('companyState').touched && !addressForm.get('companyState').valid)">
                        City required.
                    </div>       
                </div>
            </div>
            <div class="col-xl-4 col-sm-4">
                <label for="zipCode">Zip Code<span>*</span> </label>
                <input disabled formControlName="zipCode" maxlength="10" style="float:right" onlyNumber
                [ngClass]="{ 'is-invalid': submitted && f.zipCode.errors }" class="form-control rounded"
                id="zipcode" placeholder="Enter Zip" (keyup)="getUSPSAddress($event.target.value);">
                <div class="col-xl-12 m-auto">
                    <div class="text_errors ml-15 m-top-5" *ngIf="(submitted && !addressForm.get('zipCode').valid) || (addressForm.get('zipCode').touched && !addressForm.get('zipCode').valid)">
                        ZipCode required.
                    </div>       
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="col-xl-12">
        <div *ngIf="this.uspsAddress && !this.uspsAddress.Error" class="col-xl-6 add_class" (click)="selectAddress()"
            [ngClass]="status ? 'success' : 'danger'">
            <div class="col-xl-12 d-flex">
                <div  class="col-xl-10"><b>Suggested Address </b></div>
                <div class="col-xl-1">
                    <input type="checkbox" formControlName="chkUSPSAddress" [checked]="status">
                </div>
            </div>
            <hr>
            <div class="col-xl-12 d-flex">

            <div class="col-xl-6 name">
                <label *ngIf="this.uspsAddress?.Address1" class="name">Address 1: </label>
                <label  *ngIf="this.uspsAddress?.Address2" class="name">Address 2:</label>
                <label  *ngIf="this.uspsAddress?.City" class="name">City:</label>
                <label  *ngIf="this.uspsAddress?.State" class="name">State:</label>
                <label  *ngIf="this.uspsAddress?.Zip5" class="name"> Zip Code:</label>
            </div>
            <div class="col-xl-5">
                <label class="name1"> {{this.uspsAddress?.Address1}}</label>
                <label class="name1"> {{this.uspsAddress?.Address2}}</label>
                <label class="name1"> {{this.uspsAddress?.City}}</label>
                <label class="name1"> {{this.uspsAddress?.State}}</label>
                <label class="name1"> {{this.uspsAddress?.Zip5}}</label>
             </div>

            </div>
        </div>
        <div *ngIf="this.uspsAddress && this.uspsAddress?.Error" class="col-xl-6 add_class danger">
            <div  class="col-xl-10"><b>Suggested Address </b></div><hr>
            <label class="name"> {{this.uspsAddress?.Error?.Description}}</label>
        </div>
    </div> -->

    <div class="col-xl-12" style="margin-top: 40px;">
        <!-- <button class="btn btn-primary" style="margin:0 5px 0 5px">Back</button> -->
        <button class="btn btn-danger btn-ba" style="margin-left:26.5%">Proceed to Pay</button>
    </div>
</form>
