import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { lastValueFrom } from "rxjs";
import { environment } from "src/environments/environment";
@Injectable({
    providedIn: "root",
})
export class NpiTypeService {
    readonly baseURL = environment.baseURL;
    readonly userURL = environment.mainURL;
    readonly orderURL = environment.orderURL;
    constructor(private http: HttpClient) {}

    getNPIType(): Promise<any> {
        try {
            return lastValueFrom(this.http.get(this.userURL + "/npitype"));
        } catch (error: any) {
            throw new Error(error.message);
        }
    }
}
