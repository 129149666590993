
<section id="pricing" class="pricing-area" style="background-image: url('../../../../assets/img/reg-bg.jpg'); 
background-position: left top; background-repeat: no-repeat; padding-top: 20px; 
 ">

    <!--static HTML-->
    <div class="container">
        <div class="elementor-widget-container">
            <style>/*! elementor - v3.23.0 - 15-07-2024 */
            .elementor-widget-image{text-align:center}.elementor-widget-image a{display:inline-block}.elementor-widget-image a img[src$=".svg"]{width:48px}.elementor-widget-image img{vertical-align:middle;display:inline-block}</style>											<a href="https://uat.aaamb.com">
                            <img width="150px" src="../../../../assets/img/aaamb-logo-new.png" title="aaamb-logo-new (2)" alt="aaamb-logo-new (2)" loading="lazy">								</a>
                                                    </div>
        <div class="row justify-content-center" style="padding-bottom:2rem">
            <div class="section-title mt-5 mb-5">
                <h3><span>What</span> Does Your Team Need?</h3>
                <h5 *ngIf="!productsList || productsList.length == 0">Something went wrong, please contact
                    administrator.</h5>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-5" *ngFor="let product of this.productsList;">

                <div class="pricing-table" (click)="onCheckboxChange(product)"
                    (mouseover)="prodImage.src = product.bannerover" (mouseout)="prodImage.src=product.bannerout">
                    <!-- [ngClass]="isChecked ? 'table-hover' : 'table-normal'" -->
                    <div class="pricing-header">
                        <h5>{{product.name}}</h5>
                    </div>
                    <div class="prdct-con"><img #prodImage id="{{product.desc}}" src="{{product.bannerout}}"
                            width="160"></div>
                    <div class="row ms-3">
                        <ul class="pricing-features float_right">
                            <li><i class="icofont-circled-right"></i>Feedback Categorization</li>
                            <li><i class="icofont-circled-right"></i>Features Prioritization</li>
                            <li><i class="icofont-circled-right"></i>Real-Time Collaboration</li>
                            <li><i class="icofont-circled-right"></i>Feedback Loop Notifications</li>
                            <li><i class="icofont-circled-right"></i>Essential Dev Tools Integrations</li>
                        </ul>
                    </div>
                    <div class="price mt-0">
                        $ {{product.price}}<span>/month</span>
                    </div>
                    <!-- <input type="checkbox" id="{{product._id}}" value="{{product}}" (change)="onCheckboxChange($event,product)"  class="slct-chk"><i class="icofont-ui-check"></i> -->


                    <!-- <a  class="slct-chk"  (click)="onCheckboxChange(product)"><i class="icofont-ui-check"></i></a> -->
                    <!-- Checked checkbox -->
                    <div class="round slct-chk">
                        <input type="checkbox"
                            [checked]="(productsChecked && productsChecked[product._id]) ? productsChecked[product._id] : false"
                            id="checkbox3" />
                        <label for="checkbox3"></label>
                    </div>


                </div>
            </div>
            <!-- <div class="col-lg-3 col-md-6 offset-lg-0 offset-md-3">
            <div class="pricing-table">
                <div class="pricing-header">
                  <h5>Credential Today</h5>
                </div>
                <div class="prdct-con"><img src="assets/img/aaamb-logo-new.png"></div>
                <ul class="pricing-features">
                    <li><i class="icofont-circled-right"></i>Feedback Categorization</li>
                    <li><i class="icofont-circled-right"></i>Features Prioritization</li>
                    <li><i class="icofont-circled-right"></i>Real-Time Collaboration</li>
                    <li><i class="icofont-circled-right"></i>Feedback Loop Notifications</li>
                    <li><i class="icofont-circled-right"></i>Essential Dev Tools Integrations</li>
                </ul>
                <div class="price">
                    $25<span>/month</span>
                </div>
                <a href="#" class="slct-chk"><i class="icofont-ui-check"></i></a>
            </div>
        </div> -->
            <!-- <div class="col-lg-3 col-md-6 offset-lg-0 offset-md-3">
            <div class="pricing-table">
                <div class="pricing-header">
                   <h5> Patient Scheduling</h5>
                </div>
                <div class="prdct-con"><img src="assets/img/aaamb-logo-new.png"></div>
                <ul class="pricing-features">
                    <li><i class="icofont-circled-right"></i>Feedback Categorization</li>
                    <li><i class="icofont-circled-right"></i>Feedback Loop Notifications</li>
                    <li><i class="icofont-circled-right"></i>Essential Dev Tools Integrations</li>
                </ul>
                <div class="price">
                    $25<span>/month</span>
                </div>
                <a href="#" class="slct-chk"><i class="icofont-ui-check"></i></a>
            </div>
        </div>
        <div class="col-lg-3 col-md-6 offset-lg-0 offset-md-3">
            <div class="pricing-table">
                <div class="pricing-header">
                   <h5>Benefit Verification</h5>
                </div>
                <div class="prdct-con"><img src="assets/img/aaamb-logo-new.png"></div>
                <ul class="pricing-features">
                    <li><i class="icofont-circled-right"></i>Feedback Categorization</li>
                    <li><i class="icofont-circled-right"></i>Feedback Loop Notifications</li>
                    <li><i class="icofont-circled-right"></i>Essential Dev Tools Integrations</li>
                </ul>
                <div class="price">
                    $25<span>/month</span>
                </div>
                <a href="#" class="slct-chk"><i class="icofont-ui-check"></i></a>
            </div>
        </div> -->
        </div>
        <div class="row text-center">
            <div class="col-lg-6 m-auto pt-3 pb-5 m-top-35 mb-50">
                <h5 *ngIf="isItemSelected" class="aambred_color font_18">{{(this.itemsCount && this.itemsCount === 1) ?
                    'Selected Product' : 'Selected Products'}}</h5>
                <h5 *ngIf="isItemSelected" class="aambgreen_color font_18 m-top-15">{{this.selectedCartItems}}</h5>
                <h5 *ngIf="itemsselected" class=" aambgreen_color font_18  m-top-15">{{this.selectCartItems}}</h5>
                <button *ngIf="!productsList || productsList.length !== 0" class="btn btn-danger custom-large-btn"
                    (click)="processCart()">Subscribe Now</button>
            </div>
        </div>
    </div>
    <!--static HTML-->
    <section class="row no-margin position-footer">
        <div class="col-md-6 col-sm-12 col-lg-6 ps-4">
            <p class="copy_rights_styles footer_links">Copyright ©2024 AAAMB LLC. All rights reserved</p>
        </div>
        <div class="col-md-6 col-sm-12 col-lg-6 pe-5">
            <div class="m-r-3p text-end">
            <small><a id="lnkTermsOfService" href="/terms-service" target="_blank" class="footer_links font-12">Terms of Services</a></small>
            <small><a id="lnkPrivacyPolicy" href="/privacy-policy" target="_blank" class="footer_links font-12">Privacy Policy</a></small>
            </div>
        </div>
    </section>

</section>
