import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Token } from './interface';

import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { of } from 'rxjs';
import { LocalService } from 'src/app/shared/services/local.service';
import { User } from 'src/app/shared/models/user';
import { Menu } from '../bootstrap/menu.service';
@Injectable({
  providedIn: 'root',
})
export class LoginService {
  readonly baseURL = environment.mainURL;
  resultData: any;
  constructor(protected http: HttpClient, private localService: LocalService) {}
  token: Token = {
    access_token: '',
    token_type: '',
    expires_in: 0,
    exp: 0,
    refresh_token: '',
  };

  // gets the token from aaamb authentication service which is available in back-end rest API's and stores in the coockie
  login(username: string, password: string, grantType: any) {
    try {
        const loginUser = {
            email: username,
            password: password,
            applicationName: 'MAIN'
           }
        return this.http.post(this.baseURL + '/user/login' , loginUser).pipe(
          map((data:any) => {
            console.log(JSON.stringify(data.data.token));
            this.token.token_type = 'bearer';
            this.token.access_token = data.data.token;
            this.token.exp = 1200;
            this.token.expires_in = 1200;
            //this.token = data as Token;
            return this.token;
          })
        );
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  // gets the refresh token from UDS authentication service when the current token is expired and stores in the local storage
  refresh(paramsl: Record<string, any>) {
    try {
      const params = {token: paramsl.refresh_token} ;
      return this.http.post(this.baseURL + '/authentication/refreshtoken', params).pipe(
        map(data => {
          this.token = data as Token;
          return this.token;
        }),
        catchError(error => {
          return of(this.token);
        })
      );
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
  // to get logout from UDS keycloak and redirects the current user to login  page by deleting all local storage data.
  logout() {
    localStorage.clear();
    this.localService.clearData();
    const url = environment.mainURL+'/user/logout';
    return this.http.get<any>(url);
  }
  //gets the current logged in user details
  me() {
    return this.http.get<User>(this.baseURL + '/user');
  }
  //gets the menu for current role
  menu() {
    return this.http.get<{ menu: Menu[] }>('/me/menu').pipe(map(res => res.menu));
  }
}
