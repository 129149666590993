import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { first } from "rxjs";
import { Practice } from "src/app/shared/models/practice";
import { AuthenticationService } from "src/app/shared/services/authentication.service";
import { CustomerService } from "src/app/shared/services/customers/customers.service";
import { NpiTypeService } from "src/app/shared/services/main/npitype.service";
import { PracticeService } from "src/app/shared/services/practice.service";
import { APP_CONSTANTS } from "src/app/shared/utils/appconstants";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-tellyourself",
    templateUrl: "./tellyourself.component.html",
    styleUrls: ["./tellyourself.component.scss"],
})
export class TellyourselfComponent implements OnInit {
    public siteURL = environment.siteURL;
    practice!: Practice;
    submitted = false;
    isValid = false;
    error = "";
    practiceForm: UntypedFormGroup;
    userType = [];
    npiType = [];
    selectedNpiType = "";
    selectedUserType = "";
    username = "";
    userid = "";
    user = [];
    npiname: String = "";
    validNPI = false;
    validNPILength = false;
    isDisplayed = false;
    constructor(
        private formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private customerService: CustomerService,
        private npiTypeService: NpiTypeService,
        private practiceService: PracticeService,
        private cookieService: CookieService,
        private cdr: ChangeDetectorRef,
        private authentication: AuthenticationService
    ) {
        this.practiceForm = this.formBuilder.group({
            npiType: ["", Validators.required],
            npiNumber: ["", [
                Validators.required,
                Validators.minLength(10),
            ],],
            practiceName: ["", Validators.required],
            facilityName: [""],
            userType: ["", Validators.required],
        });
    }
    get f() {
        return this.practiceForm.controls;
    }
    async ngOnInit() {
        if (this.customerService.loggedInUser == null) {
            this.customerService.loggedInUser = [];
            const _token = JSON.parse(
                this.cookieService.get(APP_CONSTANTS.cookiekey) || "{}"
            );
            this.customerService.loggedInUser.push(_token.data);
            await this.getUserDetails();
        } else {
            await this.getUserDetails();
        }
        await this.getNPITypes();
        await this.GetUserTypes();
    }
    async getUserDetails() {
        if (this.customerService.loggedInUser.length > 0) {
            await this.customerService
                .getUserById(this.customerService.loggedInUser[0]?.id)
                .then((res) => {
                    this.customerService.user = res;
                    this.customerService.user._id = res._id;
                    this.customerService.user.firstname = res.firstname;
                    this.customerService.user.lastname = res.lastname;
                    this.customerService.user.email = res.email;
                    this.customerService.user.password = res.password;
                    this.customerService.user.phone = res.phone;
                    this.customerService.user.usertype = res.usertype[0]._id;
                    this.customerService.user.createdby = res._id;
                    this.customerService.user.createdon = res.createdon;
                    this.customerService.user.modifiedby = res._id;
                    this.customerService.user.modifiedon = new Date();
                    this.username = `${res.firstname} ${res.lastname}` ;
                    this.userid = res._id;
                    this.user.push(res);
                });
        }
    }
    async getNPITypes() {
        await this.npiTypeService.getNPIType().then((res) => {
            // console.log(res);
            this.npiType = res;
            this.selectedNpiType = undefined;
            // const admin = this.userType.filter((citem) => citem.name == 'Admin');
            //console.log('admin-----'+JSON.stringify(admin));
        });
    }
    async GetUserTypes() {
        await this.customerService.GetUserType().then((res) => {
            // console.log(res);
            this.userType = res;

            const admin = this.userType.filter(
                (citem) => citem.name == "Admin"
            );
            this.selectedUserType = admin[0]._id;
            this.cdr.detectChanges();
        });
    }
    getUserType(event: any) {
        // console.log(JSON.stringify(event.target.value));
        this.selectedUserType = event.target.value;
        //console.log(this.selectedUserType);
    }
    getNpiType($event: any) {
        //  console.log(JSON.stringify($event.target.value));
        this.selectedNpiType = $event.target.value;
        this.npiname =
            $event.target.options[$event.target.options.selectedIndex].text;
        // console.log(this.selectedNpiType);
    }
    get getnpiType() {
        return this.practiceForm.get("npiType");
    }
    async checkNPI(npiNumber: any){
        if(npiNumber.length<10){
            this.validNPI = false;
            this.validNPILength = true;
            this.selectedNpiType = undefined;
            this.practiceForm.controls.practiceName.setValue('');
        }
        if(npiNumber.length==10){
            this.isDisplayed = true;
            this.validNPILength = false;
            await this.practiceService
            .ValidateNPINumber(this.practiceForm.controls.npiNumber.value)
            .then((data: any) => {
               // console.log("NPI service--------"+JSON.stringify(data));
                 if (!data || data.length == 0) {
                    this.validNPI = true;
                    this.isDisplayed = false;
                    this.selectedNpiType = undefined;
                    return;
                }
                else{
               
                    if(data[0].enumeration_type =='NPI-1')
                    {
                         const selectedNPI = this.npiType.filter( (citem) => citem.name == "Individual");
                        this.selectedNpiType = selectedNPI[0]._id;
                        let practiceName = `${data[0]?.basic?.first_name} ${data[0].basic.last_name}`
                        this.practiceForm.controls.practiceName.setValue(practiceName);
                        this.practiceForm.get('practiceName').disable();
                    }
                    if(data[0].enumeration_type =='NPI-2')
                    {
                        this.practiceForm.controls.practiceName.setValue(data[0]?.basic?.organization_name);
                        this.practiceForm.get('practiceName').disable();
                        const selectedNPI = this.npiType.filter( (citem) => citem.name == "Group");

                        this.selectedNpiType = selectedNPI[0]._id;
                    }

                    this.isDisplayed = false;
                }
            });
        }

    }
    async onSubmit() {
        if(this.validNPI == true || this.validNPILength == true){
            return;
        }
        this.submitted = true;
        this.validNPI = false;
        if (this.practiceForm.invalid) {
            return;
        } else {
            this.practice = {
                npitype: "",
                npinumber: "",
                name: "",
                // usertype: "",

                createdby: "",
                createdon: new Date(),
                modifiedby: "",
                modifiedon: new Date(),
            };
        }
        // await this.practiceService
        //     .ValidateNPINumber(this.practiceForm.controls.npiNumber.value)
        //     .then((data: any) => {
        //          console.log("NPI service--------"+JSON.stringify(data));
        //          if (!data || data.length == 0) {
        //             this.validNPI = true;
        //             return;
        //         } else {
                    this.practice.npitype = this.selectedNpiType;
                    this.practice.npinumber =
                        this.practiceForm.controls.npiNumber.value;
                    this.practice.name =
                        this.practiceForm.controls.practiceName.value;
                    // this.practice.usertype = this.selectedUserType;
                    //  this.practice.facility = this.practiceForm.controls.facility.value;
                    this.practice.createdby = this.userid;
                    this.practice.createdon = new Date();
                    this.practice.modifiedby = this.userid;
                    this.practice.modifiedon = new Date();
                    //console.log("valid");
                    //  console.log(this.practice);
                    this.customerService.npiInfo = {
                        npinumber: "",
                        npitype: "",
                        practicename: "",
                    };
                    this.customerService.npiInfo.npinumber =
                        this.practice.npinumber;
                    let npi = this.getnpiType;
                    this.customerService.npiInfo.npitype = this.npiname;
                    this.customerService.npiInfo.practicename =
                        this.practice.name;
                    this.practiceService
                        .AddPractice(this.practice)
                        .then((data) => {
                            //  console.log('practice tell----------------'+JSON.stringify(data));
                            this.customerService.user.practice = data._id;

                            this.customerService
                                .putUser(this.customerService.user)
                                .then((data) => {
                                    this.getLoggedInUserData(
                                        this.customerService.user.email
                                    );
                                    // this.customerService.loggedInUser[0].practiceData.push(
                                    //     data
                                    // );
                                    // localStorage.setItem("user",JSON.stringify(this.customerService.loggedInUser[0]));
                                    // localStorage.setItem("userData",JSON.stringify(this.user));
                                });
                        });

            //     }
            // })
            // .catch((error) => {
            //     console.log("NPI Validation" + JSON.stringify(error));
            // });



    }
    async getLoggedInUserData(email: string) {
        await this.authentication
            .signedInUserData(email)
            .pipe(first())
            .subscribe({
                next: async (res: any) => {
                    if (!res.data) {
                        return;
                    }
                    if (res) {
                        // this.auth.loggedInUser = res.data;
                        this.customerService.loggedInUser = [];
                        this.customerService.loggedInUser.push(res.data);
                        this.router.navigate(["/login"]);
                    }
                },
            });
    }
}
