<section id="contact" class="contact-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2><span> Ready </span>to get started?
                AAAMB is here to help.</h2>
        </div>
        <div class="row fontsize">
            <div class="col-lg-4 col-md-12">
                <div class="contact-info">
                    <div>
                        <div class="mb-5">
                            <h3>Headquarters</h3>
                        </div>
                        <div class="mb-3">
                            <strong>
                                AAA Medical Billing Services
                            </strong>
                            <div>&nbsp;</div>
                            <div> 198-08 33rd Avenue.</div>
                            <div>
                                <p class="whitecolor">
                                    Flushing , NY 11358, USA <br>
                                    1-844-44(AAAMB)
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="contact-form">
                    <form id="contactForm" [formGroup]="contactUsForm" (ngSubmit)="onSubmit()">
                        <h3 class="mb-4 green">SEND US A MESSAGE</h3>
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="text" formControlName="firstName" class="form-control"
                                        placeholder="First Name*">
                                </div>
                                <div class="col-xl-12 m-auto" *ngIf="isSubmit && !contactUsForm.get('firstName').valid">
                                    <div class="text_errors text-capitalize ml-15 m-t-10">
                                        First Name Required.
                                    </div>
                                </div>
                            </div>


                            <div class="col-lg-6 col-md-6 mb-3">
                                <div class="form-group mb-3">
                                    <input type="text" class="form-control" formControlName="lastName"
                                        placeholder="Last Name*">
                                </div>
                                <div class="col-xl-12 m-auto" *ngIf="isSubmit && !contactUsForm.get('lastName').valid ">
                                    <div class="text_errors text-capitalize ml-15 m-t-10">
                                        Last Name Required.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 mb-3">
                                <div class="form-group mb-3">
                                    <input type="email" formControlName="email" class="form-control"
                                        placeholder="Email*">
                                </div>
                                <div class="col-xl-12 m-auto" *ngIf="isSubmit && !contactUsForm.get('email').valid ">
                                    <div class="text_errors text-capitalize ml-15 m-t-10">
                                        Email Name Required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 mb-3">
                                <div class="form-group mb-3">
                                    <input type="text" formControlName="phoneNumber" class="form-control"
                                        placeholder="Phone Number" (keypress)="numberOnly($event)" maxlength="14"
                                        appPhoneMask>

                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 mb-3">
                                <div class="form-group mb-3">
                                    <input type="text" formControlName="practiceName" class="form-control"
                                        placeholder="Practice Name">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-sm-3 mb-3 fontsize-16">
                                    <div>Purpose of Enquiry : </div>
                                </div>
                                <div class="col-lg-8 col-md-8 col-sm-9 mb-3 fontsize-16 ">

                                    <input type="checkbox" formControlName="services" value="services"
                                        class="me-1 form-check-input" name="Services">
                                    <label class="me-3 form-check-label" for="Services">Services</label>


                                    <input type="checkbox" formControlName="products" value="products"
                                        class="me-1 form-check-input" name="products">
                                    <label class="me-3 form-check-label" for="products">Products</label>
                                    <input type="checkbox" formControlName="others" value="others"
                                        class="me-1 form-check-input" name="others">
                                    <label class="me-3 form-check-label" for="others">Others</label>
                                </div>

                            </div>
                            <div class="col-lg-12 col-md-12 mb-3">
                                <div class="form-group mb-3">
                                    <textarea placeholder="Briefly describe your requirements" class="form-control"
                                        cols="30" rows="5"></textarea>
                                </div>
                            </div>
                            <!-- <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3 fontsize-16">
                                    <input type="checkbox">
                                    Subscribe to our newsletter, blogs and perspectives
                                </div>
                            </div> -->
                            <div class="col-lg-12 col-md-12 mb-3 ">
                                <button type="submit" class="btn btn-primary contactBtn">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

