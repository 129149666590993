import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { NgWizardService } from "ng-wizard";
import { Subscription, map, take, timer } from "rxjs";
import { User } from "src/app/shared/models/user";
import { CartService } from "src/app/shared/services/cart/cart.service";
import { CustomerService } from "src/app/shared/services/customers/customers.service";
import { DataService } from "src/app/shared/services/data.service";
import { UserService } from "src/app/shared/services/user.service";

@Component({
    selector: "app-step-two",
    templateUrl: "./step-two.component.html",
    styleUrls: ["./step-two.component.scss"],
})
export class StepTwoComponent implements OnInit, AfterViewInit {
    timerSub: Subscription;
    value: number;
    message:string;
    display: any;
    resendOtp: boolean = false;
    displayTimer: boolean = false;
    user!: User;
    otpForm: UntypedFormGroup;
    submitted = false;
    invalidotp = false;
    otp = "";
    showPassword = false;
    userEmail = '';
    timerTime = 300;
    constructor(private dataService:DataService,
        private formBuilder: UntypedFormBuilder,
        private cartService: CartService,
        private userService: CustomerService,
        private ngWizardService: NgWizardService,
        private cdr: ChangeDetectorRef) {
        this.otpForm = this.formBuilder.group({
            otp: ["", Validators.required],
        });
    }
    ngAfterViewInit(): void {
      //  this.start(1);
    }

    public startTimer(){
       // console.log("public start timer-----")
       // this.start(1);
       this.stopTimer();
       this.startTimerS(this.timerTime);
    }
    get g() {
        return this.otpForm.controls;
    }
    ngOnInit(): void {
        this.dataService.getClickEvent().subscribe((value:any)=>{
            if(value == 'otp'){
                this.display = '';
                this.userEmail = this.userService.user.email.toString();
                this.stopTimer();
                this.startTimerS(this.timerTime);
            }
        });
    }
    otpLength = 0;
    onOTPChangeEvent(event: any){
        console.log(event.target.value)
        if(event.target.value.length > 0){
            this.otpLength = event.target.value.length;
        }

    }
    resendOtpClick(){
        this.user = this.userService.user;
        this.userService.GetOtp(this.user.firstname + " " + this.user.lastname, this.user.email).then((res) => {
          //  console.log(JSON.stringify(res));
            this.cartService.otp = res.data;
            // this.user.otp = this.otp;
            this.stopTimer();
            this.startTimerS(this.timerTime);
        });
    }

    onSubmit() {
        this.submitted = true;
        this.invalidotp = false;
        if (this.otpForm.invalid) {
            return;
        } else {
           // console.log(this.otpForm.controls.otp.value + this.otp);
            if ( this.cartService.otp  !== this.otpForm.controls.otp.value) {
                this.invalidotp = true;
                return;
            }
            else
            {
                // this.userService.userOnboardingInfo(this.user).then((res) => {

                // })
                this.ngWizardService.next();
               // window.scroll(0,0);
            }
        }
    }
    toggleShowPassword() {
        this.showPassword = !this.showPassword;
    }
    start(minute) {
        this.invalidotp = false;
        this.displayTimer = true;
        this.resendOtp = false;
        this.otpForm.controls.otp.setValue('');
        this.cdr.detectChanges();
        // let minute = 1;
        let seconds = minute * 60;
        let textSec: any = '0';
        let statSec = 60;

        const prefix = minute < 10 ? '0' : '';

        const timer = setInterval(() => {
          seconds--;
          if (statSec != 0) statSec--;
          else statSec = 59;

          // if (statSec < 10) textSec = "0" + statSec;
          // textSec = statSec;

          if (statSec < 10) {
           // console.log('inside', statSec);
            textSec = '0' + statSec;
          } else {
           // console.log('else', statSec);
            textSec = statSec;
          }

          // this.display = prefix + Math.floor(seconds / 60) + ":" + textSec;
          this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

          if (seconds == 0) {
           // console.log('finished');
            this.otp = '';
            this.cartService.otp = '';
            clearInterval(timer);
            this.resendOtp = true;
            this.displayTimer = false;
          }
        }, 1000);
      }
      startTimerS(input: any) {
        this.resendOtp = false;
        this.displayTimer = true;
        const startValue = 1 * input;

        this.timerSub = timer(0, 1000).pipe(
          take(startValue + 1),
          map(value => startValue - value)
        ).subscribe(
          value => {
            this.value = value;
            if(!value){
                this.timerSub = null;
                this.otp = '';
                this.cartService.otp = '';
                this.resendOtp = true;
                this.displayTimer = false;
            }
        });
    }
    stopTimer() {
        if (this.timerSub) {
          this.timerSub.unsubscribe();
          this.displayTimer = false;
          this.otp = '';
          this.cartService.otp = '';
        }
    }
}
