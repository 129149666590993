import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BlogOneComponent } from '../components/pages/blog-one/blog-one.component';
import { BlogDetailsComponent } from '../components/pages/blog-details/blog-details.component';
import { ErrorComponent } from '../components/pages/error/error.component';
import { DashboardComponent } from '../components/pages/dashboard/dashboard.component';
import { PortfolioComponent } from '../components/layouts/portfolio/portfolio.component';
import { PricingComponent } from '../components/layouts/pricing/pricing.component';
import { NewsComponent } from "../components/layouts/news/news.component";
import { PartnerComponent } from "../components/layouts/partner/partner.component";
import { WebLayoutComponent } from '../theme/web-layout/web-layout.component';
import { HomeOneComponent } from '../components/pages/home-one/home-one.component';
import { ServicesComponent } from '../components/layouts/services/services.component';
import { PrivacyPolicyComponent } from '../components/layouts/privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from '../components/layouts/terms-of-service/terms-of-service.component';
import { ContactComponent } from '../components/layouts/contact/contact.component';


const routes: Routes = [{
  path: '',
  component: WebLayoutComponent,
  children: [
    { path: '', component: HomeOneComponent },
    { path: 'products', component: PortfolioComponent },
    { path: 'pricing', component: PricingComponent },
    { path: 'testimonials', component: NewsComponent },
    { path: 'partners', component: PartnerComponent },
    { path: 'contact-us', component: ContactComponent },
    { path: 'services', component: ServicesComponent },
    { path: 'blog-details', component: BlogDetailsComponent },
    // {path: 'dashboard', component: DashboardComponent},

    { path: '**', component: ErrorComponent } // This line will remain down from the whole pages component list
  ],
},
{ path: 'products', component: PortfolioComponent },
{ path: 'privacy-policy', component: PrivacyPolicyComponent },
{ path: 'terms-service', component: TermsOfServiceComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WebRoutingModule { }
