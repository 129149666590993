import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { environment } from '../environments/environment';

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { PreloaderComponent } from "./components/layouts/preloader/preloader.component";
import { FooterComponent } from "./components/layouts/footer/footer.component";
import { NavbarComponent } from "./components/layouts/navbar/navbar.component";
import { StoryComponent } from "./components/layouts/story/story.component";
import { HomeOneComponent } from "./components/pages/home-one/home-one.component";
import { TeamComponent } from "./components/layouts/team/team.component";
import { NewsComponent } from "./components/layouts/news/news.component";
import { CtaComponent } from "./components/layouts/cta/cta.component";
import { CallToActionComponent } from "./components/layouts/call-to-action/call-to-action.component";
import { SubscribeComponent } from "./components/layouts/subscribe/subscribe.component";
import { ContactComponent } from "./components/layouts/contact/contact.component";
import { PartnerComponent } from "./components/layouts/partner/partner.component";
import { AboutComponent } from "./components/layouts/about/about.component";
import { ServicesComponent } from "./components/layouts/services/services.component";
import { PortfolioComponent } from "./components/layouts/portfolio/portfolio.component";
import { SkillsComponent } from "./components/layouts/skills/skills.component";
import { FeaturesComponent } from "./components/layouts/features/features.component";
import { WhyWeDifferentComponent } from "./components/layouts/why-we-different/why-we-different.component";
import { FunfactsComponent } from "./components/layouts/funfacts/funfacts.component";
import { PricingComponent } from "./components/layouts/pricing/pricing.component";
import { FaqComponent } from "./components/layouts/faq/faq.component";
import { BlogComponent } from "./components/layouts/blog/blog.component";
import { CtaTwoComponent } from "./components/layouts/cta-two/cta-two.component";
import { WhoWeAreComponent } from "./components/layouts/who-we-are/who-we-are.component";
import { FunfactsTwoComponent } from "./components/layouts/funfacts-two/funfacts-two.component";
import { BlogOneComponent } from "./components/pages/blog-one/blog-one.component";
import { BlogDetailsComponent } from "./components/pages/blog-details/blog-details.component";
import { ErrorComponent } from "./components/pages/error/error.component";
import { DemoSidebarComponent } from "./components/layouts/demo-sidebar/demo-sidebar.component";
import { SignupComponent } from "./components/pages/auth/signup/signup.component";
import { ReactiveFormsModule } from "@angular/forms";
import { MatStepperModule } from "@angular/material/stepper";
import { MatInputModule } from "@angular/material/input";

import { MatButtonModule } from "@angular/material/button";

import { MatListModule } from "@angular/material/list";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { LoginComponent } from "./components/pages/auth/login/login.component";
import { DashboardComponent } from "./components/pages/dashboard/dashboard.component";
import { ArchwizardModule } from "angular-archwizard";
import { TellyourselfComponent } from "./components/pages/tellyourself/tellyourself.component";
import { AdminModule } from "./admin/admin.module";
import { WebLayoutComponent } from "./theme/web-layout/web-layout.component";
import { WebModule } from "./web/web.module";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgWizardConfig, NgWizardModule, THEME } from "ng-wizard";
import { DemoWizardService } from "./shared/services/demo-wizard.service";

import { StepSixComponent } from "./components/pages/steps/step-six/step-six.component";
import { StepOneComponent } from "./components/pages/steps/step-one/step-one.component";
import { StepTwoComponent } from "./components/pages/steps/step-two/step-two.component";
import { StepThreeComponent } from "./components/pages/steps/step-three/step-three.component";
import { StepFourComponent } from "./components/pages/steps/step-four/step-four.component";
import { StepFiveComponent } from "./components/pages/steps/step-five/step-five.component";
import { DynamicScriptLoaderService } from "./shared/services/dynamic-script-loader.service";
// import { NgxPayPalModule } from 'ngx-paypal';
import { PhoneMaskDirective } from "./shared/directives/phone-mask.directive";
import { OnlyNumberDirective } from "./shared/directives/only-number.directive";
import { CommonModule } from "@angular/common";
import { OrderStatusComponent } from "./components/pages/order-status/order-status.component";
import { RecoverpwdComponent } from "./components/pages/auth/recoverpwd/recoverpwd.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { PasswordresetComponent } from "./components/pages/auth/passwordreset/passwordreset.component";
import { KeycloakAngularModule, KeycloakService } from "keycloak-angular";
import { initializeKeycloak } from "./core/authentication/app.init";
import { ModalComponent } from "./_components";
import { PrivacyPolicyComponent } from "./components/layouts/privacy-policy/privacy-policy.component";
import { TermsOfServiceComponent } from "./components/layouts/terms-of-service/terms-of-service.component";
import { ShortNamePipe } from "./shortname.pipe";
import { TimeRemainingPipe } from "./shared/pipes/time-remaining.pipe";
import { NgxStripeModule } from "ngx-stripe";
import { NgxSpinnerModule } from "ngx-spinner";
import { RouterModule } from "@angular/router";
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { MatDialogModule } from "@angular/material/dialog";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";


export function createTranslateLoader(http: HttpClient): any {
    return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}
// wizard
const ngWizardConfig: NgWizardConfig = {
    theme: THEME.default,
};
@NgModule({
    declarations: [
        AppComponent,
        PreloaderComponent,
        FooterComponent,
        NavbarComponent,
        StoryComponent,
        HomeOneComponent,
        TeamComponent,
        NewsComponent,
        CtaComponent,
        CallToActionComponent,
        SubscribeComponent,
        ContactComponent,
        PartnerComponent,
        AboutComponent,
        ServicesComponent,
        PortfolioComponent,
        SkillsComponent,
        FeaturesComponent,
        WhyWeDifferentComponent,
        FunfactsComponent,
        PricingComponent,
        FaqComponent,
        BlogComponent,
        CtaTwoComponent,
        WhoWeAreComponent,
        FunfactsTwoComponent,
        ShortNamePipe,
        BlogOneComponent,
        BlogDetailsComponent,
        ErrorComponent,
        DemoSidebarComponent,
        SignupComponent,
        LoginComponent,
        DashboardComponent,
        TellyourselfComponent,
        StepOneComponent,
        StepTwoComponent,
        StepThreeComponent,
        StepFourComponent,
        StepFiveComponent,
        StepSixComponent,
        WebLayoutComponent,
        PhoneMaskDirective,
        OnlyNumberDirective,
        OrderStatusComponent,
        RecoverpwdComponent,
        PasswordresetComponent,
        ModalComponent,
        PrivacyPolicyComponent,
        TermsOfServiceComponent,
        TimeRemainingPipe,
    ],
    imports: [
        RouterModule,
        BrowserModule,
        CommonModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        NgxSpinnerModule.forRoot({ type: "ball-scale-multiple" }),
        MatStepperModule,
        MatInputModule,
        MatButtonModule,
        MatListModule,
        AppRoutingModule,
        HttpClientModule,
        ArchwizardModule,
        AdminModule,
        WebModule,
        NgxStripeModule.forRoot(
            environment.stripeKey
        ),
        NgWizardModule.forRoot(ngWizardConfig),
        BrowserAnimationsModule,
        KeycloakAngularModule,
        ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: "toast-top-right",
            preventDuplicates: true,
            closeButton: true,
        }), // ToastrModule added
        NgxUiLoaderModule,
        MatDialogModule,
        GooglePlaceModule
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService],
        },
        DemoWizardService,
        DynamicScriptLoaderService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
