<div class="reg-con">

  <div class="container-fluid">
    <div class="row">
        <div class="header">
            <a class="logo" href="/home">AAAMB</a>
        </div>
        <div>
            <p class="userinfo">Welcome <span> {{username | titlecase}}!</span></p>
        </div>
    </div>
    <div class="row main-hero">
      <div class="col-xl-12  justify-content-center d-flex">
        <div class="col-xl-6 col-md-7 col-sm-7 left-sec">
          <div class="row">
            <img src="assets/img/setup-profile.png" alt="Setup your profile">
          </div>

        </div>
        <div class="col-xl-5 col-md-5 col-sm-5 right-sec">
            <div class="col-lg-10 col-md-10 col-sm5 m-auto">
          <form [formGroup]="practiceForm" (ngSubmit)="onSubmit()">
            <div class="col-xl-12 m-auto justify-content-center">
              <h3><b>Set Up Your Profile</b></h3>

              <div class="col-xl-12 mb-4" style="display: flex;">
                <div class="col-xl-6">
                  <label class="mb-2" for="npinumber"> NPI Number<span>*</span></label>
                  <!--  (keyup)="checkNPI($event.target.value)"  add after demo -->
                  <input type="text" formControlName="npiNumber"
                    [ngClass]="{ 'is-invalid': submitted && f.npiNumber.errors }" class="form-control rounded"
                    id="npinumber" (keyup)="checkNPI($event.target.value)" placeholder="Enter NPI Number" maxlength="10">
                    <div class="text_errors" style="margin-top: 10px;" *ngIf="validNPILength">
                         NPI number should be 10 digits.
                       </div>
                    <div class="text_errors" style="margin-top: 10px;" *ngIf="validNPI">
                       Invalid NPI Number
                      </div>
                </div>
                <div class="col-xl-6" style="padding-left: 10px; float: right !important;" *ngIf="userType">
                    <label class="mb-2" for="npitype">NPI Type<span>*</span></label>
                    <select id="npitype" class="form-control rounded no-arrow" style="padding:0 0 0 15px;" formControlName="npiType"
                      [ngClass]="{ 'is-invalid': submitted && f.npiType.errors }" 
                      (change)="getNpiType($event)" [(ngModel)]="selectedNpiType"disabled>
                      (change)="getNpiType($event)" >
                      <option value="undefined" disabled selected>NPI Type</option>
                      <option *ngFor="let c of npiType" [value]="c._id">{{ c.name }}</option>
                    </select>
                  <!-- <div *ngIf="submitted && f.userType.errors" class="invalid-feedback">
                    <div *ngIf="f.userType.errors.required">User Type is required</div>
                  </div> -->
                </div>
              </div>
              <div class="col-xl-12 mb-4 mt-4">
                <label class="mb-2" for="useremail">User Type<span>*</span></label>
                <select id="usertype" formControlName="userType" class="form-control rounded no-arrow"
                  [ngClass]="{ 'is-invalid': submitted && f.userType.errors }" style="padding:0 0 0 15px;"
                  (change)="getUserType($event)" [(ngModel)]="selectedUserType" disabled>
                  <!-- <option value="undefined" disabled selected>User Type</option> -->
                  <option *ngFor="let c of userType" [value]="c._id" [selected]="c._id === selectedUserType">{{ c.name
                    }}</option>
                </select>
                <!-- <div *ngIf="submitted && f.npiType.errors" class="invalid-feedback">
                  <div *ngIf="f.npiType.errors.required">NPI Type is required</div>
                </div> -->
              </div>
              <div class="col-xl-12 mb-4">
                <label class="mb-2" for="password">Practice Name<span>*</span></label>
                <input type="text" title="Practice Name cannot be edited as it is retrieved from NPI registry" formControlName="practiceName"
                  [ngClass]="{ 'is-invalid': submitted && f.practiceName.errors }" class="form-control rounded"
                  id="practice" placeholder="Enter Practice Name">
                  <!-- <div *ngIf="!isDisplayed" class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                   </div> -->

              </div>
              <div class="col-xl-12 mb-4">
                <label class="mb-2" for="phone">
                  Facility/Location Name</label>
                <input formControlName="facilityName" [ngClass]="{ 'is-invalid': submitted && f.facilityName.errors }"
                  class="form-control rounded" id="facility" placeholder="Enter Facility/Location Name">

              </div>
              <div class="col-xl-12 mb-2">

                    <p style="width:100%"><b>Note </b><br /> <i>You can change the details in settings if needed</i></p>
                    <div class="m-auto btn-center">
                      <button type="submit" class="btn btn-aaamb btn-right">Get Started</button> &nbsp;&nbsp;&nbsp;
                      <!-- <a href="/login" class="btn btn-aaamb btn-right">Go Back</a> -->
                    </div>

                <br/>
              </div>
            </div>
          </form>
            </div>
        </div>
      </div>
    <div class="d-flex justify-content-around footer">
      <div class="aambgreen_color">
        Copyright ©2024 AAAMB LLC. All rights reserved.
      </div>
      <div>
        <a target="_blank" class="aambgreen_color footer_links" href="{{siteURL}}/terms-service">Terms of Services</a>
        <a target="_blank" class="aambgreen_color" style="margin-left: 10px;" href="{{siteURL}}/privacy-policy">Privacy Policy</a>
      </div>
    </div>
      <!-- <div class="col-xl-12 footer">Copyright ©2024 AAAMB LLC. All rights reserved.</div><br/><br/> -->
    </div>
  </div>
</div>
