import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class DataService {
    private refresh: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    private subject = new Subject<any>();

    private Logo = new BehaviorSubject("false");
    getMessage = this.Logo.asObservable();

    constructor() {}

    sendClickEvent(message: any) {
        this.subject.next(message);
    }
    getClickEvent(): Observable<any> {
        return this.subject.asObservable();
    }

    updateLogo(Logo){
      this.Logo.next(Logo)
    }

    public getRefresh(): Observable<boolean> {
        return this.refresh.asObservable();
    }

    public setRefresh(value: boolean): void {
        this.refresh.next(value);
    }
}
