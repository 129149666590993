import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import { AuthService, LoginService, Token, TokenService } from 'src/app/core/authentication';
// import { BASE_URL } from './base-url-interceptor';

import { LocalService } from 'src/app/shared/services/local.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private hasHttpScheme = (url: string) => new RegExp('^http(s)?://', 'i').test(url);
  private isRefreshing = false;
  previousUrl!: string ;
  currentUrl!: string;
  constructor(
    private tokenService: TokenService,
    private auth: AuthService,
    private router: Router,
    private localService: LocalService,
    private loginService: LoginService
  ) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }
  //gets the previous url
  public getPreviousUrl() {
    return this.previousUrl;
  }
  //identifies and handles given http request
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const  token  = this.tokenService.getToken();
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    const currentToken = localStorage.getItem('uid');
    const handler = () => {
      if (request.url.includes('/auth/logout')) {
        this.router.navigateByUrl('/auth/login');
      }
      if (this.router.url.includes('/auth/login')) {
        this.router.navigateByUrl(this.getPreviousUrl());
      }
    };
    if (this.tokenService.getTimeForRefrsh() && currentToken) {
      return this.handle401Error(request, next);
    } else return next.handle(request).pipe(tap(() => handler()));
  }
  // handles 401 error and gets refresh token
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      return this.auth.refresh().pipe(
        switchMap(() => {
          this.isRefreshing = false;
          return next.handle(request);
        }),
        catchError(error => {
          this.isRefreshing = false;
          if(error.message.includes('403')){
            const  token  = this.tokenService.getToken();
            if (token) {
              request = request.clone({
                setHeaders: {
                  Authorization: `Bearer ${token}`,
                },
              });
            }
            return next.handle(request);
          }
          else
          {
            this.auth.logout().subscribe(() => this.router.navigateByUrl('/auth/login'));
          }
          return throwError(() => error);
        })
      );
    }
    return next.handle(request);
  }

}
