import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/user';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StatesService {
    constructor(private http: HttpClient) { }
    
    getAll(): Promise<any> {
        try {
            return lastValueFrom(this.http.get(environment.mainURL + "/state"));
        } catch (error: any) {
            throw new Error(error.message);
        }
    }

    getSingleState(Id): Promise<any> {
        try {
            return lastValueFrom(this.http.get(environment.mainURL + "/state/"+Id));
        } catch (error: any) {
            throw new Error(error.message);
        }
    } 
    
}
