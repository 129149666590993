import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeOneComponent } from "./components/pages/home-one/home-one.component";
import { TellyourselfComponent } from "./components/pages/tellyourself/tellyourself.component";
import { SignupComponent } from "./components/pages/auth/signup/signup.component";
import { LoginComponent } from "./components/pages/auth/login/login.component";
import { OrderStatusComponent } from "./components/pages/order-status/order-status.component";
import { RecoverpwdComponent } from "./components/pages/auth/recoverpwd/recoverpwd.component";
import { PasswordresetComponent } from "./components/pages/auth/passwordreset/passwordreset.component";
import { AuthGuard } from "./core/authentication/auth.guard";

const routes: Routes = [
    { path: "", redirectTo: "login", pathMatch: "full" },
    { path: "profilesetup", component: TellyourselfComponent },
    { path: "orderstatus", component: OrderStatusComponent },
    { path: "signup", component: SignupComponent },
    {
        path: "login",
        component: LoginComponent,
        // data: {
        //     roles: ["admin"],
        // },
        // canActivate: [AuthGuard],
    },
    { path: "reset-password", component: RecoverpwdComponent },
    { path: "resetp", component: PasswordresetComponent },
    {
        path: "home",
        loadChildren: () => import("./web/web.module").then((m) => m.WebModule),
    },
    {
        path: "dashboard",
        loadChildren: () =>
            import("./admin/admin.module").then((m) => m.AdminModule),
    },
    // {path:'cart', component:CartComponent}
    // Here add new pages component
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
