    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container">
            <a class="navbar-brand" routerLink="/">
                <img src="assets/img/aaamb-logo-new.png" width="150" alt="logo">
                <img src="assets/img/aaamb-logo-new.png" width="150" alt="logo">
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav m-auto">
                    <li class="nav-item aambgreen_color"><a class="nav-link" href="/home"  [routerLink]="['/home']" [routerLinkActive]="'active'">Home</a></li>
                    <li class="nav-item aambgreen_color"><a class="nav-link" href="/products" [routerLink]="['/products']" [routerLinkActive]="'active'">Products</a></li>
                    <!-- <li class="nav-item aambgreen_color"><a class="nav-link" href="/pricing" [routerLink]="['/pricing']" [routerLinkActive]="'active'">Pricing</a></li> -->
                    <li class="nav-item aambgreen_color"><a class="nav-link" href="/services"[routerLink]="['/services']" [routerLinkActive]="'active'">Services</a></li>
                    <li class="nav-item aambgreen_color"><a class="nav-link" href="/testimonials"[routerLink]="['/testimonials']" [routerLinkActive]="'active'">Testimonials</a></li>
                    <!-- <li class="nav-item aambgreen_color"><a class="nav-link" href="/partners"[routerLink]="['/partners']" [routerLinkActive]="'active'">News</a></li> -->
                    <li class="nav-item aambgreen_color"><a class="nav-link" href="/contact-us"[routerLink]="['/contact-us']" [routerLinkActive]="'active'">Contact Us</a></li>
                    </ul>
                    <ul class="rightnav">
                    <li class="nav-item"><a class="nav-link" href="/login">Login</a></li>
                    <li class="nav-item"><a class="btn btn-aaamb btn-rounded" (click)="redirectToPricing()" ><span>Sign Up</span><i class="ico icofont-arrow-right"></i></a></li>
                </ul>
            </div>
        </div>
    </nav>

