<div class="reg-con">
    <div class="container-fluid">
        <div class="row">
            <div class="header">
                <a class="logo" href="/home">AAAMB</a>
            </div>
            <!-- <div>
        <p class="userinfo">Welcome <span> {{username}}</span>!</p>
      </div> -->
        </div>
        <div class="row main-hero">
            <div class="col-xl-12 justify-content-center d-flex">
                <div class="col-lg-6 col-md-6 col-sm-6 left-sec">
                    <div class="left-img  m-auto">
                      <img src="assets/img/signup-bg.png" alt="Recover Password">
                    </div>

                  </div>
                  <div class="col-lg-5 col-md-5 col-sm-5 right-sec">
                    <div class="col-lg-10 col-md-10 col-sm5 m-auto">
                    <form [formGroup]="resetForm" (ngSubmit)="onSubmit()">
                        <div class="col-xl-12 m-auto justify-content-center">
                            <h3><b>Reset Password</b></h3>

                            <div class="col-xl-12 mb-3">
                                <label class="mb-2" for="password">New Password<span>*</span></label>
                                <div class="input-group auth-pass-inputgroup">
                                <input [type]="showPassword ? 'text' : 'password'"
                                    formControlName="newPassword"
                                    [ngClass]="{
                                        'is-invalid':
                                            submitted && f.newPassword.errors
                                    }"
                                    class="form-control rounded"
                                    id="newpassword"
                                    placeholder="New Password"  (keyup)="pressNewPassword($event.target.value)"
                               >
                               <div (click)="toggleShowPassword()" class="show-password-icon">
                                <i [class]="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'" aria-hidden="true"></i>
                                </div>
                                </div>
                            </div>
                            <div class="col-xl-12 mt-2 m-auto">
                                <div class="text_errors" *ngIf="resetForm.get('newPassword')?.touched && f.newPassword.errors">
                                <label class="col validcol" [ngClass]="resetForm.controls['newPassword'].hasError('required') || resetForm.controls['newPassword'].hasError('minlength')  ? 'text-danger' : 'text-success'">
                                    <i class="material-icons">{{ resetForm.controls['newPassword'].hasError('required') ||
                                        resetForm.controls['newPassword'].hasError('minlength') ? 'cancel' :
                                      'check_circle' }}</i>
                                    Must be at least 8 characters!
                                  </label>
                                  <label class="col validcol" [ngClass]="resetForm.controls['newPassword'].hasError('required') || resetForm.controls['newPassword'].hasError('hasNumber')  ? 'text-danger' : 'text-success'">
                                    <i class="material-icons">{{ resetForm.controls['newPassword'].hasError('required') ||
                                        resetForm.controls['newPassword'].hasError('hasNumber') ? 'cancel' :
                                      'check_circle' }}</i>
                                    Must contain at least 1 number!
                                  </label>
                                  <label class="col validcol" [ngClass]="resetForm.controls['newPassword'].hasError('required') || resetForm.controls['newPassword'].hasError('hasCapitalCase')  ? 'text-danger' : 'text-success'">
                                    <i class="material-icons">{{ resetForm.controls['newPassword'].hasError('required') ||
                                        resetForm.controls['newPassword'].hasError('hasCapitalCase') ? 'cancel' :
                                      'check_circle' }}</i>
                                    Must contain at least 1 in Capital Case!
                                  </label>
                                  <label class="col validcol" [ngClass]="resetForm.controls['newPassword'].hasError('required') || resetForm.controls['newPassword'].hasError('hasSmallCase')  ? 'text-danger' : 'text-success'">
                                    <i class="material-icons">{{ resetForm.controls['newPassword'].hasError('required') ||
                                        resetForm.controls['newPassword'].hasError('hasSmallCase') ? 'cancel' :
                                      'check_circle' }}</i>
                                    Must contain at least 1 Letter in Small Case!
                                  </label>
                                  <label class="col validcol" [ngClass]="resetForm.controls['newPassword'].hasError('required') || resetForm.controls['newPassword'].hasError('hasSpecialCharacters') ? 'text-danger' : 'text-success'">
                                    <i class="material-icons">{{ resetForm.controls['newPassword'].hasError('required') ||
                                        resetForm.controls['newPassword'].hasError('hasSpecialCharacters') ? 'cancel' :
                                      'check_circle' }}</i>
                                    Must contain at least 1 Special Character!
                                  </label>
                                </div>
                        </div>
                            <div class="col-xl-12 mb-4">
                                <label class="mb-2" for="phone">Confirm Password<span>*</span></label>
                                <div class="input-group auth-pass-inputgroup">
                                    <input
                                    [type]="showConfirmPassword ? 'text' : 'password'"
                                    formControlName="confirmPassword"
                                    [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }"
                                    class="form-control rounded"
                                    id="confirmpassword"
                                    placeholder="Confirm Password">
                                    <div (click)="toggleConfirmShowCPassword()" class="show-password-icon">
                                        <i [class]="showConfirmPassword ? 'fa fa-eye' : 'fa fa-eye-slash'" aria-hidden="true"></i>
                                        </div>

                                </div>
                                <div class="text_errors move-danger" *ngIf="!resetForm.controls['newPassword'].hasError('required') && resetForm.get('confirmPassword')?.touched && f.confirmPassword.errors">
                                    <span class="text-danger"  *ngIf="resetForm.controls['confirmPassword'].hasError('NoPasswordMatch')">
                                        Password and Confirm Password do not match.
                                    </span>
                                </div>
                                 <!-- <p *ngIf="isnpandcpsame" class="npandcpnotsame">
                                    Password and Confirm Password do not match.
                                </p> -->
                            </div>
                            <div class="col-xl-12">
                                <div class="m-auto" style="text-align: center;">
                                    <button type="submit" class="btn btn-aaamb btn-right">
                                        Submit
                                    </button>
                                    &nbsp;&nbsp;&nbsp;
                                    <!-- <a  href="/login" class="btn btn-aaamb btn-right">Login</a> -->
                                </div>

                                <br />
                            </div>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    <div class="row mr-28">
      <div class="col-md-6 col-sm-12 col-lg-6 pl-35">
          <p class="copy_rights_styles _color">Copyright ©2024 AAAMB LLC. All rights reserved</p>
      </div>
      <div class="col-md-6 col-sm-12 col-lg-6 text-end pr-35">
          <div class="m-r-3p">
              <a routerLink="/terms-service" target="_blank" class="footer_links font-14 _color">Terms of
                      Services</a>
      <a routerLink="/privacy-policy" target="_blank" class="font-14 _color"
                      style="margin-left: 15px;">Privacy Policy</a>
          </div>
      </div>
    </div>
</div>
