<div id="admin-container">  
    <div class="container-fluid">
        <div class="row">
            <h5 class="NPI-data">NPI No: <b>0123456789</b> | NPI Type: <b>Group</b></h5>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row admin-cont">
        Roles content some here
        </div>
    </div>
</div>
