import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from "@angular/forms";
import { NgWizardService } from "ng-wizard";
import { DynamicScriptLoaderService } from "src/app/shared/services/dynamic-script-loader.service";
import { ICreateOrderRequest, IPayPalConfig } from "ngx-paypal";
import { KCUser, KCUserAttributes, User } from "src/app/shared/models/user";
import { CustomerAddress } from "src/app/shared/models/address";
import { ItemSchema, SubscriptionOrder } from "src/app/shared/models/order";
import { CartService } from "src/app/shared/services/cart/cart.service";
import { CustomerService } from "src/app/shared/services/customers/customers.service";
import { Subscription } from "src/app/shared/models/subscription";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { DataService } from "src/app/shared/services/data.service";
import { CookieService } from "ngx-cookie-service";
import {
    StripeService,
    StripeCardComponent,
    StripeCardNumberComponent,
} from "ngx-stripe";
import {
    StripeCardElementOptions,
    StripeElementsOptions,
} from "@stripe/stripe-js";
import { NgxSpinnerService } from "ngx-spinner";
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService

@Component({
    selector: "app-step-five",
    templateUrl: "./step-five.component.html",
    styleUrls: ["./step-five.component.scss"],
})
export class StepFiveComponent implements OnInit {
    @ViewChild("cardNumber") cardNumber: StripeCardComponent;

    item: ItemSchema;
    orderItems: ItemSchema[];

    kcUser!: KCUser;
    kcUserAttributes!: KCUserAttributes;
    user!: User;
    address!: CustomerAddress;
    order!: SubscriptionOrder;
    subscription!: Subscription;
    grandTotal = 0;
    transId = "";
    awaiting = false;
    paymentForm: UntypedFormGroup;
    public payPalConfig?: IPayPalConfig;
    // public cart: any[] = [];
    public showSuccess: boolean = false;
    public showCancel: boolean = false;
    public showError: boolean = false;
    items: {
        name: any;
        quantity: any;
        category: string;
        unit_amount: { currency_code: string; value: any };
    }[] = [];
    total = 0;
    @ViewChild("priceElem", { static: false }) priceElem?: ElementRef;
    cardError:boolean = false;

    cardOptions: StripeCardElementOptions = {
        style: {
            base: {
                iconColor: "#666EE8",
                color: "#31325F",
                fontWeight: "300",
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSize: "18px",
                "::placeholder": {
                    color: "#6e707e",
                    fontSize: "14px",
                },
            },
        },
    };

    elementsOptions: StripeElementsOptions = {
        locale: "en",
    };

    stripeTest: UntypedFormGroup;
    cardErrText: string;

    constructor(
        private router: Router,
        private formBuilder: UntypedFormBuilder,
        private cartService: CartService,
        private userService: CustomerService,
        private ngWizardService: NgWizardService,
        private toastr: ToastrService,
        private dataService: DataService,
        private cookieService: CookieService,
        private stripeService: StripeService,
        private fb: UntypedFormBuilder,
        private spinner: NgxSpinnerService,
        private ngxService: NgxUiLoaderService
    ) {
        this.paymentForm = this.formBuilder.group({});
    }

    ngOnInit(): void {
        this.dataService.getClickEvent().subscribe((value: any) => {
            // console.log("step-5 value"+value);
            if (value == "paypal") {
                this.awaiting = false;
                this.updateTotal();
            }

            this.stripeTest = this.fb.group({
                name: ["", [Validators.required]],
            });
        });

        // const cardNumberElement = this.cardNumber.elements.getElement('cardNumber');

        // cardNumberElement.on('change',(event)=>{
        // }
        // )
        // this.initConfig(this.total + "");
        //  this.dsls.loadScript(this.scriptName);
    }
    updateTotal() {
        //console.log(this.cartService.cartItemList);
        this.cartService.cartItemList.forEach((cartItem) => {
            this.items.push({
                name: cartItem.name,
                quantity: 1,
                category: "DIGITAL_GOODS",
                unit_amount: {
                    currency_code: "USD",
                    value: cartItem.price,
                },
            });
            this.total += parseFloat(cartItem.price) * 1;
        });
        // console.log("----------------total----------------" + this.total);
        this.initConfig(this.total + "");
    }
    onSubmitPayment() {
        // console.log("----------------payment----------------");
        this.ngWizardService.next();
    }
    private initConfig(price: string): void {
        //  console.log("price-----" + price);
        this.payPalConfig = {
            currency: "USD",
            clientId:
                "AdJBeIa85QFSLeHLRijzXBXtWDhDrI19Cwm6bBnWou64BgQ8JMWk8cNJES7iFnkwtxs1Hzw_LHc9nSg8",
            createOrderOnClient: (data: any) =>
                <ICreateOrderRequest>{
                    intent: "CAPTURE",
                    purchase_units: [
                        {
                            amount: {
                                currency_code: "USD",
                                value: price,
                                breakdown: {
                                    item_total: {
                                        currency_code: "USD",
                                        value: price,
                                    },
                                },
                            },
                            items: this.items,
                        },
                    ],
                },
            advanced: {
                commit: "true",
            },

            style: {
                shape: "pill",
                color: "blue",
                label: "paypal",
                layout: "vertical",
            },
            onApprove: (
                data: any,
                actions: { order: { get: () => Promise<any> } }
            ) => {
                // console.log(
                //     "onApprove - transaction was approved, but not authorized",
                //     data,
                //     actions
                // );
                actions.order.get().then((details: any) => {
                    // console.log(
                    //     "onApprove - you can get full order details inside onApprove: ",
                    //     details
                    // );
                });
            },
            onClientAuthorization: (data: any) => {
                // console.log(
                //     "onClientAuthorization - you should probably inform your server about completed transaction at this point",
                //     data
                // );
                this.transId = data.id;
                this.showSuccess = true;
                this.awaiting = true;
                // this.saveClientData();
            },
            onCancel: (data: any, actions: any) => {
                //console.log("OnCancel", data, actions);
                this.showCancel = true;
            },
            onError: (err: any) => {
                // console.log("OnError", err);
                this.showError = true;
            },
            onClick: (data: any, actions: any) => {
                //console.log("onClick", data, actions);
            },
            onInit: (data: any, actions: any) => {
                //console.log("onInit", data, actions);
            },
        };
    }

    makePayment() {
        this.payPalConfig = Object.assign({}, this.payPalConfig);
    }
    private resetStatus(): void {
        this.items = [];
        this.total = 0;
        this.showError = false;
        this.showSuccess = false;
        this.awaiting = false;
        this.cookieService.delete("aaamb_cart", "/");
    }

    saveClientData(paymentId) {
        let dt = new Date();
        dt.setMonth(dt.getMonth() + 1);
        let cItems = [];
        // if (this.cartService.cartItemList.length > 0) {
        //     for (let i = 0; i < this.cartService.cartItemList.length; i++) {
        //         cItems.push(this.cartService.cartItemList[i]._id);
        //     }
        // }
        // console.log("cart items array" + JSON.stringify(cItems));
        this.kcUserAttributes = {
            type: "",
            value: "",
            temporary: false,
        };
        this.kcUser = {
            enabled: true,
            firstName: "",
            lastName: "",
            username: "",
            email: "",
            groups: [""],
            credentials: [this.kcUserAttributes],
        };
        this.item = {
            product: "",
            amount: "",
            status: "",
            txnId: "",
            subscriptionDate: new Date(),
            renewalDate: dt,
            subscriptionType: "Monthly",
            autorenewal: false,
            createdby: "1",
            createdon: new Date(),
            modifiedby: "1",
            modifiedon: new Date(),
        };
        this.order = {
            customerId: "",
            items: [],
        };
        this.subscription = {
            transactionId: "",
            subscriptionId: "",
        };
        this.userService.subscription = {
            transactionId: "",
            subscriptionId: "",
        };
        let productNames = "";
        this.user = this.userService.user;
        this.user.email = this.user.email.toLowerCase();
        this.user.payment_method_id = paymentId;
        this.kcUser.enabled = true;
        this.kcUser.firstName = this.user.firstname;
        this.kcUser.lastName = this.user.lastname;
        this.kcUser.email = this.user.email;
        this.kcUser.username = this.user.email;
        this.kcUser.groups = ["admin"];
        this.kcUserAttributes.type = "password";
        this.kcUserAttributes.value = this.user.password;
        this.kcUserAttributes.temporary = false;

        this.kcUser.credentials = [this.kcUserAttributes];
        this.userService.GetAccessToken().then((token: any) => {
            let kctoken = token.access_token;
            this.userService
                .AddKCUser(this.kcUser, kctoken)
                .then((resp) => {
                    console.log("kcuser------------"+JSON.stringify(resp));
                    this.user.kcid = resp.id;
                    this.userService.AddUser(this.user).then((res) => {
                        if (this.cartService.cartItemList.length > 0) {
                            for (
                                let i = 0;
                                i < this.cartService.cartItemList.length;
                                i++
                            ) {
                                productNames =
                                    productNames +
                                    ",  " +
                                    this.cartService.cartItemList[i].name;
                                this.item.product =
                                    this.cartService.cartItemList[i]._id;
                                this.item.amount =
                                    this.cartService.cartItemList[i].price;
                                this.item.status = "Active";
                                this.item.txnId = this.transId;
                                this.item.subscriptionDate = new Date();
                                this.item.renewalDate = dt;
                                this.item.subscriptionType = "Monthly";
                                this.item.autorenewal = true;
                                this.item.createdby = "1";
                                this.item.createdon = new Date();
                                this.item.modifiedby = "1";
                                this.item.modifiedon = new Date();

                                // console.log("item-------------------"+JSON.stringify(this.item));
                                cItems.push(this.item);
                                this.item = new ItemSchema();
                            }
                        }

                        this.order.customerId = res.id;
                        this.order.items = cItems;

                        // console.log(
                        //     "subscription data----------------------" +
                        //         JSON.stringify(this.order)
                        // );
                        let selItems = productNames.trim();
                        if (selItems.charAt(0) === ",") {
                            productNames = selItems.slice(1);
                        }
                        this.userService
                            .AddOrder(this.order)
                            .then((data) => {
                                //console.log(JSON.stringify(data));
                                // this.subscription.subscriptionId =
                                //     data.items[0].orderId;
                                this.ngxService.stop();
                                this.subscription.transactionId = this.transId;
                                this.userService.subscription =
                                    this.subscription;
                                this.address = this.userService.customerAddress;
                                this.address.customerId = this.order.customerId;
                                this.userService
                                    .AddAddress(this.address)
                                    .then((data) => {
                                        //console.log(JSON.stringify(data));
                                        this.userService
                                            .SendWelcomeEmail(
                                                this.user.email,
                                                this.user.firstname +
                                                    " " +
                                                    this.user.lastname,
                                                "AAAMB",
                                                productNames
                                            )
                                            .then((res) => {
                                                //console.log(JSON.stringify(res));
                                                this.resetStatus();
                                                this.router.navigateByUrl(
                                                    "/orderstatus"
                                                );
                                            });
                                    });
                            })
                            .catch((error) => {
                                //console.log("Add Subscription" + error);
                                this.toastr.error(
                                    "An error occurred while the Subscription is created!!!, Please contact Administrator",
                                    "Error"
                                );
                            });
                    });
                })
                .catch((error) => {
                    console.log("Add User " + JSON.stringify(error));
                    this.toastr.error(
                        "An error occurred while the user is created!!!, Please contact Administrator",
                        "Error"
                    );
                });
        });
    }
    async createToken() {
        const cardElement = this.cardNumber.element;
        this.ngxService.start();
        // Create a Payment Method using the card element
        const result = await this.stripeService.createPaymentMethod({
            type: "card",
            card: cardElement,
        });
        result.subscribe((result) => {
            console.log("hit", result);
            if(result?.error?.type == 'validation_error' || result?.error?.message !== ''){
                this.cardError = true;
                if(result?.error && result?.error?.code == 'card_declined'){
                    this.cardErrText = "Your Card was declined";
                }else{  
                    this.cardErrText = result?.error?.message;
                }
                this.ngxService.stop();              
            }
            if(result?.paymentMethod){
            this.saveClientData(result?.paymentMethod?.id);
            }
        },(err)=>{
            this.ngxService.stop();
        });
    }
}
