import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';


import { User, login } from '../models/user';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { APP_CONSTANTS } from '../utils/appconstants';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private userSubject: BehaviorSubject<User | null>;
    public user: Observable<User | null>;
    readonly userURL = environment.mainURL;
    readonly siteURL = environment.siteURL;
    constructor(
        private router: Router,
        private http: HttpClient,
        private cookie: CookieService
    ) {
        this.userSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('user')!));
        this.user = this.userSubject.asObservable();
    }

    public get userValue() {
        return this.userSubject.value;
    }

    login(username: string, password: string, grantType: any) {
        const loginUser = {
            email: username,
            password: password,
            applicationName: 'MAIN'
           }
        return this.http.post<any>( this.userURL+`/user/login`, loginUser)
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                this.cookie.set(APP_CONSTANTS.cookiekey, JSON.stringify(user), 1, '/');
                //localStorage.setItem('user', JSON.stringify(user));
                this.userSubject.next(user);
                return user;
            }));
    }
    signedInUserData(username: string) {
        const loginUser = {
            email: username
           }
        return this.http.post<any>( this.userURL+`/user/signedindata`, loginUser)
            .pipe(map(user => {
                this.cookie.delete(APP_CONSTANTS.cookiekey, '/');
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                this.cookie.set(APP_CONSTANTS.cookiekey, JSON.stringify(user), 1, '/');
                //localStorage.setItem('user', JSON.stringify(user));
                this.userSubject.next(user);
                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('user');
        this.userSubject.next(null);
        this.router.navigate([this.siteURL+'/login']);
    }
}
