import { HttpClient } from "@angular/common/http";
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";

import { NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { EventService } from "src/app/shared/services/event.service";
import { SidebarService } from "src/app/shared/services/sidebar.service";

import { MENU } from "../menu";
import { MenuItem } from "../menu.model";
import { CustomerService } from "src/app/shared/services/customers/customers.service";
import { APP_CONSTANTS } from "src/app/shared/utils/appconstants";
import { DataService } from "src/app/shared/services/data.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-sidebar",
    templateUrl: "./sidebar.component.html",
    styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit, AfterViewInit, OnChanges {
    public siteURL = environment.siteURL;
    @Input() isExpanded: boolean = false;
    @Output() toggleSidebar: EventEmitter<boolean> =
        new EventEmitter<boolean>();

    handleSidebarToggle = () => this.toggleSidebar.emit(!this.isExpanded);
    practice: String = "";
    imgUrl = "";
    logo = false;
    constructor(
        private eventService: EventService,
        private dataService: DataService,
        private router: Router,
        public translate: TranslateService,
        private http: HttpClient,
        private customerService: CustomerService
    ) {}

    ngOnInit() {
        console.log(
            "---------------------sidebar" +
                JSON.stringify(
                    this.customerService.loggedInUser[0].practiceData
                )
        );
        if (this.customerService.loggedInUser[0].practiceData) {
            //const usertype=this.customerService.loggedInUser[0].usertype
            this.practice =
                this.customerService.loggedInUser[0].practiceData.name;
        }
        if (this.customerService.npiInfo) {
            //const usertype=this.customerService.loggedInUser[0].usertype
            this.practice = this.customerService.npiInfo.practicename;
        }
        console.log(
            JSON.stringify(
                this.customerService.loggedInUser[0].practiceData.logo
            )
        );
        if (
            this.customerService.loggedInUser[0].practiceData.logo == undefined
        ) {
            this.logo = false;
        } else {
            this.imgUrl =
                APP_CONSTANTS.imgurl +
                this.customerService.loggedInUser[0].practiceData.logo;
            this.logo = true;
        }
        this.dataService.getRefresh().subscribe((value: boolean) => {
            if (value) {
                this.imgUrl =
                    APP_CONSTANTS.imgurl +
                    this.customerService.loggedInUser[0].practiceData.logo;
                this.logo = true;
            }
        });
        console.log(this.logo);
    }
    ngAfterViewInit() {}
    ngOnChanges() {}
    getState(link) {
        if (window.location.pathname == link) {
            return true;
        } else {
            return false;
        }
    }
}
