<div class="col-lg-10 col-xl-10 m-auto">
  <h3 style="text-align: center;"><b>Enter authorization code</b></h3>
  <p style="text-align: center;" class="aambgreen_color">We sent a 6 digit confirmation code to <br/> {{userEmail}}</p>
<form [formGroup]="otpForm" (ngSubmit)="onSubmit()" class="justify-content-center">
    <div class="form-check col-xl-12 mb-3">
      <label class="aambgreen_color">Enter authorization code<span>*</span></label>
        <div class="input-group auth-pass-inputgroup">
          <input class="form-control rounded customplaceholder" name="otp" [type]="showPassword ? 'text' : 'password'" formControlName="otp"
          [ngClass]="{ 'is-invalid': submitted && g.otp.errors }" [style.caret-color]="otpLength < 6  ? 'auto' : 'transparent'"
           placeholder="******" maxlength="6" (keyup)="onOTPChangeEvent($event)">
          <div (click)="toggleShowPassword()" class="show-password-icon">
            <i [class]="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'" aria-hidden="true"></i>
          </div>
        </div>
        <div class="p-1">
          <p *ngIf="displayTimer" class="timer">Resend OTP in : {{ value | timeRemaining }} Second(s)</p>
          <button *ngIf="resendOtp"  class="btn btn-primary btn-small" (click)="resendOtpClick()">Resend OTP</button>
          <label *ngIf="invalidotp" class="invalidotp">Invalid OTP!</label>
        </div>
      </div>
      <div class="col-xl-5 m-auto" style="text-align: center;">
        <button  class="btn btn-danger custom-large-btn">Verify</button>
    </div>
</form>
</div>
