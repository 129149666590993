<section class="cta-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-8">
                <div class="cta-content">
                    <h3>Start to build your <b>awesome</b> websites</h3>
                    <span>Create lovely & smart websites faster! Get Avurn now!</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 text-right">
                <a href="#contact" class="btn btn-primary">Start a Project</a>
            </div>
        </div>
    </div>
</section>