import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { APP_CONSTANTS } from 'src/app/shared/utils/appconstants';

@Component({
  selector: 'app-home-one',
  templateUrl: './home-one.component.html',
  styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {

  // @ViewChild('testDiv', {static: false}) 
  // private testDiv: ElementRef<HTMLDivElement>;
  // isTestDivScrolledIntoView: boolean;
  // value = 0;
  // valueinterval: NodeJS.Timeout;
  
  // @HostListener('window:scroll', ['$event']) 
  // isScrolledIntoView($event) {
  //     if (this.testDiv){
  //       const rect = this.testDiv.nativeElement.getBoundingClientRect();
  //       const topShown = rect.top >= 0;
  //       const bottomShown = rect.bottom <= window.innerHeight;
  //       this.isTestDivScrolledIntoView = topShown && bottomShown;
  //       if(this.isTestDivScrolledIntoView) {
  //         this.value=0;
  //       this. valueinterval  = setInterval(()=>{
  //   this.value++;
  // },10)
        
         
  //       }
        
  //     }
  //   }

  constructor( private cookieService : CookieService,private router: Router) { }

  // check() {
  //   if(this.value == 10) {
  //     clearInterval(this.valueinterval)
  //   }
  // }
  ngOnInit() {
    this.cookieService.delete(APP_CONSTANTS.cookiekey, '/');
    // let ltimeZone;
    // if (typeof Intl === 'object' && typeof Intl.DateTimeFormat === 'function') {
    //   ltimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    //   console.log(new Date().toLocaleString('en', {
    //     timeZoneName: 'short',
    //     timeZone: ltimeZone,
    //   }));
    // }
  }

  redirectToPricing() {
    this.router.navigate(['/products'], { queryParams: {id:'pricing'} });
  }

}
