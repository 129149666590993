import { Injectable, Type } from '@angular/core';
import { isObservable, Observable } from 'rxjs';
import { NgWizardConfig, StepValidationArgs, STEP_STATE, THEME } from 'ng-wizard';

import { StepOneComponent } from '../../components/pages/steps/step-one/step-one.component';
import { StepTwoComponent } from '../../components/pages/steps/step-two/step-two.component';
import { StepThreeComponent } from '../../components/pages/steps/step-three/step-three.component';
import { StepFourComponent } from '../../components/pages/steps/step-four/step-four.component';
import { StepFiveComponent } from '../../components/pages/steps/step-five/step-five.component';
import { StepSixComponent } from 'src/app/components/pages/steps/step-six/step-six.component';

@Injectable()
export class DemoWizardService {
  constructor() {
  }

  config: NgWizardConfig = {
    selected: 0,
    theme: THEME.circles,
    toolbarSettings: {
      toolbarExtraButtons: [
        // {
        //   text: 'Finish',
        //   class: 'btn btn-info',
        //   event: () => alert('Finished!!!')
        // },
      ]
    }
  };

  stepDefinitions: StepDefinition[] = [
    {
      title: '',
      description: '',
      component: StepOneComponent,

    },
    {
      title: '',
      description: '',
      component: StepTwoComponent,
      canEnter: this.validateStep.bind(this, 'entry'),
      canExit: this.validateStep.bind(this, 'exit'),
    },
    {
      title: '',
      description: '',
      component: StepThreeComponent,
      canEnter: this.validateStep.bind(this, 'entry'),
      canExit: this.validateStep.bind(this, 'exit'),
    },
    {
      title: '',
      description: '',
      component: StepFourComponent,
      canEnter: this.validateStep.bind(this, 'entry'),
      canExit: this.validateStep.bind(this, 'exit'),
    },
    {
      title: '',
      description: '',
      state: STEP_STATE.normal,
      component: StepFiveComponent,
      canEnter: this.validateStep.bind(this, 'entry'),
      canExit: this.validateStep.bind(this, 'exit'),
    },
    // {
    //     title: '',
    //     description: '',
    //     state: STEP_STATE.hidden,
    //     component: StepSixComponent
    //   },

  ];

  private validateStep(type: string, args: StepValidationArgs) {
    let step = type == 'entry' ? args.toStep : args.fromStep;
    let stepSpecificValidateMethod;

    if (step && step.componentRef) {
      stepSpecificValidateMethod = type == 'entry' ? step.componentRef.instance.validateEntryToStep : step.componentRef.instance.validateExitFromStep;
    }

    if (stepSpecificValidateMethod) {
      if (typeof stepSpecificValidateMethod === typeof true) {
        return <boolean>stepSpecificValidateMethod;
      }
      else if (stepSpecificValidateMethod instanceof Function) {
        stepSpecificValidateMethod = stepSpecificValidateMethod.bind(step.componentRef.instance);
        let result = stepSpecificValidateMethod();

        if (isObservable(result)) {
          return result;
        }
        else if (typeof result === typeof true) {
          return <boolean>result;
        }
      }
    }

    return true;
  }
}

export interface StepDefinition {
  title: string;
  description: string;
  state?: STEP_STATE;
  component: Type<any>;
  canEnter?: boolean | ((args: StepValidationArgs) => boolean) | ((args: StepValidationArgs) => Observable<boolean>);
  canExit?: boolean | ((args: StepValidationArgs) => boolean) | ((args: StepValidationArgs) => Observable<boolean>);
}
