<div class="reg-con">
<div class="header">
  <a class="logo" href="/home">AAAMB</a>
</div>
<section class="signup-page">
  <div class="container-fluid p-0">
    <div class="row g-0 min-height-44">
      <div class="col-xl-12">
        <div class="auth-full-bg">
          <div class="w-100 h-100 left-sec-img">
            <div class="d-flex flex-column">
              <!-- <div class="p-4"> -->
                <div class="row justify-content-center" >
                  <div class="col-lg-6 pt-5">
                    <h1 class="main-title"><b> Sign up now and start your <br/> journey with us!</b></h1>
                    <div>
                        <img src="assets/img/signup-bg.png" width="500"/>
                    </div>
                  </div>
                  <div class="col-lg-6 right-sec">
                    <div class="container">

                        <div class="form-group" *ngIf="this.cartItems.length>0">
                          <ng-wizard [config]="config"  (stepChanged)="stepChanged($event)">
                            <ng-wizard-step  *ngFor="let stepDefinition of stepDefinitions" [title]="stepDefinition.title"
                              [description]="stepDefinition.description" [state]="stepDefinition.state" [component]="stepDefinition.component"
                              [canEnter]="stepDefinition.canEnter" [canExit]="stepDefinition.canExit">
                            </ng-wizard-step>
                          </ng-wizard>
                        </div>
                        <div class="p-5 custom-hdng" *ngIf="this.cartItems.length==0">
                            <h3>You have not chosen any product,<span> please go to pricing page</span></h3>
                            <a href="/pricing" class="btn btn-danger btn-mw">Pricing</a>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            <!-- </div> -->
          </div>
        </div>
      </div>
      <!-- end col -->

      <!-- end col -->
    </div>
    <!-- end row -->
  </div>
</section>
<div class="container mb-20">
  <div class="row">
    <div class="col-xl-6 col-sm-12 col-lg-6 f-txt aambgreen_color">
        Copyright ©2024 AAAMB LLC. All rights reserved.
    </div>
    <div class="col-xl-6 col-sm-12 col-lg-6 f-txt text-end">
        <a href='{{siteURL}}/terms-service' target="_blank" class="aambgreen_color">Terms of Service</a>&nbsp;&nbsp; <a href="{{siteURL}}/privacy-policy" class="aambgreen_color" target="_blank">Privacy Policy</a>
    </div>
  </div>
</div>

</div>
