import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';


@Component({
  selector: 'app-contact-model',
  templateUrl: './contact-model.component.html',
  styleUrls: ['./contact-model.component.scss']
})
export class ContactModelComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ContactModelComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,public router:Router) {}

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close()
    this.router.navigate(["/home"])
  }

}
