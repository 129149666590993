<section class="main-banner">
  <div class="container">
    <div class="row g-0 main-hero">
      <div class="col-xl-6">
        <div class="col-lg-12 justify-content-center">
          <h1 class="main-title pb-0 mb-0"> <span>aaa</span> Medical</h1>
          <h2 class="sub-title">Billing Services</h2>
          <p>At AAA Medical Billing Services, we offer quality and detail oriented end-to-end revenue cycle, practice
            management, contract analysis, medical billing and other services with emphasis on completeness and
            efficiency. Our courteous Medical Billing Service staff have the professional tools and experience necessary
            to help with all your needs. Call today to get in touch with our staff and to learn more about what we have
            to offer.</p>
          <!-- <br/>
            <button class="btn btn-danger">Signup Now</button>
            <a href="#">or signup with your email address</a> -->
        </div>
      </div>
      <div class="col-xl-6 justify-content-center">
        <div class="col-lg-12 justify-content-center">
          <img src="../../../../assets/img/hero-image.png" />
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
  <!-- end container-fluid -->
</section>
<section class="aboutus-sec">

  <div class="container">
    <div class="row">
      <div class="col-3">
        <img src="../../../../assets/img/about-us.png" />
      </div>
      <div class="col-1">&nbsp;</div>
      <div class="col-8">
        <h6>about-us</h6>
        <h2><span>Who</span> we are</h2>
        <p>At AAA Medical Billing Services, we offer quality and detail oriented end-to-end revenue cycle, practice
          management, contract analysis, medical billing and other services with emphasis on completeness and
          efficiency. Our courteous Medical Billing Service staff have the professional tools and experience necessary
          to help with all your needs. Call today to get in touch with our staff and to learn more about what we have to
          offer.</p>
          
            <div class="row mt-5 pt-5">
              <div class="col-4 text-center position-relative">
                <!-- <span>
                
                <svg xmlns="http://www.w3.org/2000/svg" width="200" height="230" fill="currentColor" class="bi bi-clock-fill iconSize" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
                </svg>
              </span> -->
                <!-- <span class="icofont-circled-right iconSize"></span> -->
                <div class="iconDiv">
                  <!-- <span class="icofont-clock-time iconStyle"></span> -->
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="currentColor" class="iconStyle" version="1.1" id="Capa_1" width="75px" height="75px" viewBox="0 0 465.2 465.2" xml:space="preserve">
                    <g>
                      <g id="Layer_2_16_">
                        <g>
                          <path d="M279.591,423.714c-3.836,0.956-7.747,1.805-11.629,2.52c-10.148,1.887-16.857,11.647-14.98,21.804     c0.927,4.997,3.765,9.159,7.618,11.876c3.971,2.795,9.025,4.057,14.175,3.099c4.623-0.858,9.282-1.867,13.854-3.008     c10.021-2.494,16.126-12.646,13.626-22.662C299.761,427.318,289.618,421.218,279.591,423.714z"/>
                          <path d="M417.887,173.047c1.31,3.948,3.811,7.171,6.97,9.398c4.684,3.299,10.813,4.409,16.662,2.475     c9.806-3.256,15.119-13.83,11.875-23.631c-1.478-4.468-3.118-8.95-4.865-13.314c-3.836-9.59-14.714-14.259-24.309-10.423     c-9.585,3.834-14.256,14.715-10.417,24.308C415.271,165.528,416.646,169.293,417.887,173.047z"/>
                          <path d="M340.36,397.013c-3.299,2.178-6.704,4.286-10.134,6.261c-8.949,5.162-12.014,16.601-6.854,25.546     c1.401,2.433,3.267,4.422,5.416,5.942c5.769,4.059,13.604,4.667,20.127,0.909c4.078-2.352,8.133-4.854,12.062-7.452     c8.614-5.691,10.985-17.294,5.291-25.912C360.575,393.686,348.977,391.318,340.36,397.013z"/>
                          <path d="M465.022,225.279c-0.407-10.322-9.101-18.356-19.426-17.953c-10.312,0.407-18.352,9.104-17.947,19.422     c0.155,3.945,0.195,7.949,0.104,11.89c-0.145,6.473,3.021,12.243,7.941,15.711c2.931,2.064,6.488,3.313,10.345,3.401     c10.322,0.229,18.876-7.958,19.105-18.285C465.247,234.756,465.208,229.985,465.022,225.279z"/>
                          <path d="M414.835,347.816c-8.277-6.21-19.987-4.524-26.186,3.738c-2.374,3.164-4.874,6.289-7.434,9.298     c-6.69,7.86-5.745,19.666,2.115,26.361c0.448,0.38,0.901,0.729,1.371,1.057c7.814,5.509,18.674,4.243,24.992-3.171     c3.057-3.59,6.037-7.323,8.874-11.102C424.767,365.735,423.089,354.017,414.835,347.816z"/>
                          <path d="M442.325,280.213c-9.855-3.09-20.35,2.396-23.438,12.251c-1.182,3.765-2.492,7.548-3.906,11.253     c-3.105,8.156-0.13,17.13,6.69,21.939c1.251,0.879,2.629,1.624,4.126,2.19c9.649,3.682,20.454-1.159,24.132-10.812     c1.679-4.405,3.237-8.906,4.646-13.382C457.66,293.795,452.178,283.303,442.325,280.213z"/>
                          <path d="M197.999,426.402c-16.72-3.002-32.759-8.114-47.968-15.244c-0.18-0.094-0.341-0.201-0.53-0.287     c-3.584-1.687-7.162-3.494-10.63-5.382c-0.012-0.014-0.034-0.023-0.053-0.031c-6.363-3.504-12.573-7.381-18.606-11.628     C32.24,331.86,11.088,209.872,73.062,121.901c13.476-19.122,29.784-35.075,47.965-47.719c0.224-0.156,0.448-0.311,0.67-0.468     c64.067-44.144,151.06-47.119,219.089-1.757l-14.611,21.111c-4.062,5.876-1.563,10.158,5.548,9.518l63.467-5.682     c7.12-0.64,11.378-6.799,9.463-13.675L387.61,21.823c-1.908-6.884-6.793-7.708-10.859-1.833l-14.645,21.161     C312.182,7.638,252.303-5.141,192.87,5.165c-5.986,1.036-11.888,2.304-17.709,3.78c-0.045,0.008-0.081,0.013-0.117,0.021     c-0.225,0.055-0.453,0.128-0.672,0.189C123.122,22.316,78.407,52.207,46.5,94.855c-0.269,0.319-0.546,0.631-0.8,0.978     c-1.061,1.429-2.114,2.891-3.145,4.353c-1.686,2.396-3.348,4.852-4.938,7.308c-0.199,0.296-0.351,0.597-0.525,0.896     C10.762,149.191-1.938,196.361,0.24,244.383c0.005,0.158-0.004,0.317,0,0.479c0.211,4.691,0.583,9.447,1.088,14.129     c0.027,0.302,0.094,0.588,0.145,0.89c0.522,4.708,1.177,9.427,1.998,14.145c8.344,48.138,31.052,91.455,65.079,125.16     c0.079,0.079,0.161,0.165,0.241,0.247c0.028,0.031,0.059,0.047,0.086,0.076c9.142,9.017,19.086,17.357,29.793,24.898     c28.02,19.744,59.221,32.795,92.729,38.808c10.167,1.827,19.879-4.941,21.703-15.103     C214.925,437.943,208.163,428.223,197.999,426.402z"/>
                          <path d="M221.124,83.198c-8.363,0-15.137,6.78-15.137,15.131v150.747l137.87,71.271c2.219,1.149,4.595,1.69,6.933,1.69     c5.476,0,10.765-2.982,13.454-8.185c3.835-7.426,0.933-16.549-6.493-20.384l-121.507-62.818V98.329     C236.243,89.978,229.477,83.198,221.124,83.198z"/>
                        </g>
                      </g>
                    </g>
                    </svg>
                </div>
               <p class="iconText text-dark">Avoid time delays and process claims 2X faster than national average</p>
              </div>
              <div class="col-4 text-center position-relative">
                <!-- <span class="icofont-circled-check iconSize"></span> -->
                <div class="iconDiv bg-red">
                  <!-- <span class="icofont-clock-time iconStyle"></span> -->
                  <svg xmlns="http://www.w3.org/2000/svg" width="75px" height="75px" viewBox="0 0 24 24" fill="#c12228">
                    <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M7.75 12L10.58 14.83L16.25 9.17004" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
        
        
                </div>
                <p class="iconText text-dark">Optimize Revenue and reduce billing expenses by 50%</p>
              </div>
              <div class="col-4 text-center position-relative">
                <!-- <span class="icofont-circled-right iconSize"></span> -->
                <div class="iconDiv">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" height="75px" width="75px" class="iconStyle" version="1.1" id="Capa_1" viewBox="0 0 462.345 462.345" xml:space="preserve">
                    <g >
                      <path style="fill:#ffffff;" stroke-width="10" stroke="#ffffff"    d="M235.696,81.72c0-4.487-3.633-8.129-8.129-8.129c-46.926,0-85.106,38.18-85.106,85.106   c0,4.487,3.633,8.129,8.129,8.129c4.495,0,8.129-3.642,8.129-8.129c0-37.96,30.889-68.849,68.849-68.849   C232.063,89.848,235.696,86.215,235.696,81.72z" />
                      <path style="fill:#ffffff;" stroke-width="10" stroke="#ffffff"   d="M108.305,158.697c0,27.897,9.015,54.274,26.076,76.279c0.081,0.106,0.171,0.211,0.26,0.317   c35.579,49.389,26.735,72.881,26.735,72.921c-1.902,4.064-0.146,8.901,3.918,10.811c1.114,0.52,2.284,0.772,3.438,0.764   c3.056,0,5.983-1.731,7.364-4.682c1.471-3.121,13.095-32.214-28.694-89.91c-0.187-0.252-0.374-0.496-0.585-0.723   c-14.558-19.021-22.248-41.748-22.248-65.785c0-59.729,48.601-108.321,108.321-108.321S341.213,98.96,341.213,158.689   c0,24.101-7.738,46.918-22.524,66.15c-41.813,57.737-29.718,87.163-28.206,90.333c1.91,3.975,6.6,5.601,10.616,3.804   s5.836-6.576,4.137-10.64c-0.098-0.236-9.413-24.199,26.483-73.775c16.842-21.915,25.751-48.146,25.751-75.864   c0-68.695-55.884-124.579-124.579-124.579S108.305,90.011,108.305,158.697z"/>
                      <path style="fill:#ffffff;" stroke-width="10" stroke="#ffffff"   d="M305.439,351.084v-6.909c0-11.014-8.966-19.972-19.972-19.972H175.927   c-11.014,0-19.972,8.958-19.972,19.972v6.909c0,11.006,8.958,19.972,19.972,19.972h109.549   C296.482,371.056,305.439,362.098,305.439,351.084z M172.212,351.084v-6.909c0-2.048,1.666-3.715,3.715-3.715h109.549   c2.048,0,3.715,1.666,3.715,3.715v6.909c0,2.048-1.666,3.715-3.715,3.715H175.927C173.878,354.799,172.212,353.133,172.212,351.084   z"/>
                      <path style="fill:#ffffff;" stroke-width="10" stroke="#ffffff"   d="M189.087,383.412c-4.495,0-8.129,3.642-8.129,8.129v21.468c0,3.186,1.861,6.072,4.755,7.397   l43.805,19.988c1.073,0.488,2.219,0.732,3.373,0.732c1.187,0,2.374-0.26,3.471-0.78l43.927-20.728   c2.845-1.341,4.658-4.202,4.658-7.348v-20.72c0-4.487-3.633-8.129-8.129-8.129s-8.129,3.642-8.129,8.129v15.574l-35.855,16.924   l-35.619-16.257v-16.241C197.215,387.045,193.582,383.412,189.087,383.412z"/>
                      <path style="fill:#ffffff;"stroke-width="10"stroke="#ffffff"    d="M422.263,305.581c2.666-3.609,1.894-8.698-1.715-11.364l-55.144-40.659   c-3.617-2.666-8.698-1.902-11.364,1.715c-2.666,3.609-1.894,8.698,1.715,11.364l55.144,40.659c1.455,1.073,3.146,1.585,4.82,1.585   C418.207,308.889,420.67,307.743,422.263,305.581z"/>
                      <path style="fill:#ffffff;"stroke-width="10" stroke="#ffffff"   d="M99.16,77.468c1.455,1.073,3.146,1.585,4.82,1.585c2.495,0,4.95-1.146,6.552-3.308   c2.666-3.609,1.894-8.698-1.715-11.364l-56.388-41.57c-3.609-2.674-8.698-1.894-11.364,1.715   c-2.666,3.617-1.902,8.706,1.715,11.372L99.16,77.468z"/>
                      <path style="fill:#ffffff;"stroke-width="10"stroke="#ffffff"    d="M101.721,250.761l-58.932,43.455c-3.609,2.666-4.381,7.755-1.715,11.364   c1.593,2.162,4.056,3.308,6.552,3.308c1.674,0,3.365-0.512,4.82-1.585l58.932-43.455c3.609-2.666,4.381-7.755,1.715-11.364   C110.41,248.867,105.33,248.103,101.721,250.761z"/>
                      <path style="fill:#ffffff;"stroke-width="10" stroke="#ffffff"   d="M358.161,61.699c-3.609,2.666-4.381,7.755-1.715,11.364c1.593,2.162,4.056,3.308,6.552,3.308   c1.674,0,3.365-0.52,4.82-1.585l52.746-38.887c3.609-2.666,4.381-7.755,1.715-11.364c-2.666-3.609-7.747-4.389-11.364-1.715   L358.161,61.699z"/>
                      <path style="fill:#ffffff;" stroke-width="10"stroke="#ffffff"   d="M454.217,156.933h-62.175c-4.495,0-8.129,3.642-8.129,8.129s3.633,8.129,8.129,8.129h62.175   c4.495,0,8.129-3.642,8.129-8.129S458.712,156.933,454.217,156.933z"/>
                      <path style="fill:#ffffff;"stroke-width="10" stroke="#ffffff"   d="M80.416,165.062c0-4.487-3.633-8.129-8.129-8.129H8.129c-4.495,0-8.129,3.642-8.129,8.129   s3.633,8.129,8.129,8.129h64.159C76.774,173.19,80.416,169.549,80.416,165.062z"/>
                    </g>
                    </svg>
                </div>
                <p class="iconText text-dark">Optimize resources and save time by 1,200 minutes weekly</p>
              </div>
        
            </div>
        
      </div>
    </div>
  </div>

  <div class="container pb-home">
    <div class="row">
      <div class="col-8">
        <h6>Our-Services</h6>
        <h2><span>What</span> we do</h2>
        <p>AAA Medical Billing Services provides a professional, expert medical billing, collection and administrative
          services for medical practitioners. Whether you require a complete turnkey billing service from start to
          finish or just practice analysis, our experienced medical billing and collection team is sure to impress you.
        </p>

        <p> Not all billing services are apt for all customers. We would like to assist you in any way possible, so our
          first step is always to determine what type of services you need and what is important to you. Then we can
          decide what solutions are best for you.</p>
      </div>
      <div class="col-1">&nbsp;</div>
      <div class="col-3">
        <img src="../../../../assets/img/hexagon-bg.jpeg" />
      </div>

    </div>
  </div>

  <div class="container pb-home ">
    <div class="row align-items">
      <div class="col-1"></div>
      <div class="col-4">
        <img src="../../../../assets/img/medical-practice.png" />
      </div>
      <div class="col-6">
        <h3>Practice Management</h3>
        <p>A business check-up is imminent for any business. Whether your business is new or well established, knowing if it is in <q>top condition</q> is imperative.
      </p>
        <button class="btn btn-danger">Learn More</button>
      </div>



    </div>
  </div>

  <!---->
  <div class="container pb-home-2">
    <div class="row align-items">
      <div class="col-1"></div>
      <div class="col-6">
        <h3>Revenue Cycle Management</h3>
        <p>
          Revenue cycle management (RCM) is a lifeline for any practice. It is a concept that covers all facets of medical billing. Every practice should know about the importance of RCM and its solutions.
        </p>
        <button class="btn btn-danger">Learn More</button>
      </div>
      <div class="col-4">
        <img src="../../../../assets/img/medical-practice.png" />
      </div>
      <div class="col-2">&nbsp;</div>
    </div>
  </div>
  <!---->
  <div class="container pb-home-1">
    <div class="row align-items">
      <div class="col-1"></div>
      <div class="col-4">
        <img src="../../../../assets/img/medical-practice.png" />
      </div>
      <div class="col-6">
        <h3>Medical Billing</h3>
        <p>
          With increasing demands on providers and decreasing reimbursements, healthcare offices need highly skilled medical billers.

        </p>
        <button class="btn btn-danger">Learn More</button>
      </div>
      <div class="col-2">&nbsp;</div>
    </div>
  </div>
  <!---->
  <div class="container pb-home-2">
    <div class="row align-items">
      <div class="col-1"></div>
      <div class="col-6">
        <h3>Information Technology</h3>
        <p>
          IT solutions should complement the business needs and not become hindrances to the process.
        </p>
        <button class="btn btn-danger">Learn More</button>
      </div>
      <div class="col-4">
        <img src="../../../../assets/img/medical-practice.png" />
      </div>
      <div class="col-2">&nbsp;</div>
    </div>
  </div>
  <!---->
  <div class="container pb-home">
    <div class="row">
      <div class="col-6">
        <h6>Why choose us?</h6>
        <h2><span>See</span> Why we are trusted</h2>
        <p>AAA Medical Billing Services provides a professional, expert medical billing, collection and administrative
          services for medical practitioners. Whether you require a complete turnkey billing service from start to
          finish or just practice analysis, our experienced medical billing and collection team is sure to impress you.
        </p>

        <p> Not all billing services are apt for all customers. We would like to assist you in any way possible, so our
          first step is always to determine what type of services you need and what is important to you. Then we can
          decide what solutions are best for you.</p>
      </div>
      <div class="col-6 ">
        <!-- <div class="card cardDimentions">
          300K
          <p class="cardSocial">Social Followers</p>
        </div> -->
      <div class="row">
        <div class="col-lg-6 col-md-6 marginTop-100">
          <div class="cardNumber hasBorder mt-50">
            <div class="card-head" #testDiv>250+</div>
            <div class="card-description">Employees</div>
          </div>
          <div class="cardNumber bg-brand-2">
            <div class="card-head">98%</div>
            <div class="card-description">Clean Claim Rate</div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 margin-65">
          <div class="cardNumber bg-2">
            <div class="card-head">300+</div>
            <div class="card-description">Clients</div>
          </div>
          <div class="cardNumber">
            <div class="card-head">30+</div>
            <div class="card-description">Specialties Served</div>
          </div>
        </div>
      </div>
      </div>

    </div>
  </div>
  <!---->

<!--  -->


</section>



<section class="viewpricing">
  <div class="container">
    <div class="row">
      <div class="col-xl-8 m-auto">
        <h3>Join millions of happy users</h3>
        <h2>Now is the time to subscribe <br> for hassle-free service.</h2>
        <a class="btn btn-white" (click)="redirectToPricing()">View Pricing</a>
      </div>
    </div>
  </div>
</section>

<section class="grow-testimonials">
  <div id="testimonial" class="container testimonials">
    <div class="row">
      <div class="col-6 m-auto">
        <h6>TRUSTED BY</h6>
        <h3><span>Words</span> From Clients</h3>
      </div>
    </div>
    <div class="row">
      <!--testimonials-->
      <div class="container">
        <div class="row">
          <div class="col-xl-8 m-auto">
            <div class="testimonial-slides owl-carousel owl-theme">
              <div class="testimonial-item">
                <div class="client-image">
                  <img src="assets/img/think-avatar.png" alt="image">
                </div>
                <div class="testimonial-content">
                  <div class="desc">
                    <p>I highly recommend AAA Medical Billing Services. All of the staff members are extremely
                      professional, have excellent communication skills, and provide great service. I wouldn't choose
                      any other Medical Billing Service.</p>
                  </div>
                  <div class="client-info">
                    <h3>Jennifer CTO</h3>
                    <span>THNIK PRICING</span>
                  </div>
                </div>
              </div>
              <div class="testimonial-item">
                <div class="client-image">
                  <img src="assets/img/think-avatar.png" alt="image">
                </div>
                <div class="testimonial-content">
                  <div class="desc">
                    <p>I highly recommend AAA Medical Billing Services. All of the staff members are extremely
                      professional, have excellent communication skills, and provide great service. I wouldn't choose
                      any other Medical Billing Service.</p>
                  </div>
                  <div class="client-info">
                    <h3>Jennifer CTO</h3>
                    <span>THNIK PRICING</span>
                  </div>
                </div>
              </div>
              <div class="testimonial-item">
                <div class="client-image">
                  <img src="assets/img/think-avatar.png" alt="image">
                </div>
                <div class="testimonial-content">
                  <div class="desc">
                    <p>I highly recommend AAA Medical Billing Services. All of the staff members are extremely
                      professional, have excellent communication skills, and provide great service. I wouldn't choose
                      any other Medical Billing Service.</p>
                  </div>
                  <div class="client-info">
                    <h3>
                      <b>Jennifer CTO</b>
                    </h3>
                    <span>THINK PRICING</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--testimonials-->
    </div>
  </div>
</section>
<section class="clients">
  <div class="container">
    <div class="row">
      <div class="col-xl-12 m-auto">
        <h6>TRUSTED BY</h6>
        <h3><span>Awesome</span> Clients</h3>
        <img src="../../../../assets/img/Logos.png" />
      </div>
    </div>
  </div>
</section>
