import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { Products } from "src/app/shared/models/products";
import { CartService } from "src/app/shared/services/cart/cart.service";
import { CustomerService } from "src/app/shared/services/customers/customers.service";
import { ProductsService } from "src/app/shared/services/products/products.service";
import { APP_CONSTANTS } from "src/app/shared/utils/appconstants";

@Component({
    selector: "app-pricing",
    templateUrl: "./pricing.component.html",
    styleUrls: ["./pricing.component.scss"],
})
export class PricingComponent implements OnInit {
    productsList!: Products[];
    productSubscribed!: any[];
    isChecked = false;
    productsChecked: any;
    constructor(
        private productsService: ProductsService,
        private cartService: CartService,
        private router: Router,
        private cookieService: CookieService,
        private customerService: CustomerService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.isChecked = false;
        this.cartService.cartItemList = [];
        const _token = JSON.parse(
            this.cookieService.get(APP_CONSTANTS.cookiekey) || "{}"
        );
        //console.log("pricing--------------cookie" + JSON.stringify(_token));
        if (_token.length > 0) {
            this.GetCustomerOrders(_token.data.id);
        }
        this.getProducts();
    }
    GetCustomerOrders(userID) {
        this.productSubscribed = [];
        this.customerService.getOrderByCustomerId(userID).then((data: any) => {
            if (data && data[0].items.length > 0) {
                this.productSubscribed.push(data[0].items);

              //  console.log("product data---------" + data[0].items);
            }
        });
    }
    getProducts() {
        try
        {
            this.productsService.getProductsByCategory().then((res) => {
                //console.log(JSON.stringify(res));
                this.productsList = res.filter((p) => p.available);
                if(this.productsList && this.productsList.length > 0){
                    this.productsList.forEach((product:any) => {
                        console.log(product);
                      this.productsChecked = {[product._id]:  false};  
                    });
                }
                console.log(this.productsChecked);
            });
        }
        catch(e){

        }

    }
    singleClass = false;
    selectCartItems = "";
    selectedCartItems = "";
    itemsselected = false;
    isItemSelected = false;
    itemsCount = 0;
    onCheckboxChange(item) {
        this.productsChecked[item._id] = !this.productsChecked[item._id]
        //console.log('checked')
        this.singleClass = true;
        // console.log(JSON.stringify(item));
        if (this.productSubscribed && this.productSubscribed.length > 0) {
            for (let i = 0; i < this.productSubscribed.length; i++) {
                if (this.productSubscribed[i] == item._id) {
                    // console.log("already subscribed-----------"+JSON.stringify(this.productSubscribed[i]));
                    //e.target.checked = false;
                    this.itemsselected = true;
                    this.selectCartItems = "Already subscribed to this product";
                    this.cdr.detectChanges();
                    return;
                }
            }
        }
        this.itemsselected = false;
        this.isItemSelected = false;
        var concatenateStr = " + ";
        this.selectedCartItems = "";
        // this.cartService.addToCart(item);

        //    const occ =[...this.selectedCartItems].filter(l => l === '+').length

        //    if(occ==1 || occ==2)
        //    {
        //     const txt =  this.selectedCartItems.substring(0, this.selectedCartItems.length-2);
        //     this.selectedCartItems = txt;
        //    }

        // this.itemsselected =true;

        if (this.productsChecked[item._id]) {
            this.itemsCount += 1;
            this.isChecked = true;
            //console.log("checked" + JSON.stringify(item));
            this.cartService.addToCart(item);
          //  console.log(JSON.stringify(this.cartService.cartItemList));
        } else {
            this.itemsCount -= 1;
            this.isChecked = false;
            //console.log("unchecked");
            this.cartService.removeFromCart(item);
            //console.log(JSON.stringify(this.cartService.cartItemList));
        }
        this.selectedCartItems = "";
        this.cartService.cartItemList.forEach((element) => {
            this.isItemSelected = true;
            if (this.itemsCount > 1) {
                this.selectedCartItems =
                    this.selectedCartItems + " + " + element.name;
              //  console.log("---------" + this.selectedCartItems);
            } else {
                this.selectedCartItems = this.selectedCartItems + element.name;
            }
        });
        let selItems = this.selectedCartItems.trim();
        //console.log( "this.selectedCartItems.charAt(0)" + this.selectedCartItems.charAt(1) );
        if (selItems.charAt(0) === "+") {
            this.selectedCartItems = selItems.slice(1);

        }
        this.cdr.detectChanges();
        // this.selectedCartItems = this.selectedCartItems + item.name;
        // if(this.selectCartItems.length > 0)
        // {
        //     this.selectCartItems = this.selectCartItems + "' + '" + item.name;
        // }
    }
    processCart() {
        this.itemsselected = false;
        this.selectCartItems = "";
       // console.log(this.cartService.cartItemList.length);
        if (this.cartService.cartItemList.length == 0) {
            this.itemsselected = true;
            this.selectCartItems = "In order to proceed, kindly subscribe to a product.";
            this.cdr.detectChanges();
            return;
        } else {
            this.cookieService.delete("aaamb_cart","/");
            this.cookieService.set("aaamb_cart",JSON.stringify(this.cartService.cartItemList));
            //console.log("-------------------" + JSON.stringify(this.cartService.cartItemList));
            this.itemsselected = false;
            this.selectCartItems = "";
            this.router.navigate(["/signup"]);
        }
    }
}
