import { Component, Directive, OnInit } from "@angular/core";
import { flush } from "@angular/core/testing";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ContactModelComponent } from "../../../contact-model/contact-model.component";

@Component({
    selector: "app-contact",
    templateUrl: "./contact.component.html",
    styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {
    contactUsForm: any;
    isSubmit = false;
    displayStyle = "none";

    constructor(
        private formBuilder: UntypedFormBuilder,
        public dialog: MatDialog
    ) {}

    ngOnInit() {
        this.contactUsForm = this.formBuilder.group({
            firstName: [
                "",
                [Validators.required, Validators.pattern(/^[A-z0-9_ ]*$/)]
            ],
            lastName: [
                "" ,
                [Validators.required, Validators.pattern(/^[A-z0-9_ ]*$/)] 
            ],
            practiceName: [""],
            phoneNumber: ["",[Validators.required]],
            email: [
                null,
                Validators.compose([
                    Validators.required,
                    Validators.pattern(
                        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
                    ),
                ]),
            ],
            services: [''],
            products:[''],
            others:['']
        });
    }

    onSubmit() {
        this.isSubmit = true;
        if (this.contactUsForm.valid) {
            const dialogRef = this.dialog.open(ContactModelComponent);
            dialogRef.afterClosed().subscribe((result) => {
                this.contactUsForm.reset();
                this.isSubmit = false;
            });
        }
    }

    numberOnly(event): boolean {
        const charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }
}
