import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  NgWizardConfig,
  NgWizardService,
  StepChangedArgs,
  STEP_STATE,
  THEME
} from 'ng-wizard';
import { MainUser } from 'src/app/shared/models/mainuser';
import { CustomerAddress } from 'src/app/shared/models/address';
import { ItemSchema, SubscriptionOrder } from 'src/app/shared/models/order';
import { CartService } from 'src/app/shared/services/cart/cart.service';
import { CustomerService } from 'src/app/shared/services/customers/customers.service';
import { DemoWizardService, StepDefinition } from '../../../../shared/services/demo-wizard.service';
import { User } from 'src/app/shared/models/user';
import { DataService } from 'src/app/shared/services/data.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],

})
export class SignupComponent implements OnInit {

  public siteURL = environment.siteURL;
  user!: User;
  address!: CustomerAddress;
  subscriptionOrder!: SubscriptionOrder;
  cartItems = [];
  signupForm: UntypedFormGroup;
  otpForm: UntypedFormGroup;
  paymentForm: UntypedFormGroup;
  addressForm: UntypedFormGroup;
  successForm: UntypedFormGroup;
  reviewForm: UntypedFormGroup;
  submitted = false;
  isValid = false;
  error = '';
  successmsg = false;
  grandTotal : string ='';
  paymentHandler: any = null;
  userState = '';
  userCountry = '';
  otp = '';

  userType = [];
  item: ItemSchema;
  orderItems : ItemSchema[];

  config: NgWizardConfig;
  stepDefinitions: StepDefinition[];

  stepStates = { normal: STEP_STATE.normal, disabled: STEP_STATE.disabled, error: STEP_STATE.error, hidden: STEP_STATE.hidden };
  themes = [THEME.default, THEME.arrows, THEME.circles, THEME.dots];
  stepIndexes = [0, 1, 2, 3, 4,5];

  selectedTheme: THEME;
  selectedStepIndex: number;

  constructor(private formBuilder: UntypedFormBuilder,
    private dataService: DataService,
    private route: ActivatedRoute,
    private router: Router,
    private cartService: CartService,
    private userService: CustomerService,
    private cookieService: CookieService,
    private demoWizardService: DemoWizardService,
    private ngWizardService: NgWizardService) {
    this.config = demoWizardService.config;
    this.stepDefinitions = demoWizardService.stepDefinitions;
    this.config.toolbarSettings.showNextButton = false;
    this.config.toolbarSettings.showPreviousButton = false;

    // this.config.toolbarSettings.toolbarExtraButtons.push(
    //   {
    //     text: 'Reset',
    //     class: 'btn btn-danger',
    //     event: this.resetWizard.bind(this)
    //   }
    // );
    this.signupForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      phone: ['', Validators.required],
    });
    this.otpForm = this.formBuilder.group({
      otp: ['', Validators.required],
    });
    this.addressForm = this.formBuilder.group({
      companyName: ['', Validators.required],
      companyPhone: ['', Validators.required],
      companyAddress: ['', [Validators.required]],
      companyCity: ['', Validators.required],
      zipCode: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.grandTotal = '';
    this.selectedTheme = this.config.theme;
    this.selectedStepIndex = this.config.selected;

    this.ngWizardService.stepChanged()
      .subscribe({
        next: (args) => {
         // console.log('catching step change - method 2');
        }
      });
      if(this.cartService.cartItemList.length>0)
      {
        this.cartItems.push(this.cartService.cartItemList);
      }
      else
      {
        if(this.cookieService.get("aaamb_cart")){
            this.cartItems.push(this.cookieService.get("aaamb_cart"));
            this.cartService.cartItemList = (JSON.parse(this.cartItems[0]));

        }
      }
    console.log('----wizrd' +JSON.stringify( this.cartService.cartItemList));

    //.log('----cartitems----------' + JSON.stringify(this.cartItems));
    if(this.cartItems.length>0){
       // console.log("this.grandTotal---------------"+this.grandTotal);
        this.grandTotal = this.cartService.getTotalPrice();
    }else
    {
        this.router.navigateByUrl('/pricing');
    }


    this.GetUserTypes()
  }
  GetUserTypes() {
    this.userService.GetUserType().then(res => {
      //  console.log(res);
      this.userType = res;
      const admin = this.userType.filter((citem) => citem.name == 'Admin');
      //console.log('admin-----'+JSON.stringify(admin));
    });
  }
  done() {

  }
  onSubmit() {
    this.submitted = true;
    if (this.signupForm.invalid) {
      return;
    }
    this.user = {
      _id: '',
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      phone: '',
      otp: '',
      usertype: '',
      practice: "",
      createdby: '',
      createdon: new Date(),
      modifiedby: '',
      modifiedon: new Date()
    }
    const admin = this.userType.filter((citem) => citem.name == 'Admin');

    this.user.firstname = this.signupForm.controls.firstName.value;
    this.user.lastname = this.signupForm.controls.lastName.value;
    this.user.email = this.signupForm.controls.email.value;
    this.user.password = this.signupForm.controls.password.value;
    this.user.phone = this.signupForm.controls.phone.value;
    this.user.usertype = admin[0]._id;
    this.user.createdby = "1";
    this.user.createdon = new Date();
    this.user.modifiedby = "1";
    this.user.modifiedon = new Date();
    //console.log('submut----------------------' + JSON.stringify(this.user));
    this.GetOtp();
  }
  onSubmitReview() {

  }
  GetOtp() {
    this.userService.GetOtp(this.user.firstname + " " + this.user.lastname, this.user.email).then(res => {
      //console.log(JSON.stringify(res));
      this.otp = res.data;
      // this.user.otp = this.otp;
    });
  }
  invalidotp = false;
  onSubmitOTP() {
    this.isValid = true;
    if (this.otpForm.invalid) {
      this.isValid = false;

      return;
    }
    //console.log(this.otpForm.controls.otp.value)
    if (this.otp !== this.otpForm.controls.otp.value) {
      this.invalidotp = true;
      return;
    }


  }
  onSubmitBilling() {
   // console.log('address form');
    // if(this.addressForm.invalid)
    // {
    //     return;
    // }
    let dt = new Date();
    dt.setMonth(dt.getMonth() + 1);
    let cItems = [];

    //console.log('cart items array' + JSON.stringify(cItems));

    this.item = {
        product: '',
        amount: '',
        txnId:'',
        subscriptionDate: new Date(),
        renewalDate: dt,
        subscriptionType: 'Monthly',
        autorenewal: false,
        createdby: "",
        createdon: new Date(),
        modifiedby: "",
        modifiedon: new Date(),
        status: ''
    }
    this.subscriptionOrder = {
      customerId: '',
      items: [],
    }
    this.address = {
        customerId: '',
        companyname: '',
        address1: '',
        address2: '',
        phone: '',
        postalCode: '',
        city: '',
        state: '',
        country: ''
    }
    if (this.cartService.cartItemList.length > 0) {
        for (let i = 0; i < this.cartService.cartItemList.length; i++) {
            this.item.amount = this.cartService.cartItemList[i].amount;
            this.item.product = this.cartService.cartItemList[i]._id;

          cItems.push(this.cartService.cartItemList[i]._id);
        }
      }
    this.address.companyname = this.addressForm.controls.companyName.value;
    this.address.phone = this.addressForm.controls.companyPhone.value;
    this.address.address1 = this.addressForm.controls.address1.value;
    this.address.address2 = this.addressForm.controls.address2.value;
    this.address.city = this.addressForm.controls.companyCity.value;
    this.address.state = "1";
    this.address.country = "1";
    this.address.postalCode = this.addressForm.controls.zipCode.value;
    //console.log('submut----------------------' + JSON.stringify(this.user));

    //console.log(this.address);
    this.userService.AddUser(this.user).then(res => {
     // console.log(JSON.stringify(res));
      this.subscriptionOrder.customerId = res.id;
      this.subscriptionOrder.items = cItems;
      //this.subscriptionOrder.amount = this.grandTotal;
     // console.log('subscription data----------------------' + JSON.stringify(this.subscriptionOrder));
      this.userService.AddOrder(this.subscriptionOrder).then(data => {
        //console.log(JSON.stringify(data));

        this.userService.AddAddress(this.address).then(data => {
         // console.log(JSON.stringify(data));
        })


      })


    });

  }
  onStateChange(e) {


  }
  onSubmitPayment() {


  }
  onSubmitSuccess() {
    this.router.navigateByUrl('/login');
  }
  // convenience getter for easy access to form fields
  get f() { return this.signupForm.controls; }
  get g() { return this.otpForm.controls; }

  removeItem(item: any) {
    //this.cartService.removeCartItem(item);
  }
  emptyCart() {
    //   this.cartService.removeAllCart();
  }

  //ng-wizrd
  stepChanged(args: StepChangedArgs) {
    this.selectedStepIndex = args.step.index;
   // console.log('catching step change - method' +this.selectedStepIndex);
    if(this.selectedStepIndex ==1){
       this.dataService.sendClickEvent('otp');
    }
    if(this.selectedStepIndex ==2){
        this.dataService.sendClickEvent('cart');
     }
    if(this.selectedStepIndex ==3){
        this.dataService.sendClickEvent('email');
     }
     if(this.selectedStepIndex ==4){
        this.dataService.sendClickEvent('paypal');
     }
  }

  showPreviousStep(event?: Event) {
    this.ngWizardService.previous();
  }

  showNextStep(event?: Event) {
    this.ngWizardService.next();
  }

  resetWizard(event?: Event) {
    this.selectedTheme = this.config.theme;
    this.selectedStepIndex = this.config.selected;

    this.ngWizardService.reset();
  }

  themeSelected() {
    this.ngWizardService.theme(this.selectedTheme);
  }

  stepIndexSelected() {
    this.ngWizardService.show(this.selectedStepIndex);
  }

}


