<!-- <div class="sidebar" [ngClass]="{ 'expanded': isExpanded }"> -->
<div id="admin-sidebar" class="sidebar expanded">
  <div class="header">
    <h1 class="title">
      <!-- <div *ngIf="!logo">
                {{practice}}
            </div> -->
      <div>
        <img src="assets/img/aaamb-logo-new.png" width="150" alt="logo">
        <!-- <img  [src]="imgUrl" [alt]="practice" style="width: 150 !important;height: 100 !important; padding-bottom: 10px;"> -->
      </div>
    </h1>
    <!-- <div class="toggle" (click)="handleSidebarToggle()">
            <a href="javascript:void()">
           <i class="fa fa-bars"></i></a>
          </div>  -->
  </div>
  <div class="content m-t-15">
    <div class="link">
      <div class="content" [ngClass]="getState('/dashboard')? 'active' : 'in'" [routerLink]="['/dashboard']"
        [routerLinkActive]="'active'">
        <div class="icon">
          <i class="fa fa-th-large" aria-hidden="true"></i>
        </div>
        <div class="title">Dashboard</div>
      </div>
    </div>
    <div class="link with-children">
      <div class="content" [ngClass]="getState('/myproducts')? 'active' : 'in'" [routerLink]="['/myproducts']"
        [routerLinkActive]="'active'">
        <div class="icon">
          <i class="fa fa-folder-o" aria-hidden="true"></i>
        </div>
        <div class="title">My Products</div>
      </div>

    </div>
    <div class="link">
      <div class="content" [ngClass]="getState('/subscriptions')? 'active' : 'in'" [routerLink]="['/subscriptions']"
        [routerLinkActive]="'active'">
        <div class="icon">
          <i class="fa fa-calendar-check-o" aria-hidden="true"></i>
        </div>
        <div class="title">Subscriptions</div>
      </div>
    </div>
    <!-- <div class="link">
            <div class="content" [routerLink]="['/users']" [routerLinkActive]="'active'">
              <div class="icon">
               <i class="fa  fa-users"></i>
              </div>
              <div class="title">Users & Roles</div>
            </div>
          </div>
          <div class="link">
            <div class="content" [routerLink]="['/reports']" [routerLinkActive]="'active'">
              <div class="icon">
                <i class="fa fa-area-chart" aria-hidden="true"></i>
              </div>
              <div class="title">Reports</div>
            </div>
          </div> -->

    <div class="link">
      <div class="content" [ngClass]="getState('/settings') ? 'active' : 'in'" [routerLink]="['/settings']"
        [routerLinkActive]="'active'">
        <div class="icon">
          <i class="fa fa-cog" aria-hidden="true"></i>
        </div>
        <div class="title">Settings</div>
      </div>
    </div>
  </div>
  <div class="side-footer"><img src="{{siteURL}}/assets/img/aaamb-logo-new.png"><small>
      Copyright ©2024 AAAMB LLC. All rights
      reserved.
    </small>
    <div class="clearfix">&nbsp;</div>
    <div class="d-flex">
      <small><a href="{{siteURL}}/terms-service" target="_blank" class="aambgreen_color footer_links">Terms of
          Services</a></small>
      <small><a href="{{siteURL}}/privacy-policy" target="_blank" class="aambgreen_color  "
          style="margin-left: 10px;">Privacy
          Policy</a></small>
    </div>
  </div>
</div>