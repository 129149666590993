<div id="admin-container">
    <!--Heading of the page-->
    <div class="container-fluid">
        <div class="row ml-5 m-top-10">
            <div class="col-md-12 col-lg-12 col-sm-12">
                <div class="page-title-box d-flex align-items-center">
                    <div class="page-title-left">
                        <h2 class="card-title title_header">Subscriptions</h2>
                    </div>
                    <span class="ml-5 m-top-5"> </span>
                </div>
            </div>
        </div>
    </div>
    <!--Main Body-->
    <!--Subscribe product list Boxes-->
    <div class="container productlist_boxes m-top-10 ms-0">
        <form formControlName="subscriptionForm">
            <div class="row">
                <!--Active Products-->
                <div class="col-lg-3 col-md-6" *ngFor="let product of this.activeSubscriptionList; let i = index">
                    <div class="pricing-table pricing_boxes" aria-colcount="">
                        <div class="prdct-con">
                            <img src="{{ product.logo_small }}" width="80" />
                        </div>
                        <div class="product-label">
                            <label><b>{{ product.product_name }}</b></label>
                        </div>
                        <div>
                            <label class="productstatus white_color">Active</label>
                        </div>
                        <div>
                            <hr style="margin: 15px 14px 5px;color: #004745!important;" />
                            <!--Renewal-->
                            <div class="col-lg-12 pt-2" style="display: flex">
                                <div class="col-lg-6 col-xl-7 left-clas">
                                    <label class="renewalLabel">Auto Renewal:</label>
                                </div>
                                <div class="col-lg-6 col-xl-5 right-clas">
                                    <span *ngIf="product.auto_renewal">Yes</span>
                                    <span *ngIf="!product.auto_renewal">No</span>
                                </div>
                            </div>
                            
                            <!--Subscription Date-->
                            <div class="col-lg-12 pt-2" style="display: flex">
                                <div class="col-lg-6 col-xl-7 left-clas">
                                    <label  class="renewalLabel">Subscription Date:</label>
                                </div>
                                <div class="col-lg-6 col-xl-5 renewaldat right-clas">
                                    {{
                                    product.start_date
                                    | date:"MMM dd, yyyy"
                                    }}
                                </div>
                            </div>
                            <!--Renewal Date-->
                            <div class="col-lg-12 pt-2" style="display: flex">
                                <div class="col-lg-6 col-xl-7 left-clas">
                                    <label *ngIf="!product.cancel_at_period_end" class="renewalLabel">Renewal Date:</label>
                                    <label *ngIf="product.cancel_at_period_end" class="renewalLabel">Cancel Date:</label>
                                </div>
                                <div class="col-lg-6 col-xl-5 renewaldat right-clas">
                                    {{
                                    product.next_invoice_date
                                    | date:"MMM dd, yyyy"
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Inactive Products-->
                <div class="col-lg-3 col-md-6" *ngFor="let product of this.finalInactiveList">
                    <div class="pricing-table" style="
                            border: 0.09rem solid #d9d9d9;
                            border-radius: 10px;
                            min-height: 304px;
                        ">
                        <div class="prdct-graycon grey-img">
                            <img src="{{ product.banner }}" style="height: 75px" />
                        </div>
                        <div class="product-label">
                            <label style="color: #afafaf !important;"><b>{{ product.name }}</b></label>
                        </div>
                        <label class="productinactivestatus">Not Subscribed</label>
                        <!-- <input type="checkbox" id="{{product._id}}" value="{{product}}" (change)="onCheckboxChange($event,product)"  class="slct-chk"><i class="icofont-ui-check"></i> -->
                        <div>
                            <hr style="margin: 14px 15px;color: #004745;" />
                            <div class="col-lg-12 pt-2">
                                <a (click)="show2(product)" data-bs-toggle="modal" data-bs-target="#subscribeModal"
                                    class="btn btn-aaamb" style="margin-top: 30px">Subscribe Now</a>
                                <!--subscribe Modal -->
                                <div class="modal fade" id="subscribeModal" tabindex="-1" aria-labelledby="ModalLabel"
                                    aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header bg_greeen display_block">
                                                <div class="row">
                                                    <div class="col-lg-12 col-sm-12 col-md-12 text-center">
                                                        <h4 class="pb-0 mb-0 white_color">
                                                            Confirm Subscription
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="modal-body">
                                                <div class="clearfix">
                                                    &nbsp;
                                                </div>
                                                <div class="text-center aambgreen_color">
                                                    <h4 class="aambgreen_color">
                                                        Amount: $ {{ total }}/
                                                        Month
                                                    </h4>
                                                    <p class="aambgreen_color">
                                                        Are you sure you want to subscribe to
                                                        {{ productName }}?
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="modal-footer d-flex justify-content-center">
                                                <button type="button" class="btn custom-btn-cancel"
                                                    data-bs-dismiss="modal">
                                                    Cancel
                                                </button>
                                                <button type="button" class="btn custom-btn-subscribe"
                                                    data-bs-dismiss="modal" (click)="
                                                        updateOrder(product)
                                                    ">
                                                    Subscribe Now
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="clearfix">&nbsp;</div>
    <!-- subscription table  -->
    <div class="container-fluid">
        <div class="row ml-5 m-top-10">
            <div class="col-md-12 col-lg-12 col-sm-12">
                <h5 class="h5 card-title text-black fw-600">
                    Subscription Info
                </h5>

                <!-- Table -->
                <div class="table-responsive">
                    <table id="basic-datatable" class="table datatables dt-responsive nowrap no-footer dtr-inline">
                        <thead>
                            <tr>
                                <th class="W-20">Product Name</th>
                                <th class="W-20">Amount</th>
                                <th class="W-20">Start Date</th>
                                <th class="W-20">End Date</th>
                                <th class="W-25 text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="subcriptionData?.length > 0">
                            <tr *ngFor="let data of subcriptionData">
                                <td>{{data.product_name}}</td>
                                <td>${{data.base_fare + (data.provider_cost * data.provider_qty)}}
                                    <!-- Default dropend button -->
                                    <i class="fa fa-info-circle aambgreen_color cursorPointer font-large"
                                        (click)="getPrices(data)" mwlConfirmationPopover
                                        [customTemplate]="customTemplate" placement="right"></i>
                                </td>
                                <td>{{data.start_date | date:"MMM dd, yyyy"}}</td>
                                <td>{{data.next_invoice_date | date:"MMM dd, yyyy"}}</td>
                                <td class="text-center">
                                    <button *ngIf="!data.cancel_at_period_end" data-bs-toggle="modal"
                                        class="btn custom-btn-red" data-bs-target="#cancelSubscriptionModal"
                                        (click)="updatedOrder(data)">
                                        Cancel Subscription
                                    </button>
                                    <button *ngIf="data.cancel_at_period_end" class="btn custom-btn-red"
                                        data-bs-toggle="modal" data-bs-target="#cancelSubscriptionModalInfo">
                                        Unsubscribed
                                    </button>

                                    <!--Cancel Subscription Modal -->
                                    <div class="modal fade center" id="cancelSubscriptionModal" tabindex="-1"
                                        aria-labelledby="ModalLabel" aria-hidden="true">
                                        <div class="modal-dialog">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <div class="row">
                                                        <div class="col-lg-6 col-sm-12 col-md-6">
                                                            <h4 class="aambgreen_color pb-0 mb-0">
                                                                Cancel Subscription
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                        aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body">
                                                    <div class="clearfix">
                                                        &nbsp;
                                                    </div>
                                                    <div class="text-center  aambgreen_color">
                                                        <div class="text-justify">
                                                            Cancellation will be effective at the end of your<br />
                                                            current
                                                            billing period on {{
                                                            data.current_period_end
                                                            | date:"MMM dd, yyyy"
                                                            }}.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="modal-footer d-flex justify-content-center">
                                                    <button type="button" class="btn custom-btn" data-bs-dismiss="modal"
                                                        (click)="cancelSubscription(data)">
                                                        Yes
                                                    </button>
                                                    <button type="button" class="btn custom-btn-red"
                                                        data-bs-dismiss="modal">
                                                        No
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--Cancel Subscription Modal Info -->
                                    <div class="modal fade" id="cancelSubscriptionModalInfo" tabindex="-1"
                                        aria-labelledby="ModalLabel" aria-hidden="true">
                                        <div class="modal-dialog">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <div class="row">
                                                        <div class="col-lg-6 col-sm-12 col-md-6">
                                                            <h4 class="aambgreen_color pb-0 mb-0">
                                                                {{selectedproductName}}
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                        aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body">
                                                    <div class="clearfix">
                                                        &nbsp;
                                                    </div>
                                                    <div class="text-center  aambgreen_color">
                                                        <h2 class="aambgreen_color">Unsubscribed Successfully</h2>
                                                        <div class="text-justify">
                                                            Cacellation will be effective at the end of your<br />
                                                            current
                                                            billing period on {{
                                                            data.current_period_end
                                                            | date:"MMM dd, yyyy"
                                                            }}.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="modal-footer d-flex justify-content-center">
                                                    <button type="button" class="btn custom-btn-red"
                                                        data-bs-dismiss="modal">
                                                        Ok
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <ng-template #customTemplate let-options="options" #closebutton>
                                    <div [class]="'popover ' + options.placement" style="display: block">
                                        <table class="table table-hover table-borderless">
                                            <tbody>
                                                <tr>
                                                    <th colspan="2">Amount Breakdown
                                                        <i class="fa-regular fa-circle-xmark font-large cursorPointer breakdown_icon"
                                                            (click)="options.onCancel({clickEvent: $event})"></i>
                                                    </th>

                                                </tr>
                                                <tr>
                                                    <td class="W-80">{{data.product_name}} Base Fee</td>
                                                    <td>${{data.base_fare}}</td>
                                                </tr>
                                                <tr class="line_break">
                                                    <td class="W-80">per Provider per Month(${{data.provider_cost
                                                        }}) x {{data.provider_qty}}</td>
                                                    <td>${{providerAmount}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="W-80"><b>Total Payable Amount</b></td>
                                                    <td><b>${{totalAmount}}</b></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </ng-template>
                            </tr>
                        </tbody>

                        <tbody *ngIf="subcriptionData?.length == 0">
                            <tr>
                                <td colspan="5" class="text-center">
                                    <img src="assets/img/no-data-6.svg" alt="No_data_icon" class="nodata_icon" />
                                    <div class="clearfix">&nbsp;</div>
                                    <p>No Active Subscriptions</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <!--Billing Address-->
    <div class="container-fluid">
        <div class="row ml-5 m-top-10">
            <div class="col-md-12 col-lg-12 col-sm-12">
                <h5 class="h5 card-title text-black fw-600">Billing Address</h5>
                <!-- Table -->
                <div class="table-responsive">
                    <table id="basic-datatable" class="table datatables dt-responsive nowrap no-footer dtr-inline">
                        <thead>
                            <tr>
                                <th class="W-25">Address</th>
                                <th class="W-auto">City</th>
                                <th class="W-auto">State</th>
                                <th class="W-auto">Postal</th>
                                <th class="W-auto">Cell Phone Number</th>
                                <th class="W-auto">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let address of BillingAdressInfo">
                                <td *ngIf="!address.isEdit">
                                    {{ address.address1 }}
                                </td>
                                <td *ngIf="address.isEdit">
                                    <input type="text" class="form-control" [(ngModel)]="BillingAdressInfo[0].address1"
                                        ngx-google-places-autocomplete [options]="{
                                        componentRestrictions: { country: 'US' }
                                        }" [ngModelOptions]="{ standalone: true }"
                                        (onAddressChange)="onBillingAddress($event)" />

                                </td>
                                <td *ngIf="!address.isEdit">{{ address.city }}</td>
                                <td *ngIf="address.isEdit"><input [disabled]="true" type="text" class="form-control"
                                        [(ngModel)]="BillingAdressInfo[0].city"
                                        [ngModelOptions]="{ standalone: true }" /></td>
                                <td *ngIf="!address.isEdit">{{ stateName }}</td>
                                <td *ngIf="address.isEdit">
                                    <input type="text" [disabled]="true" class="form-control" [(ngModel)]="stateName"
                                        [ngModelOptions]="{ standalone: true }">
                                </td>
                                <td *ngIf="!address.isEdit">{{ address.postalCode }}</td>
                                <td *ngIf="address.isEdit"> <input [disabled]="true" type="text" class="form-control"
                                        [(ngModel)]="BillingAdressInfo[0].postalCode"
                                        [ngModelOptions]="{ standalone: true }"></td>
                                <td *ngIf="!address.isEdit">{{ userInfo[0]?.phone }}</td>
                                <td *ngIf="address.isEdit"><input type="text" (input)="formatPhoneNumber($event)"
                                        class="form-control" [(ngModel)]="userInfo[0].phone"
                                        [ngModelOptions]="{ standalone: true }"></td>

                                <!-- <button style="
                                            background-color: white;
                                            border: none;
                                        " 
                                         class="vertical_sub" 
                                       (click)="address.isEdit = !address.isEdit">
                                        <i class="fa-solid fa-trash aambgreen_color cursorPointer"></i>
                                    </button> -->
                                <td>
                                    <a *ngIf="!address.isEdit" (click)="address.isEdit = !address.isEdit">
                                        <i class="fa-solid fa-pencil aambgreen_color cursorPointer"></i>
                                    </a>
                                    <ng-container *ngIf="address.isEdit">
                                        <a ngbTooltip="Update">
                                            <i class="fa-solid fa-save aambgreen_color cursorPointer position-size"
                                                (click)="updateBillingAddrs(address._id)"></i>
                                        </a>

                                        <a ngbTooltip="Cancel">
                                            <i class="fa-solid fa-times-circle aambred_color  cursorPointer ms-2 position-size"
                                                (click)="address.isEdit = !address.isEdit"></i>
                                        </a>
                                    </ng-container>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <!-- Manage Cards -->
    <div class="container-fluid">
        <div class="row ml-5 m-top-10">
            <div class="col-md-12 col-lg-12 col-sm-12">
                <div class="flex d-flex justify-content-between">
                    <h5 class="h5 card-title text-black fw-600">
                        Manage Cards
                    </h5>
                    <button class="btn custom-btn-red mb-15" data-bs-toggle="modal" data-bs-target="#addCardModal"
                        (click)="clearform()">
                        + Add New Card
                    </button>
                </div>

                <!-- Table -->
                <div class="table-responsive">
                    <table id="basic-datatable" class="table datatables dt-responsive nowrap no-footer dtr-inline">
                        <thead>
                            <tr>
                                <th class="W-25">Card</th>
                                <th class="W-20">Expiration Date</th>
                                <th class="W-20">Card Type</th>
                                <th class="W-20">Default</th>
                                <th class="W-20">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="
                                    let cardInfo of cardDetails;
                                    let i = index
                                ">
                                <td class="text-capitalize">
                                    {{ cardInfo.brand }} Ending in
                                    {{ cardInfo.last4 }}
                                </td>
                                <td>
                                    {{
                                    cardInfo.exp_month
                                    .toString()
                                    .padStart(2, "0")
                                    }}/
                                    {{
                                    cardInfo.exp_year.toString().substr(-2)
                                    }}
                                </td>
                                <td class="text-capitalize">{{ cardInfo.funding }}</td>
                                <td *ngIf="cardInfo.default_payment_method">
                                    Primary
                                </td>
                                <td *ngIf="!cardInfo.default_payment_method">
                                    Secondary
                                </td>

                                <td>
                                    <button style="
                                            background-color: white;
                                            border: none;
                                        " [disabled]="
                                            cardInfo.default_payment_method
                                        " class="vertical_sub" data-bs-toggle="modal" data-bs-target="#deleteCardModal"
                                        title="add one more card and delete" (click)="deleteModal(cardInfo)">
                                        <i class="fa-solid fa-trash aambgreen_color cursorPointer"></i>
                                    </button>
                                    <button *ngIf="cardInfo.default_payment_method" style="
                                            background-color: white;
                                            border: none;
                                        " title="Default" [disabled]="
                                            cardInfo.default_payment_method
                                        " class="vertical_sub" data-bs-toggle="modal" data-bs-target="#deleteCardModal"
                                        (click)="deleteModal(cardInfo)">
                                        <i class="fa-solid fa-star aambgreen_color cursorPointer"></i>
                                    </button>
                                    <button title="Make as Default" *ngIf="!cardInfo.default_payment_method" style="
                                            background-color: white;
                                            border: none;
                                        " class="vertical_sub" data-bs-toggle="modal"
                                        data-bs-target="#setasPrimaryModal" (click)="deleteModal(cardInfo)">
                                        <i class="fa-regular fa-star aambgreen_color cursorPointer"></i>
                                    </button>
                                    <!--Set as primary card Modal -->
                                    <div class="modal fade" id="setasPrimaryModal" tabindex="-1"
                                        aria-labelledby="ModalLabel" aria-hidden="true">
                                        <div class="modal-dialog">
                                            <div class="modal-content">
                                                <div class="modal-header bg_greeen display_block">
                                                    <div class="row">
                                                        <div class="col-lg-12 col-sm-12 col-md-12 text-center">
                                                            <h4 class="white_color pb-0 mb-0">
                                                                Set as Primary
                                                                Card
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="modal-body">
                                                    <div class="clearfix">
                                                        &nbsp;
                                                    </div>
                                                    <div class="text-center _color">
                                                        <div class="text-justify">
                                                            By setting this <b class="text-capitalize">{{ branding
                                                                }}</b> ending in <b>{{ last4digits }}</b> as primary,
                                                            this card will be used<br /> for your subscriptions’s
                                                            auto-renewal and future subscriptions.

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="modal-footer d-flex justify-content-center">
                                                    <button type="button" class="btn custom-btn-red"
                                                        data-bs-dismiss="modal">
                                                        Cancel
                                                    </button>
                                                    <button type="button" class="btn custom-btn" data-bs-dismiss="modal"
                                                        (click)="
                                                    setpopupDefaultCard(
                                                        cardInfo.id
                                                    )
                                                ">
                                                        Confirm
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--Delete Modal -->
                                    <div class="modal fade" id="deleteCardModal" tabindex="-1"
                                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog">
                                            <div class="modal-content">
                                                <div class="modal-header bg_greeen display_block">
                                                    <div class="row">
                                                        <div class="col-lg-12 col-sm-12 col-md-12 text-center">
                                                            <h4 class="white_color pb-0 mb-0">
                                                                Delete Card
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="modal-body">
                                                    <div class="clearfix">
                                                        &nbsp;
                                                    </div>
                                                    <div class="text-center _color">
                                                        <div class="sub-text">
                                                            Are you sure you want to
                                                            delete your <b class="text-capitalize">{{ branding }}</b>
                                                            ending in <b>{{ last4digits }}</b>
                                                            Card?
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="modal-footer d-flex justify-content-center">
                                                    <button type="button" class="btn custom-btn-red"
                                                        data-bs-dismiss="modal">
                                                        Cancel
                                                    </button>
                                                    <button type="button" class="btn custom-btn" data-bs-dismiss="modal"
                                                        (click)="
                                                    deleteDefaultCard(
                                                        cardInfo.id
                                                    )
                                                ">
                                                        Confirm
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>


    <!-- Modal -->
    <div class="modal fade" id="addCardModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header bg_greeen pb-5">
                    <div class="row">
                        <div class="col-lg-6 col-sm-12 col-md-6">
                            <h4 class="white_color pb-0 mb-0">Add New Card</h4>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-md-6">
                            <img src="assets/img/card_logo_white.png" class="m-t-3" alt="payment Logos" />
                        </div>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="col-xl-12">
                        <div class="container">
                            <div class="col-lg-12 p-3">
                                <h6 *ngIf="cardError" class="red_color text-center">{{cardErrorMethod}}
                                </h6>
                                <div class="row">
                                    <div class="stripe-card" ngxStripeCardGroup [elementsOptions]="elementsOptions">
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <div class="stripe-element form-group">
                                                <label for="cardNummber" class="Label">Card Number</label>
                                                <ngx-stripe-card-number class="borders form-control padding-all"
                                                    id="cardNummber" #cardNumber
                                                    [options]="cardOptions"></ngx-stripe-card-number>
                                            </div>
                                        </div>
                                        <div class="row m-bottom-30">
                                            <div class="col-lg-6 col-md-6 col-sm-6 m-top-15">
                                                <div class="w-full stripe-element form-group">
                                                    <label class="Label" for="cardExpiry">Expiration Date
                                                    </label>
                                                    <ngx-stripe-card-expiry class="borders form-control padding-all"
                                                        id="cardExpiry" #cardExpiry
                                                        [options]="cardOptions"></ngx-stripe-card-expiry>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6 m-top-15">
                                                <div class="w-full stripe-element form-group">
                                                    <label for="cardCVV">CVC</label>
                                                    <ngx-stripe-card-cvc class="borders form-control padding-all"
                                                        id="cardCVV" #cardCvc
                                                        [options]="cardOptions"></ngx-stripe-card-cvc>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-12 col-md-12 col-sm-12">
                                        <div class="w-full stripe-element">
                                            <label for="cardholderName"
                                                >Cardholder Name</label
                                            >
                                            <ngx-stripe-card-name
                                                class="form-control"
                                                id="cardholderName"
                                                #cardholderName
                                                [options]="cardOptions"
                                            ></ngx-stripe-card-name>
                                        </div>
                                    </div> -->
                                        <div class="form-check m-l-18">
                                            <input class="form-check-input" type="checkbox" id="paymentPrimary"
                                                [value]="setasPrimary" [checked]="setasPrimary" (change)="
                                                    setAsPrimaryCard($event)
                                                " />
                                            <label class="form-check-label" for="paymentPrimary">
                                                Set as primary
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- end #form-container -->
                        </div>
                        <br />
                    </div>
                </div>
                <div class="modal-footer d-flex justify-content-center">
                    <button type="button" class="btn custom-btn-cancel" data-bs-dismiss="modal" #saveCancelClick>
                        Cancel
                    </button>
                    <button type="button" (click)="addPayment()" class="btn btn-primary">
                        Save
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Payment History -->
    <div class="container-fluid">
        <div class="row ml-5 m-top-10">
            <div class="col-md-12 col-lg-12 col-sm-12">
                <h5 class="h5 card-title text-black fw-600">
                    Invoices
                </h5>
                <!-- Table -->
                <div class="table-responsive">
                    <table id="basic-datatable" class="table datatables dt-responsive nowrap no-footer dtr-inline">
                        <thead>
                            <tr>
                                <th class="W-20">#</th>
                                <th class="W-30">Description</th>
                                <th class="W-10">Amount</th>
                                <th class="W-10">Status</th>
                                <th class="W-15 text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="IsShowTotal">
                            <tr *ngFor="let data of InvoiceData;let i = index">
                                <ng-container *ngIf=" data.invoice_number && !data.invoice_number.endsWith('01') && data.total !== '0.00' ">
                                <td>{{data.invoice_number}}<br /><small>{{data.period_start | date:"MMM dd,
                                        yyyy"}}-{{data.period_end |
                                        date:"MMM dd, yyyy"}}</small>
                                </td>
                                <td>
                                    <div *ngFor="let line of data.lines"><small>{{line.description}}</small></div>
                                </td>
                                <!-- {{data | json}} -->
                                <td>${{data.total}}</td>
                                <td class="text-capitalize">{{data.status}}</td>
                                <td class="cursorPointer text-center">
                                    <span data-bs-toggle="modal" data-bs-target="#InvoiceModal"
                                        (click)="getView(data.invoice_pdf)"><a><i
                                                class="fa-solid fa-download aambgreen_color"></i>
                                        </a></span>
                                    <!--Modal PDF-->
                                    <div class="modal fade" id="" tabindex="-1" aria-labelledby="ModalLabel"
                                        aria-hidden="true">
                                        <div class="modal-dialog">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <div class="row">
                                                        <div class="col-lg-6 col-sm-12 col-md-6">
                                                            <h4 class="aambgreen_color pb-0 mb-0">
                                                                Cancel Subscription
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                        aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body">
                                                    <div class="clearfix">
                                                        &nbsp;{{safeUrl}}
                                                    </div>
                                                    <iframe [src]="safeUrl" frameBorder="0" scrolling="auto"
                                                        height="700px" width="100%"></iframe>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>
                            </tr>
                        </tbody>
                        <tbody *ngIf="!IsShowTotal">
                            <tr>
                                <td colspan="5" class="text-center">
                                    <img src="assets/img/no-data-6.svg" alt="No_data_icon" class="nodata_icon" />
                                    <div class="clearfix">&nbsp;</div>
                                    <p>No Invoices Yet</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loader></app-loader>

<ng-template #noDocTab>
    <div class="row">
        <div class="col-md-12 col-sm-12 col-lg-12">
            <div class="nocsdocument_tab_body text-center">
                <img src="assets/img/no-data-6.svg" alt="No_data_icon" height="80px" />
                <div class="clearfix">&nbsp;</div>
                <h2>Title</h2>
                <p>description for documents</p>
                <div class="clearfix">&nbsp;</div>
            </div>
        </div>
    </div>
</ng-template>