<div id="admin-container">
    <div class="container-fluid">
        <div class="row m-top-10 ml-5">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <h4 class="aambgreen_color text-capitalize">Welcome {{userfullname}}</h4>
                <!-- <p class="m-t-18 aambgreen_color">How've you feeling today?</p> -->
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 text-end">
                <h5 class="NPI-data aambgreen_color m-top-5">NPI: <b>{{npinumber}}</b> | NPI Type: <b>{{npitype}}</b>
                </h5>
            </div>
        </div>
    </div>
    <!--Dashboard Header title-->
    <div class="container-fluid">
        <div class="row  ml-5 m-top-10">
            <div class="col-md-12 col-lg-12 col-sm-12">
                <div class="page-title-box d-flex align-items-center">
                    <div class="page-title-left">
                        <h2 class="card-title title_header">Dashboard</h2>
                    </div>
                    <span class="ml-5 m-top-5">

                        <!-- <button class="btn btn_popover_noborder me-2" placement="end"
                    ngbPopover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus." popoverTitle="Dashboard Info">
                    <i class="fa fa-info-circle _info_color_icon"></i>
                    </button> -->
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="row admin-cont no-gutters">
            <!--box-->
            <div class="col-xl-4 col-md-6 mb-4">
                <div class="card border-left-primary h-100 py-2 dash-con">
                    <div class="card-body">
                        <div class="row no-gutters align-items-center">
                            <div class="col-auto">
                                <i class="fa-solid fa-screwdriver-wrench fa-3x"></i>
                            </div>
                            <div class="col ml-2">
                                <h5 class="font-weight-bold text-uppercase mb-1">
                                    {{myproducts}}</h5>
                                <div class="h6 mb-0 aambgreen_color">My Products</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--box-->
            <!--box-->
            <div class="col-xl-4 col-md-6 mb-4">
                <div class="card border-left-primary h-100 py-2 dash-con">
                    <div class="card-body">
                        <div class="row no-gutters align-items-center">
                            <div class="col-auto">
                                <i class="fa fa-users fa-3x"></i>
                            </div>
                            <div class="col ml-2">
                                <h5 class="font-weight-bold text-uppercase mb-1">
                                    {{groupind}}</h5>
                                <div class="h6 mb-0 aambgreen_color">Group/Individual</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--box-->
            <!--box-->
            <div class="col-xl-4 col-md-6 mb-4">
                <div class="card border-left-primary h-100 py-2 dash-con">
                    <div class="card-body">
                        <div class="row no-gutters align-items-center">
                            <div class="col-auto">
                                <i class="fa fa-user fa-3x"></i>
                            </div>
                            <div class="col ml-2">
                                <h5 class="font-weight-bold text-uppercase mb-1">
                                    {{orgusers}}</h5>
                                <div class="h6 mb-0 aambgreen_color">Organization Users</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--box-->

            <app-loader></app-loader>
        </div>
    </div>
</div>