<!-- <div class="demo-modal-panel">
    <button class="sidebar-demo-control">
        <span>Demos</span>
    </button>
</div> -->

<div class="example-demo-modal">
    <div class="inner">
        <ul>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-1.jpg" alt="image">
                    <span>Default Home</span>
                    <a href="/" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-2.jpg" alt="image">
                    <span>Slider Home</span>
                    <a href="/home-2" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-3.jpg" alt="image">
                    <span>Video Home</span>
                    <a href="/home-3" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-4.jpg" alt="image">
                    <span>Ripple Effect Home</span>
                    <a href="/home-4" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-5.jpg" alt="image">
                    <span>Text Typed Home</span>
                    <a href="/home-5" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-6.jpg" alt="image">
                    <span>Particles Home 1</span>
                    <a href="/home-6" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-7.jpg" alt="image">
                    <span>Particles Home 2</span>
                    <a href="/home-7" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-8.jpg" alt="image">
                    <span>Parallax Home</span>
                    <a href="/home-8" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-9.jpg" alt="image">
                    <span>Slideshow Home</span>
                    <a href="/home-9" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-10.jpg" alt="image">
                    <span>Creative Home 1</span>
                    <a href="/home-10" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-11.jpg" alt="image">
                    <span>Creative Home 2</span>
                    <a href="/home-11" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-12.jpg" alt="image">
                    <span>Creative Home 3</span>
                    <a href="/home-12" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-13.jpg" alt="image">
                    <span>Gradient Home</span>
                    <a href="/home-13" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-14.jpg" alt="image">
                    <span>Particles Home 3</span>
                    <a href="/home-14" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-15.jpg" alt="image">
                    <span>Particles Home 4</span>
                    <a href="/home-15" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-16.jpg" alt="image">
                    <span>Particles Home 5</span>
                    <a href="/home-16" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-17.jpg" alt="image">
                    <span>Slider Home With Text Typed & Parallax</span>
                    <a href="/home-17" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-18.jpg" alt="image">
                    <span>Default Home With Form</span>
                    <a href="/home-18" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-19.jpg" alt="image">
                    <span>Default Home With Video Image</span>
                    <a href="/home-19" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/home-20.jpg" alt="image">
                    <span>Animation Home</span>
                    <a href="/home-20" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/blog-1.jpg" alt="image">
                    <span>Blog Grid</span>
                    <a href="/blog-1" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/blog-2.jpg" alt="image">
                    <span>Blog Right Sidebar</span>
                    <a href="/blog-2" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/blog-details.jpg" alt="image">
                    <span>Blog Details</span>
                    <a href="/blog-details" class="link-btn"></a>
                </div>
            </li>
            <li>
                <div class="single-demo">
                    <img src="assets/img/demo-img/error.jpg" alt="image">
                    <span>Error</span>
                    <a href="/error" class="link-btn"></a>
                </div>
            </li>
        </ul>
    </div>
    <div class="header-title">
        <button class="example-demo-modal-control">
            <i class="icofont-close"></i>
        </button>
        <div class="title">View Demo</div>
    </div>
</div>
