import { Component, OnInit } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { ToastrService } from "ngx-toastr";
import { CustomerService } from "src/app/shared/services/customers/customers.service";
import { ProductsService } from "src/app/shared/services/products/products.service";
import { APP_CONSTANTS } from "src/app/shared/utils/appconstants";

@Component({
    selector: "app-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
    npinumber: String = "";
    npitype: String = "";
    locations = 0;
    orgusers = 0;
    groupind = 0;
    myproducts = 0;
    practices!: any[];
    userfullname = "";

    constructor(
        private customerService: CustomerService,
        private cookieService: CookieService,
        private toastService: ToastrService,
        private productService: ProductsService,
    ) {
        this.getUserDetails();
        this.getSubscription();
    }

    async getUserDetails() {
        if (
            this.customerService.loggedInUser &&
            this.customerService.loggedInUser.length > 0
        ) {
            this.productService.isLoading(true);
            await this.customerService
                .getUserById(this.customerService.loggedInUser[0].id)
                .then((res) => {
                    const word = res.firstname;
                    const capitalized =
                        word.charAt(0).toUpperCase() + word.slice(1);
                    this.userfullname = capitalized + " " + res.lastname;
                },err=>{
                    this.productService.isLoading(false);
                });
        }
    }

    async ngOnInit() {
        //console.log('dashboard------------------------ngOnInit-' );
        if (this.customerService.loggedInUser == null) {
            //console.log('dashboard------------------------28-' );
            this.customerService.loggedInUser = [];
            const _token = JSON.parse(
                this.cookieService.get(APP_CONSTANTS.cookiekey) || "{}"
            );
            this.customerService.loggedInUser.push(_token.data);
            //console.log(JSON.stringify(this.customerService.loggedInUser));
        }
        await this.getUserDetails();

        if (this.customerService.loggedInUser[0].practiceData) {
            //console.log('dashboard------------------------60-' +JSON.stringify(this.customerService.loggedInUser[0]) );
            await this.GetCustomerOrders(
                this.customerService.loggedInUser[0].id
            );
            this.npinumber =
                this.customerService.loggedInUser[0].practiceData.npinumber;
            this.npitype =
                this.customerService.loggedInUser[0].practiceData.npitype[0].name;
            this.practices = [];
            this.practices.push(
                this.customerService.loggedInUser[0].practiceData
            );
            console.log(this.practices);
            this.locations = this.practices.length;
            this.groupind = this.practices.length;
        }
        if (!this.customerService.loggedInUser[0].practiceData && this.customerService.npiInfo) {
            // console.log(
            //     "dashboard------------------------63-" +
            //         JSON.stringify(this.customerService.npiInfo)
            // );
            this.npinumber = this.customerService.npiInfo.npinumber;
            this.npitype = this.customerService.npiInfo.npitype;
        }
        //console.log("locations------"+JSON.stringify(this.customerService.loggedInUser[0].practiceData.length))
        // if (this.customerService.productInfo.length > 0) {
        //     // console.log('dashboard------------------------54-'+JSON.stringify(this.customerService.productInfo) );
        //     this.myproducts =
        //         this.customerService.productInfo[0]?.items?.length;
        // }
    }
    async GetCustomerOrders(userID) {
        this.customerService.productInfo = [];
        await this.customerService
            .getOrderByCustomerId(userID)
            .then(async (data: any) => {
                if (data.length == 0) {
                    this.productService.isLoading(false);
                    this.toastService.error(
                        "Subscription data not found. Please contact Administrator",
                        "Subscription Error"
                    );
                    
                    return;
                }
                if (data && data[0].items.length > 0) {
                    this.customerService.productInfo.push(data[0]);
                    await this.GetUsersByPractice(
                        this.customerService.loggedInUser[0].practiceData._id
                    );
                }
            });
    }
    async GetUsersByPractice(practiceID) {
        this.customerService.UserList = [];
        await this.customerService
            .getUsersByPractice(practiceID)
            .then((data: any) => {
                if (data && data.length > 0) {
                    this.orgusers = data.length;
                    this.productService.isLoading(false);

                }
            },err=>{
                this.productService.isLoading(false);
            });
    }

    subcriptionData: any;
    getSubscription() {
        this.customerService
            .getSubscriptions(this.customerService.loggedInUser[0].id)
            .subscribe((res) => {
                console.log(res);
                this.subcriptionData = res;
                let data = this.subcriptionData.filter(item => !item.cancel_at_period_end);
                 this.myproducts = data.length;
            },err=>{
                this.productService.isLoading(false);
            });
    }
}
