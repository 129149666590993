import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

export enum STATUS {
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private errorPages = [STATUS.FORBIDDEN, STATUS.NOT_FOUND, STATUS.INTERNAL_SERVER_ERROR];

  private getMessage = (error: HttpErrorResponse) => {
    if (error.error?.message) {
      return error.error.message;
    }

    if (error.error?.msg) {
      return error.error.msg;
    }

    return `${error.status} ${error.statusText}`;
  };

  constructor(private router: Router, private toast: ToastrService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next
      .handle(request)
      .pipe(catchError((error: HttpErrorResponse) => this.handleError(error)));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      this.toast.error(error.statusText + ': A client-side or network error occured');
    }
    if (this.errorPages.includes(error.status)) {
      if (error.status === 500) {
        if(error.error)
        {
          if (error.error.includes(401)) {
            this.toast.error(error.error);
            this.router.navigateByUrl('/auth/login');
          }
          else {
            this.toast.error('Something bad happened; please try again later.');
            this.router.navigateByUrl('/auth/login');
          }
        }
        this.router.navigateByUrl(`/${error.status}`, {
          skipLocationChange: true,
        });
      } else if (error.status === 401) {
        this.toast.error(this.getMessage(error));
        this.router.navigateByUrl('/auth/login');
      } else {
        return throwError(() => new Error(error.status.toString()));
      }
    } else {
      if (error.status === STATUS.UNAUTHORIZED) {
        this.toast.error(this.getMessage(error));
        this.router.navigateByUrl('/auth/login');
      }
    }
    if (error.error.status === 401) {
      this.toast.error(error.error.message);
      this.router.navigateByUrl('/auth/login');
    }

    return throwError(() => new Error('Something bad happened; please try again later.'));
  }
}
