import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from 'src/app/shared/services/cart/cart.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor(  private cartService: CartService, private router: Router,) { }

  ngOnInit() {
  }
  CheckSignup(){
    if(this.cartService.cartItemList.length == 0)
    {
        this.router.navigate(['#pricing']);
    }
    else{
        this.router.navigate(['/signup']);
    }
  }

  redirectToPricing() {
    this.router.navigate(['/products'], { queryParams: {id:'pricing'} });
  }
}
