import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from 'src/app/shared/services/customers/customers.service';
import { NpiTypeService } from 'src/app/shared/services/main/npitype.service';
import { PracticeService } from 'src/app/shared/services/practice.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor( private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private customerService: CustomerService,
    private npiTypeService: NpiTypeService,
    private practiceService: PracticeService,) { }

  ngOnInit() {
    console.log(JSON.stringify(localStorage.getItem('user')));
  }

}
