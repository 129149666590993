<div class="reg-con">
    <div class="container-fluid">
        <div class="header">
            <a class="logo" href="/home">AAAMB</a>
        </div>
        <div class="reg-login">
            <div class="col-xl-12">
                <div class="row">
                     <div class="col-lg-7 col-md-12 col-sm-12 left-sec " style="text-align: center;">
                        <h3>Login Now and Start your <br/> Journey with us</h3>
                        <div id="partnerAaamb" class="partner-img" style="display: block; text-align: center;">
                            <img width="65%" alt="AAAMB" src="assets/img/signup-bg.png"></div>
                    </div>
                    <div class="col-lg-5 col-md-12 col-sm-12 right-sec">
                        <div *ngIf="statusCode" class="page-content">
                            <div class="form-v5-content">
                                <div  class="form-detail">
                                    <div>
                                        <p class="text_errors ml-15">
                                        User data not found. Please contact administrator.
                                        </p>
                                            <input type="button" name="back" class="btn btn-danger" value="Back" (click)="goBack()">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="noaccess" class="page-content">
                            <div class="form-v5-content">
                                <div  class="form-detail">
                                    <div>
                                        <p class="text_errors ml-15">
                                        You don't have permissions. Please contact administrator.
                                        </p>
                                            <input type="button" name="back" class="btn btn-danger" value="Back" (click)="goBack()">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <h3 class="text-capitalize login_header_heading">Hello,<br />
                            Welcome!</h3> -->
                        <!-- <form class="" [formGroup]="loginForm">
                            <div class="mb-3">
                                <label  for="floatingInput"  class="login_labels ml-15">Email Address<span>*</span></label>
                                <input type="email" formControlName="email" class="form-control" id="floatingInput"
                                    placeholder="name@example.com" required="true" [ngClass]="{
                                        'is-invalid': submitted && f.email.errors}" />
                            </div>
                            <div class="col-xl-12 m-auto">
                                <div class="text_errors ml-15 m-t-10"
                                  *ngIf="!loginForm.get('email')?.valid &&
                                    (loginForm.get('email')?.touched)">
                                  Valid Email Required
                              </div>
                            </div>

                            <label for="password"  class="login_labels ml-15">Password<span>*</span></label>
                                <div class="input-group auth-pass-inputgroup mb-3">
                                <input id="password" [type]="showPassword ? 'text' : 'password'" formControlName="password" class="form-control"
                                     placeholder="Enter your password" [ngClass]="{
                                        'is-invalid':
                                            submitted && f.password.errors
                                    }" />
                                  <div (click)="toggleShowPassword()" class="show-password-icon">
                                    <i [class]="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'" aria-hidden="true"></i>
                                  </div>
                                  <p *ngIf="statusCode" class="text_errors ml-15">
                                    Invalid Username or Password
                                    </p>
                                    <p *ngIf="statusCode" class="text_errors ml-15">
                                        You don't have permissions to login. Please contact Admin
                                    </p>

                              </div>
                              <div class="col-xl-12 m-auto">
                                <div class="text_errors ml-15 m-t-10"
                                *ngIf="!loginForm.get('password')?.valid &&
                                    (loginForm.get('password')?.touched)">
                                Password Required
                                </div>
                            </div>
                            <div class="mb-3">
                                <label>Password</label>
                                <input type="password" formControlName="password" class="form-control"
                                    id="floatingPassword" placeholder="Password" [ngClass]="{
                                        'is-invalid':
                                            submitted && f.password.errors
                                    }" />
                            </div>
                            <p *ngIf="statusCode" style="color: #C12228">
                                Invalid Username or Password
                            </p>
                            <div class="form-check m-l-18">
                                <input class="form-check-input" type="checkbox" id="rememberme" />
                                <a class="text-capitalize form-check-label" for="rememberme">
                                  Remember Me
                                </a>
                                <a routerLink="/recoverpwd" href="#" class="fw-medium float-end"> Forgot
                                  Password?</a>
                              </div>
                              <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="rememberme" />
                                <label class="form-check-label" for="rememberme">Remember Me</label>
                                <a href="/recoverpwd" class="forgot">Forgot Password?</a>
                            </div>
                            <div class="col-12 mt-20 text-center">
                                <button class="btn custom-btn login_button" type="submit" (click)="login()">
                                    Login
                                </button>
                            </div>
                            <div class="col-3 m-auto">
                                <button class="btn btn-primary" type="submit" (click)="login()">
                                    LogIn</button>
                            </div>
                        </form> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container mb-20">
        <div class="row">
          <div class="col-xl-6 col-sm-12 col-lg-6 f-txt aambgreen_color">
              Copyright ©2024 AAAMB LLC. All rights reserved.
          </div>
          <div class="col-xl-6 col-sm-12 col-lg-6 f-txt text-end">
              <a href='{{siteURL}}/terms-service' target="_blank" class="aambgreen_color">Terms of Service</a>&nbsp;&nbsp; <a href="/privacy-policy" class="aambgreen_color" target="_blank">Privacy Policy</a>
          </div>
        </div>

    </div>
</div>
